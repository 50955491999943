import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import {toast,Toaster } from 'react-hot-toast';
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class FundRequest extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "tx_id",
                text: "Transaction Id",
                className: "transaction_id",
                align: "left",
                sortable: true,
            },
            {
                key: "userName",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "txStatus",
                text: "Transaction Type",
                className: "transaction_id",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {
                            record.txStatus =='referral' && record.referralUser !== undefined && 
                            record.txStatus+"("+record.referralUser+")"
                            }
                            {
                            record.txStatus =='referral' && record.referralUser === undefined && 
                            record.txStatus
                            }
                            {
                            record.txStatus !=='referral' && 
                            record.txStatus
                            }
                        </Fragment>
                    );
                }
            },
         
            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {   
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            // {
            //     key: "action",
            //     text: "Action",
            //     className: "action",
            //     width: 100,
            //     align: "left",
            //     sortable: false,
            //     cell: record => {
            //         return (
            //             <Fragment>
            //              <Link to={"/fund-request-verify/"+ record.id}  className="btn btn-primary btn-sm"><i className="fa fa-edit"></i></Link>
            //                 <button
            //                     className="btn btn-danger btn-sm"
            //                     onClick={() => this.deleteRecord(record)}>
            //                     <i className="fa fa-trash"></i>
            //                 </button>
            //             </Fragment>
            //         );
            //     }
            // }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {
               
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
                password: '',
                password2: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.rewardHistory !==undefined && this.props.auth.user.rewardHistory !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.rewardHistory !==undefined && nextProps.auth.user.rewardHistory !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    }

    getData() {
        axios
            .post(baseUrl+"api/getRewardTransaction",{},config)
            .then(res => {
                this.setState({ records: res.data.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/transaction-delete", {_id: record._id},config)
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles)
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                           <h1 className="mt-2 text-primary">Reward History</h1>
						    <div className="man_white_box ">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                        </div>
                    </div>
                    <Toaster/>
                </div>
            </div>
        );
    }

}

FundRequest.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(FundRequest);
