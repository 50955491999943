import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { rejectPaymentButton } from "../../../actions/transactionAction";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
class RejectPaymentButton extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            id: "",
            fImage: '',
            docStatus: '',
            reject: '',
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps');
        console.log(nextProps.record);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                docStatus: nextProps.docStatus,
                reject: nextProps.reject
              });
            
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }
        if (nextProps.transactiondata !== undefined
            && nextProps.transactiondata.transaction !== undefined
            && nextProps.transactiondata.transaction.data !== undefined
            && nextProps.transactiondata.transaction.data.message !== undefined
            && nextProps.transactiondata.transaction.data.success) {
                $('#exampleModal1').modal('hide');
         
        }
      
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

  
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };
    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
   
    onUpdate = e => {
    
        e.preventDefault();
        
        const newUser = {
            _id: this.props.match.params.id,
             reject:this.state.reject
};
        this.props.rejectPaymentButton(newUser);
    };
  


    render() {
     
        const { errors } = this.state;
        const { user } = this.state;
        return (
         <>
                     <div style={{display:(this.state.docStatus != "verify"  ? 'block':'none')}}>
                        <button type="submit" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal1">Reject</button>
                        </div>
                         <form noValidate onSubmit={this.onUpdate} id="update-user">
                            <div className="modal fade" id="exampleModal1"  data-reset="true" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Write Reason</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                           <div className="form-group">
                                           <input onChange={this.onChange}
                                                value={this.state.reject}
                                                id="reject"
                                                type="text"
                                                className={classnames("form-control", {
                                                    invalid: errors.reject
                                                })}/>
                                           </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            {this.state.reject!='' && this.state.docStatus != "reject"  &&
                                            <button type="submit" className="btn btn-primary">Save changes</button>
    }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                   

                    </>
               



        )
    }
}

RejectPaymentButton.propTypes = {
    rejectPaymentButton: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata: state.transactiondata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { rejectPaymentButton }
)(withRouter(RejectPaymentButton));
