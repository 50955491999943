import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { updateAppSetting } from "../../actions/appSettingActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import {toast,Toaster } from 'react-hot-toast';
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;    
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class AppSetting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            errors: {}
        };


    }

    getData() {
        axios
            .post(baseUrl+"api/app-setting")
            .then(res => {
                this.setState({ 
                    records: res.data.data,
                    _id: res.data.data._id,
                    version: res.data.data.version,
                    iosversion: res.data.data.iosversion,
                    url: res.data.data.url,
                    iosurl: res.data.data.iosUrl,                
                    bugFixes: res.data.data.bugFixes
                })
            })
            .catch()
    }
    componentDidMount() {
        // if (this.props.auth.user.roleManager !==undefined && this.props.auth.user.roleManager !==true ) {
        //     this.props.history.push("/dashboard");
        // }
        this.getData();
    };
    componentWillReceiveProps(nextProps) {
        // if (nextProps.auth.user.roleManager !==undefined && nextProps.auth.user.roleManager !==true ) {
        //     this.props.history.push("/dashboard");
        // }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.appSettingData !== undefined
            && nextProps.appSettingData.app !== undefined
            && nextProps.appSettingData.app.data !== undefined
            && nextProps.appSettingData.app.data.message !== undefined
            && nextProps.appSettingData.app.data.status) {
        //    this.props.history.push("/role-manager");
        toast.success(nextProps.appSettingData.app.data.message,toastStyles);
        }

        this.getData()
    }
    

  
    onChange = e => {

        this.setState({errors: {}});
        if (e.target.name === 'version') {this.setState({version: e.target.value});}
        if (e.target.name === 'iosversion') {this.setState({iosversion: e.target.value});}
        if (e.target.name === 'url') {this.setState({url: e.target.value});}
        if (e.target.name === 'iosurl') {this.setState({iosurl: e.target.value});}
        if (e.target.name === 'bugFixes') {this.setState({bugFixes: e.target.value});}
       
         this.setState({ [e.target.id]: e.target.value });
    };

    handleCommentChange = (event, editor) => {
        var data = editor.getData();
        if (data) {
          this.setState({ bugFixes: data,
            errors: {
              bugFixes:''
            } });
        }    
    };

    onRoleUpdate = e => {
        e.preventDefault();   
            const newdata = {
                _id: this.state._id,
                version: this.state.version,
                iosversion: this.state.iosversion,
                url: this.state.url,
                iosurl: this.state.iosurl,                
                bugFixes: this.state.bugFixes                
           };
        this.props.updateAppSetting(newdata);
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Update App Version</h1>
                            <div className="in_page" >
                          
                               
                                <div className="" >
                                    <div className=" padding_d man_white_box " >
                                        <form noValidate onSubmit={this.onRoleUpdate} id="add-user">
                                            <div className="white_box">
                                                <div className="tab-content ">
                                                    <div className="tab-pane active show" id="details">
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="version">Version</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.version}
                                                                    error={errors.version}
                                                                    name="version"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.version
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.version}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="name">IOS Version</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.iosversion}
                                                                    name="iosversion"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.iosversion
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.iosversion}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="email">Url</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.url}
                                                                    error={errors.url}
                                                                    name="url"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.url
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.url}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="mobile">IOS Url</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.iosurl}
                                                                    error={errors.iosurl}
                                                                    name="iosurl"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.iosurl
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.iosurl}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="bugFixes">Bug Fixes</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                            <CKEditor editor={ClassicEditor}
                                                                value={this.state.bugFixes}
                                                                onChange={this.handleCommentChange}
                                                                placeholder="Enter bugFixes"
                                                                className="form-control form_control_textarea"
                                                                data={this.state.bugFixes} />
                                                                <span className="text-danger">{errors.password}</span>
                                                            </div>
                                                        </div>
														<div className="row mt-2">
                                                            <div className="col-md-3">
                                                               
                                                            </div>
                                                            <div className="col-md-9">
                                                            <button className="btn btn-primary" type="submit">Save</button>
                                                            </div>
                                                        </div>
                                                      
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Toaster />
                </div>
            </div>
                );
  }

}

AppSetting.propTypes = {
    updateAppSetting: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    appSettingData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    appSettingData: state.appSettingData,
    errors: state.errors
});

export default connect(
mapStateToProps,
{updateAppSetting}
)(withRouter(AppSetting));
