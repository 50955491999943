var baseUrl="";
if(window.location.hostname==="192.168.1.80" || window.location.hostname==="192.168.1.130" || window.location.hostname==="localhost"){
	baseUrl = "http://"+window.location.hostname+":5000/";
	baseUrlSocket = "http://"+window.location.hostname+":5001/"
}
else if(window.location.hostname==='ttgtestadmin.technop2p.in'){
    baseUrl = "https://ttgtestapi.technop2p.in/";
}
else {
	// baseUrl = "https://apiweb.tradethegames.com/";
	// baseUrl = "https://testapi.tradethegames.com/";
	// baseUrl = "https://testingttgapi.technoloaderitservices.com/";	
	// baseUrlSocket = "https://ttgsocketapi.technoloaderitservices.com/";	
	// baseUrl = "https://stagingapi.tradethegames.com/";	
	// baseUrlSocket = "https://testsocket.tradethegames.com/";	
	baseUrl = "https://testapi.tradethegames.com/";	
	baseUrlSocket = "https://testapi.tradethegames.com/";	
}
export var baseUrl;
export var baseUrlSocket;