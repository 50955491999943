import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import WithdrawalINRReject from "../partials/WithdrawalINRReject";
import WithdrawalINRApprove from "../partials/WithdrawalINRApprove";
import {toast,Toaster } from 'react-hot-toast';

import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class Withdrawal extends Component {

    constructor(props) {
        super(props);

        this.columns1 = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
               
                text: "Mobile No",
                className: "mobile",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {record.user!=""?record.user[0].mobile:""}
                        </Fragment>
                    );
                }
            },
            {
               
                text: "User Name",
                className: "username",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {record.user!=""?record.user[0].name:""}
                        </Fragment>
                    );
                }
            },
            {
                key:"amount",
                text:"Request Amount",
                className: "amount",
                align: "left",
                sortable: true,
            },
            {
                key:"winningamount",
                text:"Winning Amount",
                className: "amount",
                align: "left",
                sortable: true
            },
            {
                key:"bankDetail",
                text:"Bank Detail",
                className: "bankDetail",
                align: "left",
                cell: record => {
                    return (            
                        <Fragment>
                            <p className="mb-0"> 
                                <button
                                data-toggle="modal"
                                data-target="#update-user-modal-bankDetail"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.showBankRecord(record.bank_detail_id)}
                                style={{marginRight: '5px'}}>
                                Bank Info
                                </button>
                            </p>
                        </Fragment>            
                    );
                },
            },        
            // {
            //     key: "status",
            //     text: "Status",
            //     className: "status",
            //     align: "left",
            //     sortable: true,
            // },
            {
       
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
               
            },
   
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (

                        <Fragment>
                            <p className="mb-0">
                                
                                {record.status == 'pending' ? ( <>
                                    <button
                                data-toggle="modal"
                                data-target="#update-user-modal-approve"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}} title="Approve">
                                <i className="fa fa-check"></i>
                            </button>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal-reject"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}} title="Reject">
                                <i className="fa fa-close"></i>
                            </button>
                                </>

                                ) : record.status == 'completed' ? (     <button
                                data-toggle="modal"
                                className="btn btn-primary btn-sm accepted"
                                style={{marginRight: '5px'}}>
                               <span className="completed_btn accepted">Approved</span>
                            </button>) : ( <>
                                    <button
                                data-toggle="modal"
                                data-target="#update-user-modal-reject"
                                className="btn btn-primary btn-sm rejectedwithdrow"
                                onClick={() => this.rejectRecord(record)}
                                style={{marginRight: '5px'}}>
                                Rejected
                            </button> </>
                                )}
                            </p>
                        </Fragment>

                    );
                }
            }
        ];

        this.columns2 = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
                
            },
            {               
                text: "Mobile No",
                className: "mobile",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {record.user!=""?record.user[0].mobile:""}
                        </Fragment>
                    );
                }
            },
            {       
                text: "User Name",
                className: "username",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {record.user!=""?record.user[0].name:""}
                        </Fragment>
                    );
                }
            },
            {
                key:"amount",
                text:"Request Amount",
                className: "amount",
                align: "left",
                sortable: true,
            },
            {
                key:"winningamount",
                text:"Winning Amount",
                className: "amount",
                align: "left",
                sortable: true
            },          
            {
                key:"bankDetail",
                text:"Bank Detail",
                className: "bankDetail",
                align: "left",
                cell: record => {
                    return (            
                        <Fragment>
                            <p className="mb-0"> 
                                <button
                                data-toggle="modal"
                                data-target="#update-user-modal-bankDetail"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.showBankRecord(record.bank_detail_id)}
                                style={{marginRight: '5px'}}>
                                Bank Info
                                </button>
                            </p>
                        </Fragment>            
                    );
                },
            }, 
            {
       
                text: "Approved By",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {(record.withdrowalVerifiedBy!="" && record.withdrowalVerifiedBy != undefined)?record.withdrowalVerifiedBy.name:""}
                        </Fragment>
                    );
                }
               
            },
            {
       
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
               
            },
   
            {
                key: "action",
                text: "Status",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (

                        <Fragment>
                            <p className="mb-0">
                                
                                {record.status == 'pending' ? ( <>
                                    <button
                                data-toggle="modal"
                                data-target="#update-user-modal-approve"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-check"></i>
                            </button>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal-reject"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}}>
                                <i className="fa fa-close"></i>
                            </button>
                                </>

                                ) : record.status == 'completed' ? (     <button
                                data-toggle="modal"
                                className="btn btn-primary btn-sm accepted"
                                style={{marginRight: '5px'}}>
                               <span className="completed_btn accepted">Approved</span>
                            </button>) : ( <>
                                    <button
                                data-toggle="modal"
                                data-target="#update-user-modal-reject-reason"
                                className="btn btn-primary btn-sm rejectedwithdrow"
                                onClick={() => this.rejectRecord(record)}
                                style={{marginRight: '5px'}}>
                                Rejected
                            </button> </>
                                )}
                            </p>
                        </Fragment>

                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            recordsPending: [],
            recordsComplete: [],
            recordsReject: []
        };

        this.state = {
            currentRecord: {
                id: '',
                amount: '',
                user_id:'',
                approve:'', 
                reject:""
            },
            currentReject: {
                id: '',
                amount: '',
                user_id:'',
                approve:'', 
                reject:""
            },
            bankRecord:{}
        };

        this.getData = this.getData.bind(this);
    }
  

  

    getData() {
        axios
            .post(baseUrl+"api/getAllTransaction",{status:'pending'},)
            .then(res => {
                this.setState({ recordsPending: res.data.data})
            })
            .catch()
        axios
            .post(baseUrl+"api/getAllTransaction",{status:'completed'},)
            .then(res => {
                this.setState({ recordsComplete: res.data.data})
            })
            .catch()
        axios
            .post(baseUrl+"api/getAllTransaction",{status:'rejected'},)
            .then(res => {
                this.setState({ recordsReject: res.data.data})
            })
            .catch()
    }
    componentWillReceiveProps(nextProps) {
        this.getData()
    }
    componentDidMount() {
        this.getData()
    };
    editRecord(record) {
        this.setState({ currentRecord: record});
    }
    rejectRecord(record) {
        this.setState({ currentReject: record});
    }
    showBankRecord(record) {
        this.setState({ bankRecord: record});
    }

   

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
    
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <WithdrawalINRReject record={this.state.currentRecord}/>
                    <WithdrawalINRApprove record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>                          
                            <h4 className="mt-2 man_hadding">Withdrawal INR Request List</h4>
                            <div className="man_white_box padding_d ">
                                <ul className="nav nav_left_tab">
                                <li className="nav-item">
                                    <a className="active show" data-toggle="tab" href="#pending">
                                    <i className="la la-mobile-phone l_i"></i>
                                        Pending
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="" data-toggle="tab" href="#completed">
                                    <i className="la la-user l_i"></i>
                                    Completed
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="" data-toggle="tab" href="#rejected">
                                    <i className="la la-map-marker l_i"></i>
                                    Rejected
                                    </a>
                                </li>
                                </ul>
                                <hr />
                                <div className="white_box">
                                    <div className="tab-content">
                                        <div id="pending" className="tab-pane active show">
                                                <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.recordsPending}
                                                    columns={this.columns1}
                                                    onPageChange={this.pageChange.bind(this)}
                                                />
                                        </div>
                                        <div id="completed" className="tab-pane">
                                            <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.recordsComplete}
                                                    columns={this.columns2}
                                                    onPageChange={this.pageChange.bind(this)}
                                                />
                                        </div>
                                        <div id="rejected" className="tab-pane">
                                            <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.recordsReject}
                                                    columns={this.columns2}
                                                    onPageChange={this.pageChange.bind(this)}
                                                />
                                        </div>
                                    </div>
                                </div>
							</div>                            
                        </div>
                    </div>
                    <Toaster/>
                </div>
                <div className="modal fade" id="update-user-modal-reject-reason">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Withdrawal Request Reject Reason</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <span>{this.state.currentReject.reject}</span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* Bank Detail Modal*/}
                <div className="modal fade" id="update-user-modal-bankDetail">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Bank Detail</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <p>Account Holder Name: {this.state.bankRecord.accountHolderName}</p>
                                <p>Account Number: {this.state.bankRecord.accountNo}</p>
                                <p>Bank Name: {this.state.bankRecord.bankName}</p>
                                <p>Branch Name: {this.state.bankRecord.branchName}</p>
                                <p>IFSC code: {this.state.bankRecord.ifscCode}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {this.state.status=="pending" &&
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

Withdrawal.propTypes = {
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata:state.transactiondata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Withdrawal);
