import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {toast,Toaster } from 'react-hot-toast';
import Moment from 'moment';
import $ from 'jquery';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class CouponManagement extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "errorCode",
                text: "Error Code",
                className: "upcase",
                align: "left",
                sortable: true,                
            }, 
            {
                key: "apiName",
                text: "API Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "description",
                text: "Description",
                className: "quantity",
                align: "left",
                sortable: true,
            },       
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "State List",
            no_data_text: 'No user found!',
            button: {
              
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            countryList: []
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                c_id: '',
               
            }
        };

        this.getData = this.getData.bind(this);
    }
  

    componentDidMount() {
        if (this.props.auth.user.stateManagment !==undefined && this.props.auth.user.stateManagment !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };
      componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.stateManagment !==undefined && nextProps.auth.user.stateManagment !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.statedata !== undefined
            && nextProps.statedata.state !== undefined
            && nextProps.statedata.state.data !== undefined
            && nextProps.statedata.state.data.message !== undefined) {
            $('#add-user-modal').modal('hide');
            $('#update-user-modal').modal('hide');
            toast.success(nextProps.statedata.state.data.message,toastStyles);
        }
        this.getData();
    } 
  
     

    getData() {
        axios
            .post(baseUrl+"api/error-logs-list",{},configToken)
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h1 className="mt-2 text-primary">Error Log List</h1>
							 <div className="man_white_box ">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                                className="table table-bordered table-striped bank-detail-datatable"
                            />
                        </div>
                        </div>
                    </div>
                    <Toaster/>
                </div>
            </div>
        );
    }

}

CouponManagement.propTypes = {
    auth: PropTypes.object.isRequired,
    statedata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    statedata: state.statedata,
    records: state.records
});

export default connect(
    mapStateToProps
)(CouponManagement);
