import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import Country from "./components/pages/Country";
import State from "./components/pages/State";
import ErrorLogs from "./components/pages/ErrorLogs";
import User from "./components/pages/Users";
import DummyUsers from "./components/pages/dummyUsers";
import ContestDummyUsers from "./components/pages/contestDummyUsers";
import BlockUser from "./components/pages/BlockUsers";
import Pair from "./components/pages/Pair";
import Crypto from "./components/pages/Crypto";
import Contest from "./components/pages/Contest";
import Suport from "./components/pages/Suport";
import TempUser from "./components/pages/TempUser";

import ChatRoom from "./components/pages/ChatRoom";
import BunnerManage from "./components/pages/BunnerManage";
import AddContest from "./components/pages/AddContest";
import ContestDetail from "./components/pages/ContestDetail";
import EditContest from "./components/pages/EditContest";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import addRole from "./components/pages/addRole";
import EditRole from "./components/pages/EditRole";
import AppSetting from "./components/pages/AppSetting";
import VerifyKyc from "./components/pages/VerifyKyc";
import BuyOrder from "./components/pages/BuyOrder";
import Withdrawal from "./components/pages/Withdrawal";
import deposit from "./components/pages/Deposit";
import DepositFund from "./components/pages/DepositFund";
import SellOrder from "./components/pages/SellOrder";
import RoleManager from "./components/pages/RoleManager";
import FundRequestVerify from "./components/pages/FundRequestVerify";
import RewardHistory from "./components/pages/RewardHistory";
import Users_complite_record from "./components/pages/Users_complite_record";
import Users_pending_record from "./components/pages/Users_pending_record";
import Users_reject_record from "./components/pages/Users_reject_record";
import BankDetailPending from "./components/pages/BankDetailPending";
import Permotion from "./components/pages/Permotion";
import contestParticipationList from "./components/pages/ContestParticipationList";
import gstInvoice from "./components/pages/gstInvoice";
import gstInvoiceUser from "./components/pages/gstInvoiceUser";
import React, { Component } from 'react';
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import '../node_modules/bootstrap/dist/js/bootstrap';
import '../node_modules/font-awesome/css/font-awesome.css';
import '../node_modules/jquery/dist/jquery.min';
import '../node_modules/popper.js/dist/popper';
import ContestViewDetail from "./components/pages/ContestViewDetail";
import NotificationAdd from "./components/pages/NotificationAdd";



if (localStorage.jwtToken) {
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const decoded = jwt_decode(token);
    store.dispatch(setCurrentUser(decoded));
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser());
        window.location.href = "./login";
    }
}

if(localStorage.jwtToken) {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event){
        window.history.pushState(null, document.title,  window.location.href);
    });
  }
  
class App extends Component {
    render () {        
      return (
            <Provider store={store}>
            <Router>
                    <div className="App">
                        <Switch>
                            <Route exact path="/" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/login" component={Login} />
                              <Route exact path="/forgot-password" component={ForgotPassword} />
                              <Route exact path="/password-reset/:userId/:token" component={ResetPassword} />
                                <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                                <PrivateRoute exact path="/users" component={User} />
                                <PrivateRoute exact path="/block-users" component={BlockUser} />
                                <PrivateRoute exact path="/country" component={Country} />
                                <PrivateRoute exact path="/state" component={State} />
                                <PrivateRoute exact path="/contest_management" component={Contest} />
                                <PrivateRoute exact path="/crypto_currency" component={Crypto} />
                                <PrivateRoute exact path="/pair_management" component={Pair} />
                                <PrivateRoute exact path="/chat-room/:id" component={ChatRoom} />
                                <PrivateRoute exact path="/verifyKyc/:id/:type" component={VerifyKyc} />
                                <PrivateRoute exact path="/edit-contest/:id" component={EditContest} />
                                <PrivateRoute exact path="/fund-request-verify/:id" component={FundRequestVerify} />
                                <PrivateRoute exact path="/suport" component={Suport} />
                                <PrivateRoute exact path="/users-complite-record" component={Users_complite_record} />
                                <PrivateRoute exact path="/users-reject-record" component={Users_reject_record} />
                                <PrivateRoute exact path="/Users-pending-record" component={Users_pending_record} />
                                <PrivateRoute exact path="/bank-detail" component={BankDetailPending} />
                                <PrivateRoute exact path="/add-contest" component={AddContest} />
                                <PrivateRoute exact path="/add-contest/:id" component={AddContest} />
                                <PrivateRoute exact path="/withdrawal-inr" component={Withdrawal} />
                                <PrivateRoute exact path="/deposit-inr" component={deposit} />
                                <PrivateRoute exact path="/buy-order" component={BuyOrder} />
                                <PrivateRoute exact path="/sell-order" component={SellOrder} />
                                <PrivateRoute exact path="/role-manager" component={RoleManager} />
                                <PrivateRoute exact path="/add-role" component={addRole} />
                                <PrivateRoute exact path="/edit-role/:id" component={EditRole} />
                                <PrivateRoute exact path="/deposit-fund" component={DepositFund} />
                                <PrivateRoute exact path="/contest-detail" component={ContestDetail} />
                                <PrivateRoute exact path="/contest-participation-list/:id" component={contestParticipationList} />
                                <PrivateRoute exact path="/contest-view-detail/:id" component={ContestViewDetail} />
                                <PrivateRoute exact path="/reward-history" component={RewardHistory} />
                                <PrivateRoute exact path="/app-setting" component={AppSetting} />
                                <PrivateRoute exact path="/temp-user" component={TempUser} />
                                <PrivateRoute exact path="/gst-invoice" component={gstInvoice} />
                                <PrivateRoute exact path="/gst-invoice-user" component={gstInvoiceUser} />
                                <PrivateRoute exact path="/bunner-manage" component={BunnerManage} />
                                <PrivateRoute exact path="/notification" component={NotificationAdd} />
                                <PrivateRoute exact path="/permotion" component={Permotion} />
                                <PrivateRoute exact path="/dummyUser" component={DummyUsers} />
                                <PrivateRoute exact path="/contestDummyUsers" component={ContestDummyUsers} />                                
                                <PrivateRoute exact path="/error-log" component={ErrorLogs} />
                                
                            <Route exact path="*" component={NotFound} />
                        </Switch>
                    </div>
                </Router>
            </Provider>
        );
    }
}

export default App;
