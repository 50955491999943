const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    role: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {
       
        case 'ROLE_ADD':
        return {
                isAuthenticated: !isEmpty(action.payload),
                role: action.payload
        };
      
        case 'ROLE_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                role: action.payload,
            };
        case 'SET_CURRENT_ROLE':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                role: action.payload
            };   
            case 'GET_ERRORS':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                role: action.payload
            }; 
      
        case 'ROLE_LOADING':
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
