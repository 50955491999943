import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { BrowserRouter as Router, Route, Switch,Redirect,withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addContest } from "../../actions/contestActions";
import LiveContest from "../partials/contest/LiveContest";
import UpcomingContest from "../partials/contest/UpcomingContest";
import PastContest from "../partials/contest/PastContest";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {  } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
  headers: {
    Authorization: `${localStorage.getItem("jwtToken")}`,
  },
};
class ContestDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      liveRecord: [],
      upcomingRecord: [],
      pastRecord: [],
      errors: {}
    };
    
    this.getData = this.getData.bind(this);
  }
 
  componentDidMount() {
    if (this.props.auth.user.contest_management !==undefined && this.props.auth.user.contest_management !==true ) {
      this.props.history.push("/dashboard");
  }
    this.getData();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.contest_management !==undefined && nextProps.auth.user.contest_management !==true ) {
      this.props.history.push("/dashboard");
    }
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }       
  }

  getData() {	  
	  axios
    .post(baseUrl+"api/live-record", {},configToken)
    .then(res => {
        this.setState({
            liveRecord: res.data.data,            
        });
     })
    .catch();	  
    axios
      .post(baseUrl+"api/upcoming-record",{},configToken)
      .then(res => {
        this.setState({ upcomingRecord: res.data.data })
      })
      .catch()
      axios
      .post(baseUrl+"api/admin-past-record",{},configToken)
      .then(res => {
        this.setState({ pastRecord: res.data.data })
      })
      .catch()
  }
   nextButtonClick=()=>{
	  $('.nav-item > .active').parent().next('li').find('a').trigger('click');
  }
  
  
   prevButtonClick=()=>{
	 $('.nav-item > .active').parent().prev('li').find('a').trigger('click');
  }
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  render() {
    const { errors } = this.state;
    
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <h1 className="mt-2 text-primary">Contest Detail</h1>
                   <div className="man_white_box padding_d ">
                    <ul className="nav nav_left_tab">
                      <li className="nav-item">
                        <a className="active show" data-toggle="tab" href="#live">
                          <i className="la la-mobile-phone l_i"></i>
                          Live Contest
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#upcomming">
                          <i className="la la-user l_i"></i>
                         Upcoming Contest
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#past">
                          <i className="la la-map-marker l_i"></i>
                          Past Contest
                        </a>
                      </li>
                    </ul>
                    <hr />
                      <div className="white_box">
                        <div className="tab-content ">
                          <div className="tab-pane active show" id="live">                                                      	
                            <LiveContest record={this.state.liveRecord}/>						
                          </div>
                          <div className="tab-pane" id="upcomming">
                            <UpcomingContest record={this.state.upcomingRecord}/>	
                          </div>
                          <div className="tab-pane" id="past">
                            <PastContest record={this.state.pastRecord}/>	
                          </div>                          
                        </div>
                      </div>
                  </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }

}

ContestDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  contestdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  contestdata: state.liveRecord,
  errors: state.errors,
  records: state.liveRecord
});

export default connect(
  mapStateToProps
)(withRouter(ContestDetail));