import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class Users_Complete_record extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "name",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "mobile",
                text: "Mobile",
                className: "mobile",
                align: "left",
                sortable: true
            },
            // {
            //     key: "fImage_status",
            //     text: "Front Image",
            //     className: "frontImage",
            //     align: "left",
            //     sortable: true
            // },
            {
                key: "bImage_status",
                text: "Front/Back Image",
                className: "backImage",
                align: "left",
                sortable: true
            },
            {
                key: "taxStatus",
                text: "Tax Status",
                className: "taxStatus",
                align: "left",
                sortable: true
            },
            {
                key: "verifiedBy",
                text: "Verified By",
                className: "verifiedBy",
                align: "left",
                sortable: true
            },
            {
                key: "kycCompletetAt",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.kycCompletetAt).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                     {(record.fImage_status !==undefined || record.bImage_status !==undefined) &&
                        <Link to={"/verifyKyc/"+ record._id +"/"+2} className="btn btn_man">View Details</Link>
                     }
                        </Fragment>
                    );
                }
            }       
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users Complete Verifications",
            no_data_text: 'No user found!',
            button: {
               
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
                password: '',
                password2: '',
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.verifyComplete !==undefined && this.props.auth.user.verifyComplete !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.verifyComplete !==undefined && nextProps.auth.user.verifyComplete !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    }

    getData() {
        axios
            .post(baseUrl+"api/user-complete-data",{},config)
            .then(res => {
                this.setState({ records: res.data.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/user-delete", {_id: record._id},config)
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>

                            <h1 className="mt-2 text-primary">Users Complete Verify List</h1>
							 <div className="man_white_box ">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }

}

Users_Complete_record.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Users_Complete_record);
