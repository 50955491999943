import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {Link} from "react-router-dom";
import {faUserAlt} from "@fortawesome/free-solid-svg-icons/faUserAlt";
import Moment from 'moment';
import axios from "axios";
import * as myConstList from '../../BaseUrl';
const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
         balance:0,
         upcomingTotal:0,
         pastTotal:0,
         liveTotal:0,
         loginHostory:[],
         userTotal:0,
         pendingTotal:0,
         completeTotal:0,
         supportotalPending:0,
         supportotalResolve:0,
         totalDeposit:0,
         rejectedTotal:0,
         errors: {}
         };
       this.getData = this.getData.bind(this);
    }
    
    componentWillUnmount() {
        
        this.getData();
    }
    componentWillReceiveProps(nextProps) {
        this.getData()
    }
    
    getData() {
        const { user } = this.props.auth;
        setTimeout(
             axios
            .post(baseUrl+"api/login-history-data",{},configToken)
            .then(res => {
                console.log("user"+res.data);
                this.setState({ loginHostory: res.data.data})
            })
            .catch()
            , 2000);
            setTimeout(
            axios
            .post(baseUrl+"api/getUserTotal",{},configToken)
            .then(res => {
                if(res.data.length>0){
                    this.setState({userTotal: res.data[0].count})
                }                
            })
            .catch()
            ,2000);
            setTimeout(
            axios
            .post(baseUrl+"api/user-pending-data",{},configToken)
            .then(res => {
                this.setState({ pendingTotal: res.data.data.length})
            })
            .catch()
            ,2000);
            setTimeout(
            axios
            .post(baseUrl+"api/user-complete-data",{},configToken)
            .then(res => {
                this.setState({ completeTotal: res.data.data.length})
            })
            .catch()
            ,2000);
            setTimeout(
            axios
            .post(baseUrl+"api/user-reject-data",{},configToken)
            .then(res => {
                this.setState({ rejectedTotal: res.data.data.length})
            })
            .catch()
            ,2000);
            setTimeout(
            axios
            .post(baseUrl+"api/totalHelp",{'status':'Pending'},configToken)
            .then(res => {
                this.setState({ supportotalPending: res.data.data})
            })
            .catch()
            ,2000);
            setTimeout(
            axios
            .post(baseUrl+"api/totalHelp",{'status':'Resolved'},configToken)
            .then(res => {
                this.setState({ supportotalResolve: res.data.data})
            })
            .catch()
            ,2000);
            setTimeout(
            axios
            .get(baseUrl+"api/totalDeposit",{},configToken)
            .then(res => {
                this.setState({ totalDeposit: res.data.data})
            })
            .catch()
            ,2000);
            
    }
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    getcofig(){
        const config = {
            headers: {
              Authorization: `${localStorage.getItem("jwtToken")}`,
            },
          };
    }
    componentDidMount() {
        // if(jwtToken ==="" || jwtToken === null){
        //     window.location.reload();
        // }
        this.getData();
    };
    showLoginHistoryHtml = () => {
        const html = []
        var classThis=this;
        if(this.state.loginHostory.length>0){
            this.state.loginHostory.map(function (value, i) {
         
                html.push(
                    <> <tr>
                    <td>{i+1}</td>
                    <td>{value.ip}</td>
                    <td>{value.cityName}</td>
                    <td>{value.countryName}</td>
                    <td>{value.device}</td>
                    <td>{value.browser}</td>
                    <td>{value.os}</td>
                    <td>{Moment(value.date).format('lll')}</td>
                </tr>
                </>
                );
            })
        }
        return html;
    }
    render() {
        const { user } = this.props.auth;
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-2" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h3 className="mt-2 man_hadding">Dashboard</h3>
                            <div className="row px-2">
                            {user.userManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card d_box">
                                            <Link to="/users" className="card-body">
                                            <h6>Users</h6>
                                            <h4>{this.state.userTotal}</h4>
                                            <div className="d_icon"><FontAwesomeIcon icon={faUserAlt}/></div>
                                            </Link>
                                    </div>
                                </div>
                            }
                            {user.kycManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                <div className="card  d_box ">
                                            <Link to="/Users-pending-record" className="card-body">
                                                <h6>KYC Pending</h6>
                                                <h4>{this.state.pendingTotal}</h4>
                                                <div className="d_icon"><i className="fa fa-hourglass-end" ></i></div>
                                            </Link>
                                        </div>
                                    </div>
                            }
                             {user.kycManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card  d_box ">
                                          <Link to="/users-complite-record" className="card-body">
                                            <h6>KYC Verified</h6>
                                            <h4>{this.state.completeTotal}</h4>
                                            <div className="d_icon"><i className="fa fa-check-circle" ></i>
</div>
                                          </Link>
                                        </div>
                                    </div>
                               
                                 }
                                 {user.kycManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card d_box ">
                                          <Link to="/users-reject-record" className="card-body">
                                            <h6>KYC Rejected</h6>
                                            <h4>{this.state.rejectedTotal}</h4>
                                            <div className="d_icon"><i className="fa fa-check-circle" ></i></div>
                                          </Link>
                                        </div>
                                    </div>
                               
                                 }
                                {user.suportManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card  d_box">
                                        <Link to="/suport" className="card-body">
                                            <h6>Support Resolve</h6>
                                            <h4>{this.state.supportotalResolve}</h4>
                                             <div className="d_icon"><i className="fa fa-headphones" ></i></div>
                                          </Link>
                                        </div>
                                    </div>
                               
                                }
                                {user.suportManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card  d_box">
                                        <Link to="/suport" className="card-body">
                                            <h6>Support Pending</h6>
                                            <h4>{this.state.supportotalPending}</h4>
                                           <div className="d_icon"><i className="fa fa-hourglass-end" ></i></div>
                                          </Link>
                                        </div>
                                    </div>
                               
                                }
                                {user.depositManagement==true &&
                                <div className="col-sm-3 p-sm-2">
                                    <div className="card d_box">
                                        <Link to="/deposit-inr" className="card-body">
                                            <h6>Total Deposit</h6>
                                            <h4>{this.state.totalDeposit}</h4>
                                           <div className="d_icon"><i className="fa fa-inr" ></i></div>
                                          </Link>
                                        </div>
                                    </div>
                               
                                }
                                
                            </div>
                            <div className="crypt-market-status2 mt-4 ">
                           
                            <div className="man_white_box ">
                            <h5 className="pb-3">Login History</h5>
                            <div className="table-responsive ">
                                <table className="table table  border_none table-striped mb-0">
                                    <thead className="thead">
                                        <tr>
                                               <th>#</th>
                                            <th>IP Address</th>
                                            <th>City</th>
                                            {/* <th>State</th> */}
                                            <th>Country</th>
                                            <th>Device</th>
                                            <th>Browser</th>
                                            <th>OS</th>
                                            <th>Date and Time</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       {this.showLoginHistoryHtml()}
                                     
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Dashboard.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    { logoutUser }
)(Dashboard);
