import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import PairAddModal from "../partials/PairAddModal";
import PairUpdateModal from "../partials/PairUpdateModal";
import {toast,Toaster } from 'react-hot-toast';
import Moment from 'moment';
import $ from 'jquery';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class Pair extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "name",
                text: "First Currency Box",
                className: "name",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.cryptoCurrency.name}
                        </Fragment>
                    );
                }
            },
            {
                key: "box2",
                text: "Second Currency Box",
                className: "bax2",
                align: "left",
                sortable: true,
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}} title="Edit">
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)} title="Delete">
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Pair List",
            no_data_text: 'No user found!',
            button: {
               
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            cryptoList:[]
        };

        this.state = {
            currentRecord: {
                id: '',
                box1: '',
                box2: ''
              }
          };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.pairManagement !==undefined && this.props.auth.user.pairManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.pairManagement !==undefined && nextProps.auth.user.pairManagement !==true ) {
            this.props.history.push("/dashboard");
        }        
        if (nextProps.pairdata !== undefined    
            && nextProps.pairdata.pair !== undefined
            && nextProps.pairdata.pair.data !== undefined && nextProps.pairdata.pair.data.status
            && nextProps.pairdata.pair.data.message !== undefined) {
            $('#add-user-modal').modal('hide');
            $('#update-user-modal').modal('hide');
            toast.success(nextProps.pairdata.pair.data.message, toastStyles);
            setTimeout(function() { window.location.reload(); },1500);
        }else if(!nextProps.pairdata.pair.data.status){
            toast.error(nextProps.pairdata.pair.data.message, toastStyles);
        }
        this.getData();
    }

    getData() {
        axios
            .post(baseUrl+"api/pair-data")
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
            axios
            .post(baseUrl+"api/crypto-data")
            .then(res => {
                this.setState({ cryptoList: res.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/pair-delete", {_id: record._id})
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles)
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <PairAddModal cryptoList={this.state.cryptoList}/>
                    <PairUpdateModal record={this.state.currentRecord} cryptoList={this.state.cryptoList}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <button className="btn btn-outline-primary float-right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> Add Pair</button>
                            <h1 className="mt-2 text-primary">Pair Management </h1>
							 <div className="man_white_box ">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <Toaster/>
                </div>
            </div>
        );
    }

}

Pair.propTypes = {
    auth: PropTypes.object.isRequired,
    pairdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    pairdata: state.pairdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Pair);
