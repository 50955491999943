import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import {Link} from "react-router-dom";
import axios from 'axios';
import { isMobile, isDesktop, isAndroid, isIOS, browserName, browserVersion, osName, osVersion } from "react-device-detect";
class Login extends Component {
    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
            this.setState({
                errors: {},
                email:"",
                password:''
            }); 
        }
        this.getUserIp();
        this.DeviceName();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.isAuthenticated) {
            // this.props.history.push("/dashboard");
            window.location.href = "/dashboard";
        }
        // if (nextProps.errors) {
        //     console.log("nextProps.errors==",nextProps.errors);
        //     this.setState({
        //         errors: nextProps.errors,
        //     });
        // }else{
        //     this.setState({
        //         errors: {},
        //     });
        // }
   
    }

    getUserIp = ()=> {
        
        axios.get('https://geolocation-db.com/json/').then((response) => {
			let data = response.data;
			this.setState({ip:data.IPv4 ? data.IPv4 : ""
						});
		}).catch((error) => {
			console.log(error);
		});
	}
    DeviceName = () => {
		if (isMobile) { 
			this.setState({ device: "Mobile" });
		}
		else if (isAndroid) {
			this.setState({ device: "Android" });
		}
		else if (isIOS) {
			this.setState({ device: "IOS" });
		}
		else if (isDesktop) {
			this.setState({ device: "Desktop" })
		}
	}
    onChange = e => {
        this.setState({ errors:{
            
        } });
        this.setState({ [e.target.id]: e.target.value ,errors:{
            
        }});
    };
    onSubmit = e => {
        e.preventDefault();
        if(this.state.email =="" || typeof this.state.email =='undefined'){
            this.setState({errors:{email:"Please enter email"}});
            return false;
        }
        if(this.state.password =="" || typeof this.state.password =='undefined'){
            this.setState({errors:{password:"Please enter password"}});
            return false;
        }
        const userData = {
            email: this.state.email,
            password: this.state.password,
            ip:this.state.ip,
            deviceName:this.state.device,
			browserName:browserName,
			osName:osName
        };
        this.setState({errors:{}});
        this.props.loginUser(userData).then(res=>{console.log("res==",res); this.setState({
                    errors: res.data,
                });
            });

    };

    render() {
        const { errors } = this.state;
        // console.log("erorrrs============",errors);
        return (
            <div className="login_bg">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-5 mx-auto ">
                    <div className="text-center">
					 <img src="img/logo-login.png" className="img-fluid" />
					 </div>
                    <div className="mx-auto mt-3 card shadow-lg">
					 
                        <div className="card-body p-3 p-md-4">
                            <form noValidate onSubmit={this.onSubmit} className="white">
							 <div className="form-group">
                                <label className="mb-1" htmlFor="email">Email</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="email"
                                    className={classnames("form-control", {
                                        invalid: errors.email
                                    })}
                                />
                                <span className="text-danger">{errors.email}</span>
                                </div>
								<div className="form-group ">
                                <label className="mb-1" htmlFor="password">Password</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.password}
                                    error={errors.password}
                                    id="password"
                                    type="password"
                                    className={classnames("form-control", {
                                        invalid: errors.password
                                    })}
                                />   
								
								
								</div> 
                                <span className="text-danger">{errors.password}</span>

                                <div className="form-group ">
    <Link to="/forgot-password" >Forgot password?</Link>

  </div> 
                                <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn man_btn  w100  mt-2 px-5">
                                        Login
                                    </button>
                                </p>
                            </form>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            </div>
        );
    }
}

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { loginUser }
)(Login);
