import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from "axios";
import {Link} from "react-router-dom";
import $ from 'jquery';
import {faLuggageCart, faPlus} from "@fortawesome/free-solid-svg-icons";
import AddContest from "../partials/AddContest";
import ContestUpdateModal from "../partials/ContestUpdateModal";
import {toast,Toaster } from 'react-hot-toast';
import * as myConstList from '../../BaseUrl';
import socketIOClient from "socket.io-client";
const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class Contest extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
    //          {
    //             key: "contestLogo",
    //             text: "Contest Logo",
    //             className: "contestLogo",
    //             align: "left",
    //             sortable: true,
    //               cell: record => {
    //                 return (
    //                     <Fragment>
    //                     <img style={{ width: '92px',
    // height: '70px'}} src={baseUrl+"static/logo/" + record.contestLogo} />
                         
                        
    //                     </Fragment>
    //                 );
    //             }
    //         },
            {
                key: "contestName",
                text: "Contest Name",
                className: "contestName",
                align: "left",
                sortable: true,
            }, 
            {
                key: "max_particepate",
                text: "Max Participate",
                className: "max_particepate",
                align: "left",
                sortable: true,
            },   {
                key: "startDate",
                text: "Start Date",
                className: "startDate",
                align: "left",
                sortable: true
            },
            {
                key: "startTime",
                text: "Start Time",
                className: "startTime",
                align: "left",
                sortable: true
            },
              {
                key: "endDate",
                text: "End Date",
                className: "endDate",
                align: "left",
                sortable: true
            },  
            {
                key: "endTime",
                text: "End Time",
                className: "endTime",
                align: "left",
                sortable: true
            },  
            {
                key: "entryFee",
                text: "Entry Fee",
                className: "entryFee",
                align: "left",
                sortable: true
            },


            {
                key: "virtualFund",
                text: "Virtual Fund",
                className: "virtualFund",
                align: "left",
                sortable: true
            },
            {
                key: "createdBy",
                text: "Created By",
                className: "upcase",
                align: "left",
                sortable: true
            },            
			{
                key: "publish",
                text: "Publish",
                className: "Publish",
                align: "left",
                sortable: true
            },
			
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {					
                    if(record.numOfParticipation ==0){
                        return (
                            <Fragment>
							<Link to={"/add-contest/"+ record._id}  className="btn btn-primary btn-sm" title="Copy contest"><i className="fa fa-copy"></i></Link>
                                <Link to={"/edit-contest/"+ record._id}  className="btn btn-primary btn-sm" title="Edit"><i className="fa fa-edit"></i></Link>
                                <button
                                    className="btn btn-danger btn-sm"
                                    onClick={() => this.deleteRecord(record)} title="Delete">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </Fragment>
                        );
                    }else{
                        return (
                            <Fragment>
							<Link to={"/add-contest/"+ record._id}  className="btn btn-primary btn-sm" title="Copy contest"><i className="fa fa-copy"></i></Link>
                                <button className="disabled btn btn-primary btn-sm" title="Disable edit"><i className="fa fa-edit" title="Edit"></i></button>
                                <Link to={"/contest-participation-list/"+ record._id}  className="btn btn-primary btn-sm" title="Participations"><i className="fa fa-users"></i></Link>
                                <button
                                    className="disabled btn btn-danger btn-sm" title="Not delete">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </Fragment>
                        );
                    }
                    
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Contest List",
            no_data_text: 'No user found!',
            button: {                
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: []
        };
        this.state = {
            currentRecord: {
                id: '',
                contestLogo: '',
                contestName: '',
                startTime: '',
                endTime: '',
                entryFee: '',
                virtualFund: ''
            }
        };
    this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        if (this.props.auth.user.contest_management !==undefined && this.props.auth.user.contest_management !==true ) {
            this.props.history.push("/dashboard");
        } 
        if (this.props.contestdata !== undefined
            && this.props.contestdata.contest !== undefined
            && this.props.contestdata.contest.data !== undefined
            && this.props.contestdata.contest.data.message !== undefined
            && this.props.contestdata.contest.data.success) {
                const socket = socketIOClient(baseUrl);
                socket.emit("contestFlag"); 
                socket.emit("totalContestData",{'token':jwtToken}); 
                socket.emit("upcomingRecordDashboard",{'token':jwtToken}); 
                socket.emit("liveRecordDashboard",{'token':jwtToken}); 
                socket.emit("getserverTime");
                // socket.emit("liveContest");
                // socket.emit("upcomingContestList");               
                // socket.emit("pastContestList");
                // socket.emit("myContestList");
        }  
        this.getData()
    };
      componentDidUpdate() {
        if (this.props.auth.user.contest_management !==undefined && this.props.auth.user.contest_management !==true ) {
            this.props.history.push("/dashboard");
        }      
        // this.getData()
      };
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.auth.user.contest_management !==undefined && nextProps.auth.user.contest_management !==true ) {
    //         this.props.history.push("/dashboard");
    //     }   
       
    //         this.getData()
    // }  
    onChange = e => {};
    getData() {
        axios
            .post(baseUrl+"api/contest-data",{},configToken)
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }
    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        confirmAlert({
            message: 'Are you sure to Delete Contest.',
            buttons: [
              {
                label: 'Yes',
                onClick: () => 
                { 
                    axios
                    .post(baseUrl + "api/contest-delete", { _id: record._id }, configToken)
                    .then(res => {
                        if (res.status === 200) {
                            toast.success(res.data.message, toastStyles)
                            this.getData();
                        }
                    })
                    .catch();
                }
              },
              {
                label: 'No',
                onClick: () => ""
              }
            ]
        });
    }
    // deleteRecord(record) {
    //     axios
    //         .post(baseUrl+"api/contest-delete", {_id: record._id},configToken)
    //         .then(res => {
    //             if (res.status === 200) {
    //                toast.success(res.data.message, toastStyles)
    //             }
    //         })
    //         .catch();
    //     this.getData();
    // } 
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <ContestUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <Link to={"/add-contest"} className="btn btn-outline-primary float-right">Add Contest</Link>
                            <h1 className="mt-2 text-primary">Contest List</h1>
							 <div className="man_white_box ">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                    <Toaster/>
                </div>
            </div>
        );
    }

}

Contest.propTypes = {
    auth: PropTypes.object.isRequired,
    contestdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    contestdata: state.contestdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Contest);
