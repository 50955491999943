import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
    GET_ERRORS,
    SET_CURRENT_USER,
    USER_LOADING
} from "./types";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
export const registerUser = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/register", userData)
        .then(res => history.push("/"))
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};

export const loginUser = userData => async dispatch => {    
   let responseData = await axios
    .post(baseUrl+"api/adminLogin", userData);
    
    if(responseData.data.status){
        const { token } = responseData.data;
        localStorage.setItem("jwtToken", token);
        setAuthToken(token);
        const decoded = jwt_decode(token);        
        dispatch(setCurrentUser(decoded));
    }else{
        return responseData;
    }
    // console.log("dsfsdfsdf=",responseData);
    // .then(res => {        
    //     const { token } = res.data;
    //     localStorage.setItem("jwtToken", token);
    //     setAuthToken(token);
    //     const decoded = jwt_decode(token);
    //     return res.data;
    //     // if(res.data.status){
    //         // dispatch(setCurrentUser(decoded));
    //     // }      
    // })
    // .catch(err =>
    //     {
    //         console.log("errrrrr=======",err);
    //         return err;
    //     }
    //     // dispatch({
    //     //     type: GET_ERRORS,
    //     //     payload: err.response.data
    //     // })
    // );
};
export const resetPassword = userData => dispatch => {
    
    axios
        .post(baseUrl+"api/reset-password", userData)
        .then(res => 
            dispatch({
                type: "FORGET",
                payload: res,
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};
export const forgetPassword = (userData, history) => dispatch => {
    axios
        .post(baseUrl+"api/forget-Password1", userData)
        .then(res => 
            dispatch({
                type: 'FORGET',
                payload: res,
            })
        )
        .catch(err =>
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        );
};



export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const setUserLoading = () => {
    return {
        type: USER_LOADING
    };
};

export const logoutUser = () => dispatch => {
    localStorage.removeItem("jwtToken");
    setAuthToken(false);
    dispatch(setCurrentUser({}));
};
