import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updatePair } from "../../actions/pairActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class PairUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            box11: this.props.record.box1,
            box22: this.props.record.box2,
            errors: {},
            cryptoList:[],
        };
    }

    componentWillReceiveProps(nextProps) {       
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                box1: nextProps.record.box1,
                box2: nextProps.record.box2,
                cryptoList: nextProps.cryptoList                
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.pairdata !== undefined
            && nextProps.pairdata.pair !== undefined
            && nextProps.pairdata.pair.data !== undefined
            && nextProps.pairdata.pair.data.message !== undefined) {
            this.setState({errors:{}
            });
        }      
    }

    onChange = e => {
        this.setState({errors:{}});
        if (e.target.id === 'user-update-box1') {
            this.setState({ box1: e.target.value });
        }
         if (e.target.id === 'user-update-box2') {
            this.setState({ box2: e.target.value });
        }
       
    };

    onPairUpdate = e => {
        e.preventDefault();
        const newPair = {
            _id: this.state.id,
            box1: this.state.box1,
            box2: this.state.box2
     };
     this.props.updatePair(newPair);
    };
    showHtml = () => {
        const html = []
            this.state.cryptoList.map(function (value, i) {
            html.push(<><option value={value._id}>{value.name}</option></>);                 
        })
        return html;
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Pair</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onPairUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box1">First Box</label>
                                        </div>
                                        <div className="col-md-9">
                                         <select className="form-control" id="user-update-box1" value= {this.state.box1}  error={errors.box1} onChange={this.onChange}>
                                            <option>Please select crypto currency</option>
                                            {this.showHtml()}
                                            </select>                                         
                                            <span className="text-danger">{errors.box1}</span>
                                        </div>
                                    </div>
                                      <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">Second Box</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                readOnly = {true} 
                                                id="user-update-box2"
                                                type="text"
                                                value={this.state.box2}
                                     
                                                className={classnames("form-control", {
                                                    invalid: errors.box2
                                                })}/>
                                            <span className="text-danger">{errors.box2}</span>
                                        </div>
                                    </div>
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PairUpdateModal.propTypes = {
    updatePair: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    pairdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    pairdata: state.pairdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updatePair }
)(withRouter(PairUpdateModal));
