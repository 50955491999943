import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class UserAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            vartualFund:"",
            bids:"",
            gainlossPercentage:"",
            userWin:"",
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userdata !== undefined
            && nextProps.userdata.newuser !== undefined
            && nextProps.userdata.newuser.data !== undefined
            && nextProps.userdata.newuser.data.message !== undefined) {
            this.setState({
                name:"",
                vartualFund:"",
                bids:"",
                gainlossPercentage:"",
                userWin:"",
                errors:{ }
            });
        }
    }

    onChange = e => {
        this.setState({ 
            errors:{ }
        });
        this.setState({ [e.target.id]: e.target.value });
        if(e.target.id ==='bids'){
            if(e.target.value <0){
                this.setState({errors:{bids:"Please enter valid bid"}});
            }else if(e.target.value <5){
                this.setState({userWin:'no',errors:{bids:""}});
            }            
        }
        // if(e.target.id ==='vartualFund'){
        //     if(e.target.value <0){
        //         this.setState({errors:{vartualFund:"Please enter valid vartual fund"}});
        //     }else{
        //         this.setState({errors:{vartualFund:""}});
        //     }     
        // }
        if(e.target.id ==='gainlossPercentage'){
            if(e.target.value <0){
                this.setState({errors:{gainlossPercentage:"Please enter valid percentage"}});
            }else{
                this.setState({errors:{gainlossPercentage:""}});
            }     
        }
    };

    onUserAdd = e => {
        e.preventDefault();
        if(typeof this.state.name =='undefined' || this.state.name ==""){
            this.setState({errors:{name:"Please enter name"}});
            return false;
        }
        // if(typeof this.state.vartualFund =='undefined' || this.state.vartualFund ==""){
        //     this.setState({errors:{vartualFund:"Please enter virtual fund"}});
        //     return false;
        // }
        if(typeof this.state.bids =='undefined' || this.state.bids ==""){
            this.setState({errors:{bids:"Please enter bids"}});
            return false;
        }

        if(this.state.bids<=0){
            this.setState({errors:{bids:"Please enter greater then 0"}});
            return false;
        }

        if(typeof this.state.gainlossPercentage =='undefined' || this.state.gainlossPercentage ==""){
            this.setState({errors:{gainlossPercentage:"Please enter gain loss percentage"}});
            return false;
        }
        if(typeof this.state.userWin =='undefined' || this.state.userWin ==""){
            this.setState({errors:{userWin:"Please enter user win"}});
            return false;
        }
        const newUser = {
            name: this.state.name,
            // vartualFund: this.state.vartualFund,
            bids: this.state.bids,
            gainlossPercentage: this.state.gainlossPercentage,
            userWin: this.state.userWin
        };
        this.props.addUser(newUser, this.props.history);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Dummy User</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUserAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })} required={true}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="email">Vartual Fund</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.vartualFund}
                                                error={errors.vartualFund}
                                                id="vartualFund"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.vartualFund
                                                })} required={true}
                                            />
                                            <span className="text-danger">{errors.vartualFund}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password">Bids</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.bids}
                                                error={errors.bids}
                                                id="bids"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.bids
                                                })} required={true}
                                            />
                                            <span className="text-danger">{errors.bids}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">Gain/Loss %</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                autoComplete={''}
                                                onChange={this.onChange}
                                                value={this.state.gainlossPercentage}
                                                id="gainlossPercentage"
                                                type="number"
                                                className={classnames("form-control", {
                                                    invalid: errors.gainlossPercentage
                                                })} required={true}
                                            />
                                            <span className="text-danger">{errors.gainlossPercentage}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="password2">User Win Set</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select
                                            onChange={this.onChange}
                                            value={this.state.userWin}
                                            id="userWin"
                                            error={errors.userWin}
                                            className={classnames("form-control", {
                                                invalid: errors.userWin,
                                            })}
                                            disabled={this.state.bids<5?true:""} required={true}
                                            >
                                            <option value="">Select</option>
                                            <option value="no">No</option>
                                            <option value="yes">Yes</option>
                                        </select>
                                            <span className="text-danger">{errors.userWin}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Add User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UserAddModal.propTypes = {
    addUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    userdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    userdata: state.userdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addUser }
)(withRouter(UserAddModal));
