import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addState } from "../../actions/stateActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';

class StateAddModal extends React.Component {

    constructor() {
        super();
        this.state = {
            name: "",
            c_id: "",
            countryList:[],
            errors: {},
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.countryList) {
            this.setState({
                countryList: nextProps.countryList
            })
        }
        // console.log("dataaaaaaaa========",nextProps.statedata.isAuthenticated,nextProps.errors);
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.statedata !== undefined
            && nextProps.statedata.state !== undefined
            && nextProps.statedata.state.data !== undefined
            && nextProps.statedata.state.data.message !== undefined) {          
            this.setState({errors:{},name:'',countryList:[]});
        }        
    }
    componentDidMount(){
        this.setState({
            errors: {}
        });
    }
    onChange = e => {
        this.setState({errors:{}});
        if (e.target.id === 'c_id') {
            this.setState({ c_id: e.target.value });
        }
        if (e.target.id === 'stateName') {
            this.setState({ name: e.target.value });
        }
    };

    onStateAdd = e => {
        e.preventDefault();
        const newState = {
            name: this.state.name,
            c_id: this.state.c_id
          
        };
        this.props.addState(newState, this.props.history);
    };
    showHtml = () => {
        const html = [];
        if(this.state.countryList.length>0){
            this.state.countryList.map(function (value, i) {
            html.push(
                <>  
                    <option value={value._id}>{value.name}</option>
                </>
            );       
            })
        }
     return html;
     }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Country</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onStateAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="c_id">Country Name</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select className={classnames("form-control", {
                                                invalid: errors.c_id
                                                })} id="c_id" error={errors.c_id} onChange={this.onChange} aria-label="Default select example">
                                                <option selected>Open this select menu</option>
                                                 {this.showHtml()}
                                            </select>
                                           <span className="text-danger">{errors.c_id}</span>
                                        </div>
                                    </div>
                                         <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">State Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="stateName"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

StateAddModal.propTypes = {
    addState: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    statedata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    statedata: state.statedata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addState }
)(withRouter(StateAddModal));
