import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import RejectButtonAdd from "./RejectButtonAdd";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class VerifyAddressImage extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: "",
            addProofImage: '',
            addressStatus: '',
          
            reject: '',
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps');
        console.log(nextProps.record);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                addProofImage: nextProps.record.addProofImage,
                addressStatus: nextProps.record.addressStatus,
                reject: nextProps.record.add_rejectResion,
                records: nextProps.record,
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message4 !== undefined
        //     && nextProps.auth.user.data.success) {

        //     toast(nextProps.auth.user.data.message4, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };
    type = () => {
        // alert( "add");
        this.setState({ catType: "add" });
    };
    onUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
             type: 'add'
        };
        this.props.verifyStatus(newUser);
    };
  
    render() {
        const { errors } = this.state;
        const { user } = this.state;
        return (
            <>
                {this.state.addressStatus !== undefined &&
                    <>
                        <form noValidate onSubmit={this.onUpdate} id="update-user">
                            <p><b>Address Proof :</b> {this.state.addressStatus}</p>
                            <Link to={baseUrl+"/address/" + this.state.addProofImage} target="_blank"><img src={baseUrl+"/address/" + this.state.addProofImage} className="img-thumbnail" alt="Cinque Terre"></img></Link>
                            {this.props.match.params.type == 2 &&
                        
                            <div style={{ display: (this.state.addressStatus != "verify" && this.state.addressStatus != "reject" ? 'block' : 'none') }}>
                                <button type="submit">Verify</button>
                            </div>
                            }
                            </form>
                            {this.props.match.params.type == 2 &&
                            <RejectButtonAdd record={this.state.records} docStatus={this.state.addressStatus} rejectStatus={this.state.reject}/>
                            }
                       
                        <hr />
                    </>
                }
            </>
        )
    }
}

VerifyAddressImage.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyAddressImage));
