import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { BrowserRouter as Router, Route, Switch,Redirect,withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addContest } from "../../actions/contestActions";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {  } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from '../../BaseUrl';
import Leaderboard from "../partials/Leaderboard";
import Participate from "../partials/Participate";
const renderHTML = require('react-render-html');
const baseUrl = myConstList.baseUrl;
const configToken = {
  headers: {
    Authorization: `${localStorage.getItem("jwtToken")}`,
  },
};
class ContestDetail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      contestId:this.props.match.params.id,
      leaderboardRecord: [],
      participateRecord: [],
      contestRecords:{},
      levels:[],
      errors: {}
    };
    
    this.getData = this.getData.bind(this);
  }
 
  componentDidMount() {
    if (this.props.auth.user.ContestDetail !==undefined && this.props.auth.user.ContestDetail !==true ) {
      this.props.history.push("/dashboard");
  }
    this.getData();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.ContestDetail !==undefined && nextProps.auth.user.ContestDetail !==true ) {
      this.props.history.push("/dashboard");
    }
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }       
    }

  getData() {	  
	  axios
    .post(baseUrl+"api/leaderboard-data", {contestId:this.state.contestId},configToken)
    .then(res => {
        this.setState({
            leaderboardRecord: res.data.data,            
            participateRecord: res.data.data,            
        });
     })
    .catch(); 
    axios
    .post(baseUrl + "api/contestByID-data", { _id: this.props.match.params.id}, configToken)
    .then(res => {
        console.log("res.data.data",res.data.data.item);
        if(res.data.data.item){
            var arrData = res.data.data.item;
            let arr = arrData.levels.split(',');
            let cleanText = arrData.description.replace(`/</?[^>]+(>|$)/g`, "");
            this.setState({
                contestRecords: res.data.data.item,
                levels:arr,
                description:renderHTML(cleanText)
            })
        }        
    })
    .catch()   
  }

  showHtml = () => {
    const html = []
    this.state.levels.map(function (value, i) {
        html.push(
            <>
                <tr>
                    <th>{i + 1}</th>
                    <th className="text-right">{value}</th>
                </tr>
            </>
        );
    })
    return html;
  }
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  render() {
    const { errors } = this.state;
    
    return (
      <div><Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />          
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <div className="contest-participant-outer">
                <div className="row">
                  <div className="col-md-7 col-12">
                    <div className="contets-outer">
                      <div className="heading-outer">
                        <h5 className="ttc"><b>{this.state.contestRecords.contestName}</b></h5>
                      </div>
                      <div className="contest-detail-outer">
                        <div className="row">
                          <div className="col-md-6 col-5">
                            <p><b>Prize Fund</b></p>
                          </div>
                          <div className="col-md-6 col-7 text-right">
                            <h5 className="ine_hadding">{this.state.contestRecords.winningAmount} INR</h5>
                          </div>
                          <div className="col-md-6 col-5">
                            <p><b>Entry Fee</b></p>
                          </div>
                          <div className="col-md-6 col-7 text-right">
                            <h5 className="ine_hadding">{this.state.contestRecords.entryFee} INR</h5>
                          </div>
                        </div>
                      </div>
                      <div className="popup-btn-outer">
                        <a class="view_dital" href="javascript:void(0)" data-toggle="modal" data-target="#contest-rules">Contest Rules</a>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 col-12">
                    <div className="crypt-market-status2">
                      <div className="crypt-market-outer">
                        <div className="row">
                          <div className="col-6 mb-2">
                            <b className="d_hadding">Participants</b>
                          </div>
                          <div className="col-6 mb-2 text-right">
                            <div className="d_value2">{this.state.contestRecords.numOfParticipation}</div>
                          </div>
                          <div className="col-6 mb-2">
                            <b className="d_hadding">Start</b>
                          </div>
                          <div className="col-6 mb-2 text-right">
                            <div className="d_value2">
                              {this.state.contestRecords.startDate} {this.state.contestRecords.startTime} 
                            </div>
                          </div>
                          <div className="col-6 ">
                            <b className="d_hadding">End</b>
                          </div>
                          <div className="col-6 mb-2 text-right">
                            <div className="d_value2">
                            {this.state.contestRecords.endDate} {this.state.contestRecords.endTime} 
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="rank-table-outer">
                        <table className="table mb-0 winners_table2">
                          <tr>
                            <th className="th">Rank</th>
                            <th className="text-right th"> Winning Amount</th>
                          </tr>
                        </table>
                        <div className="winners_table">
                          <table className="table ">                            
                            {this.showHtml()}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <h1 className="mt-2 text-primary">Contest View Detail</h1>
                  <div className=" padding_d" >
                    <ul className="nav nav_left_tab">
                      <li className="nav-item">
                        <a className="active show" data-toggle="tab" href="#leaderboard">
                          <i className="la la-mobile-phone l_i"></i>
                          Leaderboard
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#participate">
                          <i className="la la-user l_i"></i>
                          Participate
                        </a>
                      </li>
                    </ul>
                    <hr />
                      <div className="white_box">
                        <div className="tab-content ">
                          <div className="tab-pane active show" id="leaderboard">                                                      	
                            <Leaderboard record={this.state.leaderboardRecord}/>						
                          </div>
                          <div className="tab-pane" id="participate">
                            <Participate record={this.state.participateRecord}/>	
                          </div>                        
                        </div>
                      </div>
                  </div>
            </div>
          </div>
          <div className="modal fade" id="contest-rules" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Contest Rules</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>{this.state.description}</p>
                </div>
              </div>
            </div>
          </div>
          <ToastContainer />
        </div>
      </div>
    );
  }

}

ContestDetail.propTypes = {
  auth: PropTypes.object.isRequired,
  contestdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  contestdata: state.leaderboardRecord,
  errors: state.errors,
  records: state.leaderboardRecord
});

export default connect(
  mapStateToProps
)(withRouter(ContestDetail));