import axios from "axios";

import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
export const addState = (stateData, history) => dispatch => {
    axios
        .post(baseUrl+"api/state-add", stateData,configToken)
        .then(res =>
            dispatch({
                type: 'STATE_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};


export const updateState = (stateData) => dispatch => {
    axios
        .post(baseUrl+"api/state-update", stateData,configToken)
        .then(res =>
            dispatch({
                type: 'STATE_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
