import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import VerifyFrontImage from "../partials/verify/VerifyFrontImage";
import VerifyBackImage from "../partials/verify/VerifyBackImage";
import VerifyIntermediateImage from "../partials/verify/VerifyIntermediateImage";
import VerifyAddressImage from "../partials/verify/VerifyAddressImage";
import VerifyTaxImage from "../partials/verify/VerifyTaxImage";
import VerifyVideoImage from "../partials/verify/VerifyVideoImage";
import { toast, Toaster } from 'react-hot-toast';
import $ from 'jquery';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
  style: {
    borderRadius: '10px',
    background: '#fff',
    color: '#000',
  },
}
class VerifyKyc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      records: [],
      fImage: '',
      tichekId: this.props.match.params.id,
    };
    this.getData = this.getData.bind(this);
  }
  onChange = e => {
    this.setState({ errors: {} });
    if (e.target.name === 'stateUser') { this.setState({ stateUser: e.target.value }); }
    this.setState({ [e.target.id]: e.target.value });
  };
  getData() {
    const { user } = this.props.auth;
    axios
      .post(baseUrl + "api/profile-data-admin", { _id: this.state.tichekId })
      .then(res => {
        this.setState({
          records: res.data.data,
          fImage: res.data.data.fImage,
          stateUser: res.data.data.state
        });
      })
      .catch();
  }
  updateState = e => {
    // console.log("this.state.stateUser==",this.state.stateUser);
    if (this.state.stateUser == "" || this.state.stateUser == null) {
      toast.error("Please enter state", toastStyles);
    } else {
      axios
        .post(baseUrl + "api/user-state-save", { state: this.state.stateUser, user_id: this.state.records._id })
        .then(res => {
          if (res.status) {
            toast.success("User State Updated Successfully", toastStyles);
          } else {
            toast.error("Something went wrong", toastStyles);
          }
        })
        .catch()
    }
  }
  
  componentDidMount() {
    if (this.props.auth.user.verifyPending !== undefined && this.props.auth.user.verifyPending !== true) {
      this.props.history.push("/dashboard");
    }
    this.getData()

  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.verifyPending !== undefined && nextProps.auth.user.verifyPending !== true) {
      this.props.history.push("/dashboard");
    }
    if (nextProps.userdata !== undefined
      && nextProps.userdata.newuser !== undefined
      && nextProps.userdata.newuser.data !== undefined
      && nextProps.userdata.newuser.data.message !== undefined
      && nextProps.userdata.newuser.data.status) {
      toast.success(nextProps.userdata.newuser.data.message, toastStyles);
    }
    this.getData();
  }
  pageChange(pageData) {
    console.log("OnPageChange", pageData);
  }
  render() {
    const { classThis } = this.state;
    return (
      <>
        <div>
          <Navbar />
          <div className="d-flex" id="wrapper">
            <Sidebar />
            <div id="page-content-wrapper">
              <div className="container-fluid">
                <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                {this.state.fImage !== undefined ?
                  <div className="container">
                    <div className="chat_box">
                      {/* <div> */}
                      {/* <VerifyFrontImage record={this.state.records} /> */}
                      {/* <VerifyBackImage record={this.state.records} /> */}
                      {/*<VerifyAddressImage record={this.state.records}/>*/}
                      {/* <VerifyTaxImage record={this.state.records} /> */}
                      {/*<VerifyVideoImage record={this.state.records} />*/}
                      {/* </div> */}
                      <div className="man_white_box ">
                        <div className="personal-detail-outer personal-detail-top">
                          <div className="row mt-2">
                            <div className="col-md-6 form-group mt-2">
                              <label class="fwb" for="name">Name : </label>
                              <span> {this.state.records.fname} {this.state.records.lname}</span>
                            </div>

                            <div className="col-md-6 form-group d-flex">
                            <div className="col-md-4 form-group d-flex">
                            <label class="fwb" for="name">State : </label>
                            </div>
                            <div className="col-md-8 form-group d-flex">
                              {/* <input type="text" className="form-control form-control"  placeholder="State" name="stateUser" id="stateUser" value={this.state.stateUser} onChange={this.onChange} required/> */}
                              <select onChange={this.onChange} className="form-control" value={this.state.stateUser} id="stateUser"
                                type="text" placeholder="State" name="stateUser" required>
                                <option value="">Please Select State</option>
                                <option {...this.state.stateUser == "AN" ? "selected" : ""} value="AN">Andaman and Nicobar Islands</option>
                                <option {...this.state.stateUser == "AP" ? "selected" : ""} value="AP">Andhra Pradesh</option>
                                <option {...this.state.stateUser == "AR" ? "selected" : ""} value="AR">Arunachal Pradesh</option>
                                <option {...this.state.stateUser == "AS" ? "selected" : ""} value="AS">Assam</option>
                                <option {...this.state.stateUser == "BR" ? "selected" : ""} value="BR">Bihar</option>
                                <option {...this.state.stateUser == "CG" ? "selected" : ""} value="CG">Chandigarh</option>
                                <option {...this.state.stateUser == "CH" ? "selected" : ""} value="CH">Chhattisgarh</option>
                                <option {...this.state.stateUser == "DN" ? "selected" : ""} value="DN">Dadra and Nagar Haveli</option>
                                <option {...this.state.stateUser == "DD" ? "selected" : ""} value="DD">Daman and Diu</option>
                                <option {...this.state.stateUser == "DL" ? "selected" : ""} value="DL">Delhi</option>
                                <option {...this.state.stateUser == "GA" ? "selected" : ""} value="GA">Goa</option>
                                <option {...this.state.stateUser == "GJ" ? "selected" : ""} value="GJ">Gujarat</option>
                                <option {...this.state.stateUser == "HR" ? "selected" : ""} value="HR">Haryana</option>
                                <option {...this.state.stateUser == "HP" ? "selected" : ""} value="HP">Himachal Pradesh</option>
                                <option {...this.state.stateUser == "JK" ? "selected" : ""} value="JK">Jammu and Kashmir</option>
                                <option {...this.state.stateUser == "JH" ? "selected" : ""} value="JH">Jharkhand</option>
                                <option {...this.state.stateUser == "KA" ? "selected" : ""} value="KA">Karnataka</option>
                                <option {...this.state.stateUser == "KL" ? "selected" : ""} value="KL">Kerala</option>
                                <option {...this.state.stateUser == "LA" ? "selected" : ""} value="LA">Ladakh</option>
                                <option {...this.state.stateUser == "LD" ? "selected" : ""} value="LD">Lakshadweep</option>
                                <option {...this.state.stateUser == "MP" ? "selected" : ""} value="MP">Madhya Pradesh</option>
                                <option {...this.state.stateUser == "MH" ? "selected" : ""} value="MH">Maharashtra</option>
                                <option {...this.state.stateUser == "MN" ? "selected" : ""} value="MN">Manipur</option>
                                <option {...this.state.stateUser == "ML" ? "selected" : ""} value="ML">Meghalaya</option>
                                <option {...this.state.stateUser == "MZ" ? "selected" : ""} value="MZ">Mizoram</option>
                                <option {...this.state.stateUser == "NL" ? "selected" : ""} value="NL">Nagaland</option>
                                <option {...this.state.stateUser == "OR" ? "selected" : ""} value="OR">Odisha</option>
                                <option {...this.state.stateUser == "PY" ? "selected" : ""} value="PY">Puducherry</option>
                                <option {...this.state.stateUser == "PB" ? "selected" : ""} value="PB">Punjab</option>
                                <option {...this.state.stateUser === "rj" ? "selected" : ""} value="RJ">Rajasthan</option>
                                <option {...this.state.stateUser == "SK" ? "selected" : ""} value="SK">Sikkim</option>
                                <option {...this.state.stateUser == "TN" ? "selected" : ""} value="TN">Tamil Nadu</option>
                                <option {...this.state.stateUser == "TS" ? "selected" : ""} value="TS">Telangana</option>
                                <option {...this.state.stateUser == "TR" ? "selected" : ""} value="TR">Tripura</option>
                                <option {...this.state.stateUser == "UP" ? "selected" : ""} value="UP">Uttar Pradesh</option>
                                <option {...this.state.stateUser == "UK" ? "selected" : ""} value="UK">Uttarakhand</option>
                                <option {...this.state.stateUser == "WB" ? "selected" : ""} value="WB">West Bengal"</option>
                              </select>
                              <button type="submit" class="btn btn-primary ml-3" onClick={this.updateState}>Save</button>
                            </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label class="fwb" for="name">DOB :</label>
                                <span> {this.state.records.dob}</span>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-group">
                                <label class="fwb" for="name">Gender :</label>
                                <span> {this.state.records.gender}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                            <div className="form-group">
                              <label class="fwb" for="name">
                                {this.state.records.docType === "id" &&
                                  <span>Aadhaar Number :</span>
                                }
                                {this.state.records.docType === "li" &&
                                  <span>Driving Licence :</span>
                                }
                                {this.state.records.docType === "ps" &&
                                  <span>Passport Number :</span>
                                }
                              </label>
                              <span> {this.state.records.documentNo}</span>
                            </div>
                        <div className="personal-detail-outer personal-detail-top">
                          <div className="row ">
                            <VerifyFrontImage record={this.state.records} />
                            <VerifyBackImage record={this.state.records} />
                          </div>
                        </div>
                        <VerifyTaxImage record={this.state.records} />

                      </div></div>
                  </div>
                  : <h2 className="verifydocfont man_white_box mt-3 text-center text-danger">KYC Document Not Uploaded</h2>}
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </>
    );
  }

}

VerifyKyc.propTypes = {
  auth: PropTypes.object.isRequired,
  userdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  userdata: state.userdata,
  records: state.records
});

export default connect(
  mapStateToProps
)(VerifyKyc);
