import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import VerifyFrontImage from "../partials/verify/VerifyFrontImage";
import FundRequestVerifyC from "../partials/verify/FundRequestVerifyC";
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class FundRequestVerify extends Component {

    constructor(props) {
        super(props);
        this.state = {
            results: [],
            balance: '',
            tichekId: this.props.match.params.id
        };
        this.getData = this.getData.bind(this);
    }
    getData() {
       
        axios
            .post(baseUrl+"api/fundRequestById-data", { _id:this.state.tichekId },config)
            .then(res => {
                this.setState({results: res.data});
               

                axios
                .post(baseUrl+"api/profile-data-admin", {_id:res.data.data.user_id },config)
                .then(res => {
                    this.setState({ 
                        balance: res.data.data.balance});
                  })
                .catch()
            })
            .catch()
         }
    componentDidMount() {
        if (this.props.auth.user.fundRequest !==undefined && this.props.auth.user.fundRequest !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();

    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.fundRequest !==undefined && nextProps.auth.user.fundRequest !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.transactiondata !== undefined
            && nextProps.transactiondata.transaction !== undefined
            && nextProps.transactiondata.transaction.data !== undefined
            && nextProps.transactiondata.transaction.data.message !== undefined
            && nextProps.transactiondata.transaction.data.success) {

            toast(nextProps.transactiondata.transaction.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
        this.getData();

    }
   render() {
      
        return (
            <>
                <div>
                    <Navbar />
                    <div className="d-flex" id="wrapper">
                        <Sidebar />
                        <div id="page-content-wrapper">
                            <div className="container-fluid">
                                <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                                <div className="container">
                                    <div className="chat_box">
                                        <div>
                                            <FundRequestVerifyC record={this.state.results} balance={this.state.balance} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </>

        );
    }

}

FundRequestVerify.propTypes = {
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata: state.transactiondata,
    records: state.records
});

export default connect(
    mapStateToProps
)(FundRequestVerify);
