import React, { Component, Fragment,useMemo } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import { toast, ToastContainer} from "react-toastify";
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
import { CompareArrowsOutlined } from "@material-ui/icons";
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
        Authorization: `${localStorage.getItem("jwtToken")}`,
    },
};
const toastStyles = {
    style: {
        borderRadius: '10px',
        background: '#fff',
        color: '#000',
    },
}
class Users_pending_record extends Component {
    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "contestName",
                text: "Contest Name",
                className: "contestName",
                align: "left",
                sortable: true,
            },
            {
                key: "entryFee",
                text: "Entry Fee",
                className: "entryFee",
                align: "left",
                sortable: true,
                accessor: 'entryFee', 
                footer: 50
            },
            {
                key: "numOfParticipation",
                text: "Total Participated",
                className: "taxStatus",
                align: "left",
                sortable: true,
            },            
            {
                key: "texable",
                text: "Texable Amount",
                className: "fee",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Number(record.texable).toFixed(3)}
                        </Fragment>
                    );
                }
            },
            {
                key: "cgst",
                text: "CGST+SGST",
                className: "gst",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.cgst.toFixed(3)}
                        </Fragment>
                    );
                }
            },
            {
                key: "igst",
                text: "IGST",
                className: "igst",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.igst.toFixed(3)}
                        </Fragment>
                    );
                }
            },
            {
                key: "amount",
                text: "Amount",
                className: "taxable",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.amount.toFixed(3)}
                        </Fragment>
                    );
                }
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users Pending Verifications",
            no_data_text: 'No user found!',
            button: {
               
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            totalRecord:{}
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
                password: '',
                password2: '',
            },
            contestName:"",
            startDate:"",
            endDate:""
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {   
        if (this.props.auth.user.verifyPending !==undefined && this.props.auth.user.verifyPending !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.verifyPending !==undefined && nextProps.auth.user.verifyPending !==true ) {
            this.props.history.push("/dashboard");
        }      
        this.getData();
    }

    getData() {
        // axios
        //     .post(baseUrl+"api/user-pending-data")
        //     .then(res => {
        //         this.setState({ records: res.data})
        //     })
        //     .catch()

        // axios
        //     .post(baseUrl+"api/gst-invoice-contest-data",{startDate:this.state.startDate,endDate:this.state.endDate},configToken)
        //     .then(res => {
        //         if(res.data.data.length>0){
        //             this.setState({ records: res.data.data})
        //             this.setState({ contestName: res.data.data[0].contestName})
        //         }                
        //     })
        //     .catch()
    }

    onChange = e => {
        this.setState({errors: {}});
        if (e.target.name === 'startDate') {this.setState({startDate: e.target.value});
        }
        if (e.target.name === 'endDate') {this.setState({endDate: e.target.value});}        
         this.setState({ [e.target.id]: e.target.value });
    };
    searchRecord= e => {
        if(this.state.startDate ==""){
            toast.error("Please select start date",toastStyles);
        }else if(this.state.startDate ==""){
            toast.error("Please select end date",toastStyles);
        }
        if(this.state.startDate !="" && this.state.endDate !=""){
            axios
            .post(baseUrl+"api/gst-invoice-contest-data",{startDate:this.state.startDate,endDate:this.state.endDate},configToken)
            .then(res => {
                if(res.data.data.length>0){
                    this.setState({ records: res.data.data})
                    this.setState({ totalRecord: res.data.totalData})
                }                
            })
            .catch();
        }        
    }
    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/user-delete", {_id: record._id},configToken)
            .then(res => {
                if (res.status === 200) {
                   toast(res.data.message, {
                       position: toast.POSITION.TOP_CENTER,
                   })
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">                         
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h1 className="mt-2 text-primary">GST Invoice List</h1>
							<div className="man_white_box ">							 
							  <form className="mb-4" noValidate onSubmit={this.onRoleUpdate} id="add-user">
                                    <div className="white_box">
                                        <div className="tab-content ">
                                            <div className="tab-pane active show" id="details">
                                                <div className="mt-2 row align-items-center">
                                                    <div className="col-md-2">
                                                        <label className="mb-md-0" htmlFor="version">Start Date</label>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            onChange={this.onChange}
                                                            value={this.state.startDate}
                                                            name="startDate"
                                                            type="date"
															class="form-control"
                                                        />
                                                    </div>                                              
                                                    <div className="col-md-2">
                                                        <label className="mb-md-0" htmlFor="name">End Date</label>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                            onChange={this.onChange}
                                                            value={this.state.endDate}
                                                            name="endDate"
                                                            type="date"
															class="form-control"
                                                        />
                                                    </div>   
                                                    <div className="col-md-2">
                                                        <button className="btn btn-primary w100" type="button" onClick={this.searchRecord}>Submit</button>
                                                    </div>
                                                </div>                                                
                                            </div>
                                        </div>
                                    </div>
                                </form>
							 <hr/>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                            {this.state.totalRecord && 
                                <div>								
                                    <div className="row tt_table">
                                        <div className="col-sm-6 fw600">Texable Total</div>
										 <div className="col-sm-6">{this.state.totalRecord.totalTexable.toFixed(3)}</div>										 
                                        <div className="col-sm-6 fw600">CGST+SGST Total</div>
										<div className="col-sm-6">{this.state.totalRecord.totalCgst.toFixed(3)}</div>
                                        <div className="col-sm-6 fw600">IGST Total</div>
										 <div className="col-sm-6">{this.state.totalRecord.totalIgst.toFixed(3)}</div>
                                        <div className="col-sm-6 fw600">Amount Total</div>
										<div className="col-sm-6">{this.state.totalRecord.totalAmount.toFixed(3)}</div>
                                    </div>                                  
                                </div>                            
                            }                            
                            </div>
                        </div>
                    </div>
                    <ToastContainer/>
                </div>
            </div>
        );
    }
}

Users_pending_record.propTypes = {
    auth: PropTypes.object.isRequired,
   
};

const mapStateToProps = state => ({
    auth: state.auth,
    
    records: state.records
});

export default connect(
    mapStateToProps
)(Users_pending_record);
