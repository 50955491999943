import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonTax from "./RejectButtonTax";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from '../../../BaseUrl';

const baseUrl = myConstList.baseUrl;
class VerifyTaxImage extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: "",
            panDocImage: '',
            taxStatus: '',
            reject: '',
            rotation: 0,
            height: null,
            width: null,
            errors: {},
        };
        this.rotate = this.rotate.bind(this);
        this.rotateleft = this.rotateleft.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this)
        this.handleZoomOut = this.handleZoomOut.bind(this)
        this.imgRef = React.createRef()
    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.record);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                taxStatus: nextProps.record.taxStatus,
                panDocImage: nextProps.record.panDocImage,
                reject: nextProps.record.tax_rejectResion,
                records: nextProps.record,

            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message5 !== undefined
        //     && nextProps.auth.user.data.success) {
        //     toast(nextProps.auth.user.data.message5, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }
    componentDidMount() {
        // Saving initial dimension of image as class properties
        this.initialHeight = "300";
        this.initialWidth = "300";
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUpdate = e => {

        e.preventDefault();
        // alert(this.state.type);
        const newUser = {
            _id: this.props.match.params.id,
            type: "tax"


        };
        this.props.verifyStatus(newUser);
    };

    rotate() {
        let newRotation = this.state.rotation + 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }
    rotateleft() {

        let newRotation = this.state.rotation - 90;
        if (newRotation >= 360) {
            newRotation = - 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }
    handleZoomIn() {
        // Fetching current height and width
        const height = this.imgRef.current.clientHeight
        const width = this.imgRef.current.clientWidth
        // Increase dimension(Zooming)
        this.setState({
            height: height + 10,
            width: width + 10,
        })
    }
    handleZoomOut() {
        // Assigning original height and width
        this.setState({
            height: 300,
            width: 500,
        })
    }
    render() {
        const { errors } = this.state;
        const { user } = this.state;
        return (
            <>
                {this.state.taxStatus !== undefined &&
                    <>
                        <div className="form-group">
                            <label class="fwb" for="name">
                                <span>PAN Number :</span>
                            </label>
                            <span> {this.state.records.panNo}</span>
                        </div>
                        <div className="row ">
                            {this.state.records.panDocImage !== undefined && this.state.records.panDocImage !== "" &&
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label class="fwb" for="name">
                                            <span>PAN Front :</span>

                                        </label>
                                        {/* <a href={baseUrl+"pan/"+this.state.records.panDocImage} target="_blank">
                                        <img src={baseUrl+"pan/"+this.state.records.panDocImage} class="img-thumbnail" alt="Cinque Terre"/>
                                        </a> */}
                                        <a href="javascript:void(0);" className='popup-img-outer' data-toggle="modal" data-target="#Imagemodal3" target="_blank" style={{ cursor: "pointer" }} ><img src={baseUrl + "/pan/" + this.state.records.panDocImage} class="img-thumbnail" alt="Pan Card Image" /></a>
                                    </div>
                                    <form noValidate onSubmit={this.onUpdate} id="update-user">
                                        <div className="form-group">
                                            {this.props.match.params.type == 2 &&
                                                <div style={{ display: (this.state.taxStatus != "verify" && this.state.taxStatus != "reject" ? 'inline-block' : 'none') }}>
                                                    <button type="submit" class="btn btn-primary mr-3">Approve</button>
                                                </div>}
                                            {this.props.match.params.type == 2 &&
                                                <RejectButtonTax record={this.state.records} docStatus={this.state.records.taxStatus} rejectStatus={this.state.reject} />
                                            }
                                        </div>
                                    </form>
                                </div>
                            }
                        </div>


                        <div className="modal fade" id="Imagemodal3">
                            <div className="modal-dialog modalSize">
                                <div className="modal-content">
                                    <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>
                                        <div className="rotate_img_box text-center">
                                            <div className="rotate_img ">
                                                <img ref={this.imgRef} style={{ transform: `rotate(${this.state.rotation}deg)`, maxHeight: "600px", maxWidth: '650px', height: this.state.height, width: this.state.width }} src={baseUrl + "pan/" + this.state.records.panDocImage} />
                                            </div>
                                            <button className="btn btn-primary" onClick={this.rotateleft} type="button"> <i className="fa fa-undo" ></i></button>
                                            <button className="btn btn-primary" onClick={this.rotate} type="button"> <i className="fa fa-repeat" ></i></button>
                                            <button className="btn btn-primary" onClick={this.handleZoomIn} type="button"> <i className="fa fa-plus" ></i></button>
                                            <button className="btn btn-primary" onClick={this.handleZoomOut} type="button"> <i className="fa fa-refresh" ></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>
        )
    }
}

VerifyTaxImage.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyTaxImage));
