import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPair } from "../../actions/pairActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

class PairAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            box1: "",
            box2: "USDT",
            errors: {},
            cryptoList:[],
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.cryptoList) {
            this.setState({
               cryptoList: nextProps.cryptoList
            })
        }
        if (nextProps.errors !== "" && nextProps.errors !== undefined) {
            console.log("nextProps.errors======",nextProps.errors);
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.pairdata !== undefined
            && nextProps.pairdata.pair !== undefined
            && nextProps.pairdata.pair.data !== undefined
            && nextProps.pairdata.pair.data.message !== undefined && nextProps.pairdata.pair.data.status) {               
            this.setState({
                errors:{},
                box1: "",
                box2:""
            }); 
        }       
    }

    onChange = e => {
        // this.setState({errors:{}});
        this.setState({ [e.target.id]: e.target.value });
    };

    onPairAdd = e => {
        e.preventDefault();
        // alert(this.state.box1);
        //  alert(this.state.box2);
        const newPair = {
            box1: this.state.box1,
            box2: this.state.box2
        };
        this.props.addPair(newPair, this.props.history);
        };
        showHtml = () => {
            const html = []
             this.state.cryptoList.map(function (value, i) {
                  html.push(
                         <>  
                            <option value={value._id}>{value.name}</option>
                         </>
                     );
                 
         })
         return html;
         }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Pair</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onPairAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box1">First Box</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select  className="form-control" id="box1" value= {this.state.box1}  error={errors.box1} onChange={this.onChange}>
                                            <option>Please select crypto currency</option>
                                            {this.showHtml()}
                                            </select>
                                          <span className="text-danger">{errors.box1}</span>
                                        </div>
                                        </div>
                               
                                 <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="box2">Second Box</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                              type="text"
                                              id="box2"
                                              readOnly = {true} 
                                              onChange={this.onChange}
                                              value= {this.state.box2}
                                              className="form-control"
                                                />
                                            <span className="text-danger">{errors.box2}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

PairAddModal.propTypes = {
    addPair: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    pairdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    pairdata: state.pairdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addPair }
)(withRouter(PairAddModal));
