import axios from "axios";
import {
    GET_ERRORS,
    USER_ADD,
    USER_UPDATE
} from "./types";
import * as myConstList from '../BaseUrl';
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
export const addCrypto = (CryptoData, history) => dispatch => {
    axios
        .post(baseUrl+"api/crypto-add", CryptoData,configToken)
        .then(res =>
            dispatch({
                type: 'CRYPTO_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateCrypto = (CryptoData) => dispatch => {
    axios
        .post(baseUrl+"api/crypto-update", CryptoData,configToken)
        .then(res =>
            dispatch({
                type: 'CRYPTO_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
