import axios from "axios";
import socketIOClient from "socket.io-client";
import {
    GET_ERRORS,
    
} from "./types";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
export const addContest = (userData,config) => dispatch => {

    axios
        .post(baseUrl+"api/contest-add",userData,config)
        .then(res =>
            dispatch({
                type: 'CONTEST_ADD',
                payload: res,
            })
            
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateContest = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/contest-update", userData,configToken)
        .then(res =>
            dispatch({
                type: 'CONTEST_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
