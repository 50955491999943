import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCountry } from "../../actions/countryActions";
import { withRouter } from "react-router-dom";
import {toast} from 'react-hot-toast';
import $ from 'jquery';
import { InrStatus } from "../../actions/transactionAction";
import Moment from 'moment';
import axios from "axios";
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }

class WithdrawalINRReject extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            reject:this.props.record.reject,
            status:this.props.record.status,
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                reject: nextProps.record.reject,
                status: nextProps.record.status
                
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
       
        if (nextProps.transactiondata !== undefined
            && nextProps.transactiondata.transaction !== undefined
            && nextProps.transactiondata.transaction.data !== undefined
            && nextProps.transactiondata.transaction.data.message !== undefined
            && nextProps.transactiondata.transaction.data.status) {
              
                $('#update-user-modal-reject').modal('hide');
            // toast.success(nextProps.transactiondata.transaction.data.message, toastStyles);
            this.setState({
                errors: "",messageR:''
            });
        }
    }

    onChange = e => {
        if (e.target.id === 'user-update-reject') {
            this.setState({ reject: e.target.value });
        }
       
    };

    onCountryUpdate = e => {
       
        e.preventDefault();
      
        e.preventDefault();
        
        const newUser = {
           _id:this.state.id,
            type: "2",
            reject:this.state.reject,
           

        };
        this.props.InrStatus(newUser);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal-reject">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Withdrawal Request</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCountryUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Reject Reason</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.reject}
                                                id="user-update-reject"
                                                type="text"
                                                error={errors.reject}
                                                className={classnames("form-control", {
                                                    invalid: errors.reject
                                                })}/>
                                            <span className="text-danger">{errors.reject}</span>
                                        </div>
                                    </div>
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {this.state.status=="pending" &&
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

WithdrawalINRReject.propTypes = {
    transactiondata: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata:state.transactiondata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    {InrStatus}
)(withRouter(WithdrawalINRReject));
