import React, { Component, Fragment } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Moment from 'moment';
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class Leaderboard extends Component {

    constructor(props) {
        super(props);
        this.setState({record:[]});
        this.state = {
            records: []
        };
        // this.getData = this.getData.bind(this);
    }
  

  

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {          
            console.log("liveRecord===",nextProps.record); 
            this.setState({
                record: nextProps.record,
                bonus:nextProps.bonus
            });       
        }
    }
    componentDidMount() {
        // this.getData()
    };
    editRecord(record) {
        this.setState({ currentRecord: record});
    }

   

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    showChatHtml = () => {       
        console.log("this.state.leaderboard",this.state.record);
        const html = [];	
        var rank = 0;
	    var profitArr=[];	
        if(this.state.record != undefined && this.state.record.length>0){
            this.state.record.map(function (value, i) {            
                if(value.bid_count>=5) {
                    var portfolioVal = value.portfoliovalue;                
                    if(profitArr.includes(portfolioVal)===false){
                        rank++;
                    }
                    profitArr.push(portfolioVal);
                    html.push(                       
                            <tr>
                            <td><span className="pr-2">{rank}</span></td>
                            <td><span className="pr-2">{value.name}</span></td>
                            <td><span className="pr-2">{value.portfoliovalue}</span></td>
                            <td><span className="pr-2">{(Number(value.per).toFixed(3))+"%"}</span></td>
                            <td><span className="pr-2">{value.winner_amount}</span></td>
                            </tr>
                    );
                    //console.log("profitArr.includes(portfolioVal)",profitArr.includes(portfolioVal));                    
                }
            })
        }		
        return html;
    }
    render() {    
        return (
            <div className="table-responsive">
                <div className="table-responsive winners_table">
                    <table className="table  mb-0">            
                        <tr>
                            <th className="th">Rank</th>
                            <th className="th">Username</th>
                            <th className="th">Portfolio Value</th>
                            <th className="th">Gain/Loss %</th>
                            <th className="th">Winning Amount</th>
                        </tr>
                        <tbody>
                            {this.showChatHtml()}
                        </tbody>
                    </table>
                </div>
		    </div>
        );
    }

}

Leaderboard.propTypes = {
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata:state.transactiondata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Leaderboard);
