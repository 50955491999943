import axios from "axios";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };

export const addPair = (pairData, history) => dispatch => {
    axios
        .post(baseUrl+"api/pair-add", pairData,configToken)
        .then(res =>
            dispatch({
                type: 'PAIR_ADD',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
export const updatePair = (pairData) => dispatch => {
    axios
        .post(baseUrl+"api/pair-update", pairData,configToken)
        .then(res =>
            dispatch({
                type: 'PAIR_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};
