import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import SuportUpdateModal from "../partials/SuportUpdateModal";
import {toast,Toaster } from 'react-hot-toast';
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
const toastStyles = {
    style: {
        borderRadius: '10px',
        background: '#fff',
        color: '#000',
    },
}
class Suport extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "mobile",
                text: "Mobile No",
                className: "subject",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {(record.user_id!="" && record.user_id!=undefined)?record.user_id.mobile:""}
                        </Fragment>
                    );
                }

            },
            {
                key: "name",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
                cell: record => {
                    return (                    
                        <Fragment>
                          {(record.user_id!="" && record.user_id!=undefined)?record.user_id.name:""}
                        </Fragment>
                    );
                }

            },
            {
                key: "subject",
                text: "Subject",
                className: "subject",
                align: "left",
                sortable: true,
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <Link to={"/chat-room/"+ record.id}  className="btn btn-primary btn-sm">Chat</Link>
                           
                            {/* <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)}>
                                <i className="fa fa-trash"></i>
                            </button> */}
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Suport List",
            no_data_text: 'No user found!',
            button: {
                
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            recordsResolve: [],
            recordsPending: [],
        };

        this.state = {
            currentRecord: {
                id: '',
                subject: '',
                date: '',
                status: '',
              
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.suportManagement !==undefined && this.props.auth.user.suportManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.suportManagement !==undefined && nextProps.auth.user.suportManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    }

    getData() {
        axios
            .post(baseUrl+"api/userAllHelp-data",{status:'Resolved'},configToken)
            .then(res => {
                this.setState({ recordsResolve: res.data})
            })
            .catch()
        axios
            .post(baseUrl+"api/userAllHelp-data",{status:'Pending'},configToken)
            .then(res => {
                this.setState({ recordsPending: res.data})
            })
            .catch()
    }
    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/userAllHelp-delete", {_id: record._id},configToken)
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles)
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                    
                        <div className="container-fluid">
                            <div className="container-fluid">
                                <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>                          
                                <h4 className="mt-2 man_hadding">Users Query List</h4>
                                <div className="man_white_box padding_d ">
                                    <ul className="nav nav_left_tab">
                                    <li className="nav-item">
                                        <a className="active show" data-toggle="tab" href="#pending">
                                        <i className="la la-mobile-phone l_i"></i>
                                            Pending
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="" data-toggle="tab" href="#completed">
                                        <i className="la la-user l_i"></i>
                                        Resolved
                                        </a>
                                    </li>
                                    </ul>
                                    <hr />
                                    <div className="white_box">
                                        <div className="tab-content">
                                            <div id="pending" className="tab-pane active show">
                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.recordsPending}
                                                columns={this.columns}
                                                onPageChange={this.pageChange.bind(this)}
                                            />
                                            </div>
                                            <div id="completed" className="tab-pane">
                                            <ReactDatatable
                                                config={this.config}
                                                records={this.state.recordsResolve}
                                                columns={this.columns}
                                                onPageChange={this.pageChange.bind(this)}
                                            />
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </div>

                            {/* <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            
                            <h1 className="mt-2 text-primary">Users Query List</h1>
							 <div className="man_white_box ">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                />
                            </div> */}
                        </div>
                    </div>
                    <Toaster/>
                </div>
            </div>
        );
    }

}

Suport.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(Suport);
