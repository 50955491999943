import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import HelpQueryModal from "../partials/HelpQueryModal";
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class ChatRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            userrecords:{
                id:'',
                subject:'',
                description:'',
                issueImage:"",                
                date:'',
                status:''
            },
            tichekId:this.props.match.params.id
        };
       
    
        
        this.getData = this.getData.bind(this);
    }



    getData() {
        const { user } = this.props.auth;
        axios
            .post(baseUrl+"api/chat-room-data", { _id:this.state.tichekId,user_id:user.id })
            .then(res => {
                this.setState({records: res.data});
              
            })
            .catch()

            axios
            .post(baseUrl+"api/chat-room-tichekdata", { _id:this.state.tichekId,user_id:user.id })
            .then(res => {
                this.setState({userrecords: {
                    id:res.data._id,
                    subject:res.data.subject,
                    description:res.data.description,
                    issueImage:res.data.issueImage,
                    date:res.data.date,
                    status:res.data.status
                }
                
                });
              
            })
            .catch()
    }
    componentDidMount() {
        if (this.props.auth.user.suportManagement !==undefined && this.props.auth.user.suportManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
     
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.suportManagement !==undefined && nextProps.auth.user.suportManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined) {
         
            toast(nextProps.helpdata.help.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
         
        }
        
        this.getData();
        
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>
               <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h1 className="mt-2 text-primary mb-1">Reply</h1>
                            <HelpQueryModal record={this.state.records} userrecords={this.state.userrecords}/>
                        </div>
                    </div>
                 <ToastContainer/>
                </div>
            </div>
            </>

        );
    }

}

ChatRoom.propTypes = {
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    records: state.records
});

export default connect(
    mapStateToProps
)(ChatRoom);
