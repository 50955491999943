import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addPermotion } from "../../actions/updateHelp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as myConstList from '../../BaseUrl';
import {toast,Toaster } from 'react-hot-toast';
import { withRouter } from "react-router-dom";
const baseUrl = myConstList.baseUrl;
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class Permotion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            description: '',
            fieldEmpty:'',
            userrecords:{
                id:'',
            },
        };
       
        this.getData = this.getData.bind(this);
    }


    
    getData() {
        const { user } = this.props.auth;
        axios
        .post(baseUrl+"api/getPermotion",{},config)
        .then(res => {
            this.setState({ records: res.data.data})
        })
        .catch()
            .catch()
    }
    componentDidMount() {
        if (this.props.auth.user.bunnerManagement !==undefined && this.props.auth.user.bunnerManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
     
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.bunnerManagement !==undefined && nextProps.auth.user.bunnerManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined) {
         
            toast.success(nextProps.helpdata.help.data.message,toastStyles);
         
        }
        
        this.getData();
        
    }   
    handleCommentChange = (event, editor) => {
        var data = editor.getData();
        this.setState({fieldEmpty:''});
        if (data) {
            console.log("data===",data);
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };
    onUserUpdate = e => {
        
        e.preventDefault();

        const config1 = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
      
        const { user } = this.props.auth;
        const formData = new FormData();   
        if(this.state.description === ""){
         this.setState({fieldEmpty:"This filed is required"});
            // toast.error("This filed is required",toastStyles);
            return false;
        }   
        formData.append('description', this.state.description);
        this.props.addPermotion(formData, config1);    
    };
    
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>
               <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h1 className="mt-2 text-primary mb-1">Promotion</h1>
                            <div className="">
                                <div className="w_chating_box">
                                    <h5 className="mb-3">Select Promotion</h5>
                                        <form noValidate onSubmit={this.onUserUpdate} id="update-user">                     
                                        <div className="foot bunner_list">
                                            <CKEditor editor={ClassicEditor}
                                                value={this.state.description}
                                                onChange={this.handleCommentChange}
                                                placeholder="Enter Description"
                                                className="form-control form_control_textarea"
                                                data={this.state.description} />
                                                <span className="text-danger">{this.state.fieldEmpty}</span>
                                        </div>
                                                        
                                                <div className="text-right mt-3">
                                                <button className="btn btn-primary" type="submit">update</button>
                                            </div>
                                        </form>
                                </div>
                            </div>
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                        </div>
                    </div>
                 <Toaster/>
                </div>
            </div>
            </>
        );
    }

}

Permotion.propTypes = {
    addPermotion: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    records: state.records
});

export default connect(
    mapStateToProps,
    { addPermotion }
    )(withRouter(Permotion));
