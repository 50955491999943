import axios from "axios";
import * as myConstList from '../BaseUrl';
const baseUrl = myConstList.baseUrl;
export const updateAppSetting = (userData) => dispatch => {
    console.log("dispathData=",userData)
    axios
        .post(baseUrl+"api/appsetting-update", userData)
        .then(res =>
            dispatch({
                type: 'APP_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: 'GET_ERRORS',
            payload: err.response.data
        })
    );
};