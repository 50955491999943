import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonBi from "./RejectButtonBi";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { Link } from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class VerifyBackImage extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: "",
            bImage: '',
            bImage_status: '',
            reject: '',
            rotation: 0,
            errors: {},
        };
        this.rotate = this.rotate.bind(this);
        this.rotateleft = this.rotateleft.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this)
	    this.handleZoomOut = this.handleZoomOut.bind(this)
        this.imgRef = React.createRef()
    }
    componentDidMount(){
        // Saving initial dimension of image as class properties
        this.initialHeight = "300";
        this.initialWidth = "300";
    }
    componentWillReceiveProps(nextProps) {
        console.log('nextProps');
        console.log(nextProps.record);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                bImage: nextProps.record.bImage,
                bImage_status: nextProps.record.bImage_status,
                reject: nextProps.record.bi_rejectResion,
                records: nextProps.record,

            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message2 !== undefined
        //     && nextProps.auth.user.data.success) {
        //     toast(nextProps.auth.user.data.message2, {
        //         position: toast.POSITION.TOP_CENTER
        //     });

        // }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUpdate = e => {

        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            type: "bi"
        };
        this.props.verifyStatus(newUser);
    };
    rotate(){
        let newRotation = this.state.rotation + 90;
        if(newRotation >= 360){
            newRotation =- 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }
    rotateleft(){
       
        let newRotation = this.state.rotation - 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        this.setState({
          rotation: newRotation,
        })
    }
    
    handleZoomIn(){
        // Fetching current height and width
        const height = this.imgRef.current.clientHeight
        const width = this.imgRef.current.clientWidth
        
        // Increase dimension(Zooming)
        this.setState({
        height : height + 10,
        width : width + 10,
        })
    }
    handleZoomOut(){
        // Assigning original height and width
        this.setState({
        height : 300,
        width : 500,
        })
    }

    render() {

        const { errors } = this.state;
        const { user } = this.state;
        return (
            <>
                {this.state.bImage_status !== undefined &&
                    <>
                        {/* <form noValidate onSubmit={this.onUpdate} id="update-user">
                            <p><b>Back Image :-</b></p>
                            <Link to={baseUrl+"staticprofile/" + this.state.bImage} target="_blank"><img width="300" src={baseUrl+"staticprofile/" + this.state.bImage} className="img-thumbnail" alt="Cinque Terre"></img></Link>
                            {this.props.match.params.type==2 &&
                            <div style={{ display: (this.state.bImage_status !== "verify" && this.state.bImage_status !== "reject" ? 'block' : 'none') }}>
                                <button type="submit">Verify</button>
                            </div>
                            }
                        </form> */}
                        {/* {this.props.match.params.type==2 &&
                        <RejectButtonBi record={this.state.records} docStatus={this.state.bImage_status} rejectStatus={this.state.reject} />
                        } */}

                        <div className="col-md-6">
                        
                            <div className="form-group">
                                <label class="fwb" for="name">
                                {this.state.records.docType ==="id" &&
                                    <span>Aadhaar Back :</span>
                                }
                                {this.state.records.docType ==="li" &&
                                    <span>Driving Back :</span>
                                }
                                {this.state.records.docType ==="ps" &&
                                    <span>Passport Back :</span>
                                }

                                </label>
                                {/* <a href={baseUrl+"staticprofile/"+this.state.records.bImage} target="_blank">
                                <img src={baseUrl+"staticprofile/"+this.state.records.bImage} class="img-thumbnail" alt="Cinque Terre"/>
                                </a> */}

                                <a href="javascript:void(0);" className='popup-img-outer' data-toggle="modal" data-target="#Imagemodal1"  target="_blank" style={{cursor:"pointer"}} >
                                    <img src={baseUrl+"profile/"+this.state.records.bImage} class="img-thumbnail" alt="Cinque Terre"/></a>

                            </div>
                            <div className="form-group">
                                <form noValidate onSubmit={this.onUpdate} id="update-user">
                                    {this.props.match.params.type==2 &&
                                    <div style={{ display: (this.state.bImage_status !== "verify" && this.state.bImage_status !== "reject" ? 'inline-block' : 'none') }}>
                                        <button type="submit" class="btn btn-primary mr-3">Approve</button>
                                    </div>
                                    }
                                    {this.props.match.params.type==2 &&
                                    <RejectButtonBi record={this.state.records} docStatus={this.state.bImage_status} rejectStatus={this.state.reject} />
                                    }
                                </form>
                            </div>
                            
                        </div> 
                        <div className="modal fade" id="Imagemodal1">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-body login_modal"> <button type="button" className="close" data-dismiss="modal"><i className="las la-times"></i></button>
                                    <div className="rotate_img_box text-center">
                                            <div className="rotate_img ">
                                                <img ref={this.imgRef} style={{transform: `rotate(${this.state.rotation}deg)`,maxHeight:"600px",maxWidth:'650px',height : this.state.height, width: this.state.width}} src={baseUrl+"profile/"+this.state.records.bImage}/>
                                            </div>
                                            <button className="btn btn-primary" onClick={this.rotateleft} type="button"> <i className="fa fa-undo" ></i></button>
										    <button className="btn btn-primary" onClick={this.rotate} type="button"> <i className="fa fa-repeat" ></i></button>

                                            <button className="btn btn-primary" onClick={this.handleZoomIn} type="button"> <i className="fa fa-plus" ></i></button>
										    <button className="btn btn-primary" onClick={this.handleZoomOut} type="button"> <i className="fa fa-refresh" ></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </>

        )
    }
}

VerifyBackImage.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyBackImage));
