import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import RejectButtonV from "./RejectButtonV";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class VerifyVideoImage extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: "",
            video: "",
            videoStatus: '',
            reject: '',
            showResults : false,
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        
        
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                videoStatus: nextProps.record.videoStatus,
                reject: nextProps.record.v_rejectResion,
                records: nextProps.record,

            });
            if(nextProps.record.video !== undefined){
                this.setState({
                    video: nextProps.record.video,
                });
            }
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message6 !== undefined
        //     && nextProps.auth.user.data.success) {

        //     toast(nextProps.auth.user.data.message6, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUpdate = e => {

        e.preventDefault();

        const newUser = {
            _id: this.props.match.params.id,
            type: 'v'


        };
        this.props.verifyStatus(newUser);
    };



    render() {

        const { errors } = this.state;
        const { user } = this.state;
        return (            
            <>
                {this.state.videoStatus !== undefined &&
                    <>
                        <form noValidate onSubmit={this.onUpdate} id="update-user">
                            <p><b>Video Proof:</b> {this.state.videoStatus}</p>                            
                            {this.state.video !== undefined && this.state.video !== '' &&
                            <>
                            <br />
                            <video width="320" height="240" controls>
                            <source src={baseUrl+"/video/" + (this.state.video!="" && this.state.video!=undefined? this.state.video:"")} type="video/mp4" />
                            </video>
                            </>
                            }
                            {this.props.match.params.type==2 &&
                 
                            <div style={{ display: (this.state.videoStatus != "verify" && this.state.videoStatus != "reject" ? 'block' : 'none') }}>
                                <button type="submit">Verify</button>
                            </div>
                            }
                            </form>
                            {this.props.match.params.type==2 &&
                            <RejectButtonV record={this.state.records} docStatus={this.state.videoStatus} rejectStatus={this.state.reject}/>
                            }
                       
                    </>
                }
            </>
        )
    }
}

VerifyVideoImage.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyVideoImage));
