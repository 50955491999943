import { combineReducers } from "redux";
import authReducer from "./authReducers";
import contestReducers from "./contestReducers";
import countryReducers from "./countryReducers";
import cryptoReducers from "./cryptoReducers";
import helpReducers from "./helpReducers";
import pairReducers from "./pairReducers";
import stateReducers from "./stateReducers";
import roleReducers from "./roleReducers";
import transactionReducers from "./transactionReducers";
import userReducers from "./userReducers";
import appSettingReducers from "./appSettingReducers";

import errorReducer from "./errorReducers";
export default combineReducers({
    auth: authReducer,
    contestdata: contestReducers,
    countrydata: countryReducers,
    helpdata: helpReducers,
    pairdata: pairReducers,
    statedata: stateReducers,
    transactiondata: transactionReducers,
    roledata: roleReducers,
    cryptodata: cryptoReducers,
    userdata: userReducers,
    errors: errorReducer,
    appSettingData: appSettingReducers
});