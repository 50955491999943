import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import AddContest from "../partials/AddContest";
import { toast, ToastContainer } from "react-toastify";
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class BuyOrder extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "Id",
                className: "id",
                align: "left",
                sortable: true,
            },
            {
                key: "user_id",
                text: "User Name",
                className: "user_id",
                align: "left",
                sortable: true
            },
            {
                key: "price",
                text: "Price",
                className: "price",
                align: "left",
                sortable: true,
            },
            {
                key: "amount",
                text: "Amount",
                className: "amount",
                align: "left",
                sortable: true,
            },
            {
                key: "stopLoss",
                text: "Stop Loss",
                className: "stopLoss",
                align: "left",
                sortable: true
            },
            {
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true
            },
            {
                key: "date",
                text: "date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },

        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Buy Order List",
            no_data_text: 'No user found!',
            button: {
              
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };
        this.getData = this.getData.bind(this);
    }
    componentDidMount() {
        if (this.props.auth.user.buyOrder !==undefined && this.props.auth.user.buyOrder !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.buyOrder !==undefined && nextProps.auth.user.buyOrder !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
    }
    getData() {
        axios
            .post(baseUrl+"api/order-data", { search: "Buy" },configToken)
            .then(res => {
                this.setState({ records: res.data })
            })
            .catch()
    }
    editRecord(record) {
        this.setState({ currentRecord: record });
    }
    deleteRecord(record) {
        axios
            .post(baseUrl+"api/order-delete", { _id: record._id },configToken)
            .then(res => {
                if (res.status === 200) {
                    toast(res.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                    })
                }
            })
            .catch();
        this.getData();
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Buy Order</h1>
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
        );
    }

}

BuyOrder.propTypes = {
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    records: state.records
});

export default connect(
    mapStateToProps
)(BuyOrder);
