import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { InrStatus } from "../../actions/transactionAction";
import { withRouter } from "react-router-dom";
import {toast} from 'react-hot-toast';
import $ from 'jquery';
import Moment from 'moment';
import axios from "axios";
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class WithdrawalINRApprove extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
            approve: this.props.record.approve,
            amount: this.props.record.amount,
            status:this.props.record.status,
            errors: {},
			btnDisabled:false
        };
        // var submitButton = $("#sumitButton");
        // submitButton.click();
        const newUser = {
            _id:this.state.id,
            type: "1",
            // approve:this.state.approve,
            amount:this.state.amount

        };
        this.props.InrStatus(newUser);
    }

    componentWillReceiveProps(nextProps) {
		
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                // approve: nextProps.record.approve,
                amount: nextProps.record.amount,
                status: nextProps.record.status,
                
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.transactiondata !== undefined
            && nextProps.transactiondata.transaction !== undefined
            && nextProps.transactiondata.transaction.data !== undefined
            && nextProps.transactiondata.transaction.data.message !== undefined
            && nextProps.transactiondata.transaction.data.status) {
            $('#update-user-modal-approve').modal('hide');
            toast.success(nextProps.transactiondata.transaction.data.message, toastStyles);
            this.setState({
                errors: "",messageA:'',btnDisabled:false
            });
        }
    }

    onChange = e => {
        if (e.target.id === 'user-update-approve') {
            this.setState({ approve: e.target.value });
        }
       
    };
    onUpdateApprove = e => {
        e.preventDefault();
        this.setState({btnDisabled:true})
        const newUser = {
            _id:this.state.id,
            type: "1",
            // approve:this.state.approve,
            amount:this.state.amount

        };
        this.props.InrStatus(newUser);
        $('#update-user-modal-approve').modal('hide');
    };
   

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal-approve">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Withdrawal Request</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body text-center pl-1 pr-1">
                                <form noValidate onSubmit={this.onUpdateApprove} id="update-user-approve">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Approve Text</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.approve}
                                                id="user-update-approve"
                                                type="text"
                                                error={errors.approve}
                                                className={classnames("form-control", {
                                                    invalid: errors.approve
                                                })}/>
                                            <span className="text-danger">{errors.approve}</span>
                                        </div>
                                    </div> */}
                                    <div className=""><h5>Are you sure to approve Withdrowal Request</h5></div>
                               
                                </form>
                            </div>
                            <div className="modal-footer border-0">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                {this.state.status=="pending" &&
                                <button
                                    form="update-user-approve"
                                    type="submit"
									disabled={this.state.btnDisabled}
                                    className="btn btn-primary sumitButton" id="sumitButton">
                                    Approve
                                </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

WithdrawalINRApprove.propTypes = {
    transactiondata: PropTypes.func.isRequired,
    updateCountry: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata:state.transactiondata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
  {InrStatus}
)(withRouter(WithdrawalINRApprove));
