import axios from "axios";
import * as myConstList from '../BaseUrl';
import {
    GET_ERRORS
   
} from "./types";
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
export const addCountry = (countryData, history) => dispatch => {
    axios
        .post(baseUrl+"api/country-add", countryData,config)
        .then(res =>
            dispatch({
                type: 'COUNTRY_ADD',
                payload: res,
            })
        ).catch(err =>
         dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};


export const updateCountry = (countryData) => dispatch => {
    axios
        .post(baseUrl+"api/country-update", countryData,config)
        .then(res =>
            dispatch({
                type: 'COUNTRY_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
