import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Link,withRouter} from "react-router-dom";
import axios from "axios";

class Sidebar extends Component {
     constructor(props) {
    super(props);
    this.state = {
      activeNavLocation: false,
      activeNavCrypto: false,
      activeNavUser: false,
      activeNavKYC: false,
      activeNavContest: false,
      activeNavDeposit: false,
      activeNavGST: false,
    //   pagePath:""
 
    };
  }
  
    onLogoutClick = e => {
        e.preventDefault();
        this.props.logoutUser();
    };
    render() {
	
        const { user } = this.props.auth;
        return (
            <div className="border-right h-100" id="sidebar-wrapper">
                <div className="list-group list-group-flush">
				 <Link to="/dashboard" className={
                    this.props.location.pathname=== "/dashboard"
                        ? "list-group-item list-group-item-action active"
                        : "list-group-item list-group-item-action"
                    }>Dashboard</Link>
                    {user.locationManagment==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#location" href="#s">Location Management <i className="fa fa-angle-down d5"></i></a>                            
                            <div className={this.props.location.pathname == "/country" || this.props.location.pathname == "/state" ? "collapse drop_down show": "collapse drop_down"} id="location">
                                <a href="/country" className={this.props.location.pathname== "/country"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Country  </a>
                                <a href="/state" className={this.props.location.pathname== "/state"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"} >State </a>
                            </div>		
                        </div>
                    }
                    {user.cryptoManagement==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#crypto" href="#s">Crypto Management<i className="fa fa-angle-down d5"></i></a>
                            <div id="crypto" className={this.props.location.pathname == "/crypto_currency" || this.props.location.pathname == "/pair_management" ? "collapse drop_down show": "collapse drop_down"}>
                                <Link to="/crypto_currency" className={this.props.location.pathname== "/crypto_currency"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Crypto Currency  </Link>
                                <Link to="/pair_management" className={this.props.location.pathname== "/pair_management"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Pair Manage </Link>
                            </div>
                        </div>
                    }
                    {user.userManagement==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#user" href="#s">User Management<i className="fa fa-angle-down d5"></i></a>
                            <div id="user" className={this.props.location.pathname == "/users" || this.props.location.pathname == "/block-users" || this.props.location.pathname == "/temp-user" ? "collapse drop_down show": "collapse drop_down"}>
                                <Link to="/users" className={this.props.location.pathname== "/users"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Register User  </Link>
                                <Link to="/block-users" className={this.props.location.pathname== "/block-users"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Block User  </Link>
                                <Link to="/temp-user" className={this.props.location.pathname== "/temp-user"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Non-Register user </Link>
                            </div>
                        </div>
                    }
                    {user.kycManagement==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#kyc" href="#s">KYC Management<i className="fa fa-angle-down d5"></i></a>
                            <div id="kyc" className={this.props.location.pathname == "/Users-pending-record" || this.props.location.pathname == "/users-complite-record" || this.props.location.pathname == "/users-reject-record" ? "collapse drop_down show": "collapse drop_down"}>
                                <Link to="/Users-pending-record" className={this.props.location.pathname== "/Users-pending-record"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>KYC Pending  </Link>
                                
                                <Link to="/users-complite-record" className={this.props.location.pathname== "/users-complite-record"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>KYC Completed </Link>
                                
                                <Link to="/users-reject-record" className={this.props.location.pathname== "/users-reject-record"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>KYC Rejected </Link>
                            </div>
                        </div>
                    }
                    {user.contestManagement==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#contest" href="#s">Contest Management<i className="fa fa-angle-down d5"></i></a>
                            <div id="contest" className={this.props.location.pathname == "/contest_management" || this.props.location.pathname == "/contest-detail" ? "collapse drop_down show": "collapse drop_down"}>
                                    <Link to="/contest_management" className={this.props.location.pathname== "/contest_management"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Contest List  </Link>
                                    <Link to="/contest-detail" className={this.props.location.pathname== "/contest-detail"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Contest Detail </Link>
                            </div>
                        </div>
                    }
                    {user.depositManagement==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#deposit" href="#s">Deposit Management<i className="fa fa-angle-down d5"></i></a>
                            
                            <div id ="deposit" className={this.props.location.pathname == "/deposit-fund" || this.props.location.pathname == "/deposit-inr" ? "collapse drop_down show": "collapse drop_down"} >
                                <Link to="/deposit-fund" className={this.props.location.pathname== "/deposit-fund"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Add Deposit Fund</Link>
                                <Link to="/deposit-inr" className={this.props.location.pathname== "/deposit-inr"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Deposited Amount</Link>                    
                            </div>
                        </div>
                    }
                    {user.gstManagement==true &&
                        <div className="">
                            <a type="button" class="list-group-item list-group-item-action collapsed" data-toggle="collapse" aria-expanded="false" aria-controls="collapseOne" data-target="#gst" href="#s">GST Management<i className="fa fa-angle-down d5"></i></a>
                            
                            <div id="gst" className={this.props.location.pathname == "/gst-invoice" || this.props.location.pathname == "/gst-invoice-user" ? "collapse drop_down show": "collapse drop_down"}>
                                <Link to="/gst-invoice" className={this.props.location.pathname== "/gst-invoice"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>Add GST Invoice</Link>
                                
                                <Link to="/gst-invoice-user" className={this.props.location.pathname== "/gst-invoice-user"?"list-group-item list-group-item-action active":"list-group-item list-group-item-action"}>User Wise GST</Link>
                                
                            </div>
                        </div>
                    }
                    {/* {user.buyOrder==true &&
                    <Link to="/buy-order" className="list-group-item list-group-item-action">Buy Order </Link>
                    } */}
                    {/* {user.sellOrder==true &&
                    <Link to="/sell-order" className="list-group-item list-group-item-action">Sell Order </Link>
                    } */}

                    {user.rewardManagement==true &&
                        <Link to="/reward-history" className={
                        this.props.location.pathname=== "/reward-history"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Reward History</Link>
                    }
                    {user.bankManagement==true &&
					    <Link to="/bank-detail" className={
                        this.props.location.pathname=== "/bank-detail"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Bank Detail</Link>
                    }
                    {user.withdrowalInr==true &&
                        <Link to="/withdrawal-inr" className={
                        this.props.location.pathname=== "/withdrawal-inr"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Withdrawal INR</Link>
                    }      
                    {user.roleManager==true &&
                        <Link to="/role-manager" className={
                        this.props.location.pathname=== "/role-manager"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Role Management</Link>
                    }      
                    {user.suportManagement==true &&
                        <Link to="/suport" className={
                        this.props.location.pathname=== "/suport"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Support Management </Link>
                    }
                    { user.bunnerManagement==true &&
                        <Link to="/bunner-manage" className={
                            this.props.location.pathname=== "/bunner-manage"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Banner Management </Link>
                    }
                    { user.bunnerManagement==true &&
                        <Link to="/notification" className={
                            this.props.location.pathname=== "/notification"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Notification</Link>
                    }
                    { user.bunnerManagement==true &&
                        <Link to="/permotion" className={
                            this.props.location.pathname=== "/permotion"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Promotion Page Setting </Link>
                    }
                    {/* { user.dummyUserManagement==true && */}
                        <Link to="/dummyUser" className={
                            this.props.location.pathname=== "/dummyUser"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Dummy User Management </Link>
                        <Link to="/contestDummyUsers" className={
                            this.props.location.pathname=== "/contestDummyUsers"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Contest Participated Dummy User </Link>
                    {/* } */}
                    { user.appSetting==true &&
                        <Link to="/app-setting" className={
                            this.props.location.pathname=== "/app-setting"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>App Update </Link>
                    }
                    <Link to="/error-log" className={
                        this.props.location.pathname=== "/error-log"? "list-group-item list-group-item-action active": "list-group-item list-group-item-action"}>Error Logs</Link>
                    <button onClick={this.onLogoutClick}>Logout <FontAwesomeIcon icon={faSignOutAlt} /></button>
                </div>
            </div>
        );
    }
}

Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps, { logoutUser })(withRouter(Sidebar));
// export default connect(
//     mapStateToProps,
//     { logoutUser }
// )(Sidebar);
