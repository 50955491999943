import React, { Component, Fragment } from "react";
import Navbar from "../../partials/Navbar";
import Sidebar from "../../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {toast,Toaster } from 'react-hot-toast';
import {Link} from "react-router-dom";
import Moment from 'moment';
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
        borderRadius: '10px',
        background: '#fff',
        color: '#000',
    },
}
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class Deposit extends Component {

    constructor(props) {
        super(props);
        this.setState({record:[]});
        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "contestName",
                text: "Contest Name",
                className: "contestName",
                align: "left",
                sortable: true,
            }, 
            {
                key: "max_particepate",
                text: "Participate",
                className: "max_particepate",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            {record.numOfParticipation}/{record.max_particepate}
                        </Fragment>
                    );
                }
            },   {
                key: "startDate",
                text: "Start Date",
                className: "startDate",
                align: "left",
                sortable: true
            },
            {
                key: "startTime",
                text: "Start Time",
                className: "startTime",
                align: "left",
                sortable: true
            },
              {
                key: "endDate",
                text: "End Date",
                className: "endDate",
                align: "left",
                sortable: true
            },  
            {
                key: "endTime",
                text: "End Time",
                className: "endTime",
                align: "left",
                sortable: true
            },  
            {
                key: "entryFee",
                text: "Entry Fee",
                className: "entryFee",
                align: "left",
                sortable: true
            },


            {
                key: "virtualFund",
                text: "Virtual Fund",
                className: "virtualFund",
                align: "left",
                sortable: true
            },
			{
                key: "publish",
                text: "Publish",
                className: "Publish",
                align: "left",
                sortable: true
            },
            {
                key: "SmsDate",
                text: "SMS Date",
                className: "smsDate",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {record.smsDate !==undefined && Moment(record.smsDate).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "sms",
                text: "SMS Send",
                className: "balance",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.sendSMS(record)}>
                                Send SMS
                            </button>
                        </Fragment>
                    ); 
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                        return (
                            <Fragment>
                                <Link to={"/contest-view-detail/"+ record._id}  className="btn btn-primary btn-sm" alt="view detail" title="View Detail"><i className="fa fa-eye"></i></Link>                                
                            </Fragment>
                        );                    
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            pastrecord: []
        };
        // this.getData = this.getData.bind(this);
    }
  

  

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {     
            this.setState({
                pastrecord: nextProps.record,
                bonus:nextProps.bonus
            });       
        }
    }
    componentDidMount() {
        // this.getData()
    };
    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    sendSMS(record) {
        axios
            .post(baseUrl+"api/smsSendContestStart", {id:record._id,entryFee:record.entryFee},configToken)
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles);
                }
            })
            .catch();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        return (
                <>
                    <ReactDatatable
                        config={this.config}
                        records={this.state.pastrecord}
                        columns={this.columns}
                        onPageChange={this.pageChange.bind(this)}
                    />
                    <Toaster/>
                </>
        );
    }
}

Deposit.propTypes = {
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata:state.transactiondata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Deposit);
