import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { verifyStatus } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import {Link} from "react-router-dom";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class VerifyIntermediateImage extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            id: "",
            bImage: '',
            fImage: '',
            addProofImage: '',
            fImage_status: '',
            bImage_status: '',
            taxStatus: '',
            videoStatus: '',
            addressStatus: '',
            showResults: false,
            showTeaxtArea: true,
            message: '',
            errors: {},
        };

    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps');
        console.log(nextProps.record);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                intermediateStatus: nextProps.record.intermediateStatus,
              
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status
            });
        }

        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message3 !== undefined
            && nextProps.auth.user.data.success) {
            toast(nextProps.auth.user.data.message3, {
                    position: toast.POSITION.TOP_CENTER
                });
            
        }
    }
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };

    onChange = e => {
   

    };
    handleCommentChange = (event, editor) => {

        var data = editor.getData();
        if (data) {
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };

    onUpdate = e => {
    
        e.preventDefault();
        // alert(this.state.type);
        const newUser = {
            _id: this.props.match.params.id,
             type: "in"


        };
        this.props.verifyStatus(newUser);
    };
  



    render() {

        const { errors } = this.state;
        const { user } = this.state;
        return (
         
                   <>

                        <form noValidate onSubmit={this.onUpdate} id="update-user">
                          
                            <p><b>Intermediate Image : {this.state.intermediateStatus}</b></p>
                            <Link to={baseUrl+"/profile/" + this.state.fImage} target="_blank"><img src={baseUrl+"/profile/" + this.state.fImage} className="img-thumbnail" alt="Cinque Terre"></img></Link>
                            <button type="submit">Verify</button>
                            <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal3">Reject</button>
                            <div className="modal fade" id="exampleModal3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div className="modal-dialog" role="document">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">
                                            ...
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                            <button type="button" className="btn btn-primary">Save changes</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <hr />

                   </>   
               



        )
    }
}

VerifyIntermediateImage.propTypes = {
    verifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { verifyStatus }
)(withRouter(VerifyIntermediateImage));
