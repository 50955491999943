import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { BrowserRouter as Router, Route, Switch, Redirect,withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addDepositData } from "../../actions/transactionAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {toast,Toaster} from 'react-hot-toast';
import $ from 'jquery';
import classnames from "classnames";
import * as myConstList from '../../BaseUrl';
import Deposit from "./Deposit";
import Moment from 'moment';
import socketIOClient from "socket.io-client";
const jwtToken = localStorage.getItem("jwtToken");
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class DepositFund extends Component {

    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "tx_id",
                text: "Transaction Id",
                className: "transaction_id",
                align: "left",
                sortable: true,
                
            },
            {
               
                text: "User Name",
                className: "username",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                    
                        <Fragment>
                          {record.user!=""?record.user[0].name:""}
                        </Fragment>
                    );
                }

            },
            {
                key:"amount",
                text:"Amount",
                className: "amount",
                align: "left",
                sortable: true,
            },
            {
                key:"paymentMethod",
                text:"Payment Method",
                className: "amount",
                align: "left",
                sortable: true,
            }, 
            {
                text:"Cash Wallet",
                className: "currentamount",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                    
                        <Fragment>
                          {record.user!=""?record.user[0].balance:""}
                        </Fragment>
                    );
                }
            },
            {
       
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
               
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {

                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.state = {
            records: [],
            id: '',
            username:'',
            paymentmode:'',
            depositType:'',
            bankname:'',
            upiid:'',
            upiid1:'',
            trasanctionid:'',
            amount:'',
            upiDetail:false,
            customUpiDetail:false,
            bankDetail:false,
            selectOptionsBank:"",
            bankList:[],
            upiList:[],
            errors: {}
        };
        this.getData = this.getData.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        // if (this.props.auth.user.roleManager !==undefined && this.props.auth.user.roleManager !==true ) {
        //     this.props.history.push("/dashboard");
        // }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.transactiondata !== undefined
            && nextProps.transactiondata.transaction !== undefined
            && nextProps.transactiondata.transaction.data !== undefined
            && nextProps.transactiondata.transaction.data.message !== undefined
            && nextProps.transactiondata.transaction.data.status) { 
                const userId = nextProps.transactiondata.transaction.data.data
                console.log("userIduserIduserId==",userId);
                const socket = socketIOClient(baseUrl, {query:"jwtToken=" +jwtToken});
                const data= {token:jwtToken,userId:userId};
                socket.emit('userConnected', data);
            toast.success(nextProps.transactiondata.transaction.data.message, toastStyles);
            this.setState({username:'',paymentmode:'',bankname:'',upiid:'',upiid1:'',trasanctionid:'',amount:'',upiDetail:false,bankDetail:false,customUpiDetail:false});
        }
        if(nextProps.transactiondata !== undefined
            && nextProps.transactiondata.transaction !== undefined
            && nextProps.transactiondata.transaction.data !== undefined
            && nextProps.transactiondata.transaction.data.message !== undefined && nextProps.transactiondata.transaction.data.status==false){
            toast.error(nextProps.transactiondata.transaction.data.message, toastStyles);
        }
        this.getData();
    }
    componentDidMount() {
        this.getData()
    };
    getData() {
        axios
            .post(baseUrl+"api/getDepositTransaction",{'transactionType':"manual"},configToken)
            .then(res => {
                console.log("this.state.records",res.data)
                this.setState({ records: res.data.data})
            })
            .catch()
    }
    onChange = e => {
        this.setState({errors: {}});
        if (e.target.name === 'username') {this.setState({username: e.target.value});}
        if (e.target.name === 'paymentmode') {this.setState({paymentmode: e.target.value});}
        if (e.target.name === 'bankname') {this.setState({bankname: e.target.value});}
        if (e.target.name === 'upiid') {this.setState({upiid: e.target.value});}
        if (e.target.name === 'upiid1') {this.setState({upiid1: e.target.value});}
        if (e.target.name === 'trasanctionid') {this.setState({trasanctionid: e.target.value});}
        if (e.target.name === 'amount') {this.setState({amount: e.target.value});}
        if (e.target.name === 'depositType') {this.setState({depositType: e.target.value});}
        
        this.setState({ [e.target.id]: e.target.value });
    };
    
    handleChange1= e => {
        this.setState({depositType: e.target.value});
    }
    handleChange= e => {
        this.setState({paymentmode: e.target.value});
        var userName = this.state.username;
        if(this.state.username ===""){
            this.setState({paymentmode: ""});
            alert("Please enter first username");
            return false;
        }
        if(e.target.value==="NEFT/IMPS"){
            this.setState({
                bankDetail: true,upiDetail: false,customUpiDetail:false
            });
			
		}
        if(e.target.value==="UPI"){
            this.setState({
                bankDetail: false,upiDetail: true,customUpiDetail:false
            });
        } 
        if(e.target.value==="customUpi"){
            this.setState({
                bankDetail: false,upiDetail: false,customUpiDetail:true
            });
        }
        if(this.state.username !==""){
            axios
            .post(baseUrl+"api/geuserbankDetail",{username:this.state.username})
            .then(res => {
                if(res.data.data.length>0){
                    this.setState({bankList:res.data.data}); 
                }               
            })
            .catch();
            axios
            .post(baseUrl+"api/geuserUPI",{username:this.state.username})
            .then(res => {
                // console.log("upiId==",res.data.data);
                if(res.data.data.length>0){
                    this.setState({upiList:res.data.data}); 
                }               
            })
            .catch();
        }
        
    }
    addDeposit = e => {
        e.preventDefault(); 
        if(this.state.username ===""){
			 this.setState({errors:{username:"Please enter user name"}});
			 return false;
        }
        if(this.state.depositType ===""){
            this.setState({errors:{depositType:"Please enter deposit Type"}});
            return false;
       }
        if(this.state.paymentmode ===""){
            this.setState({errors:{paymentmode:"Please enter payment mode"}});
            return false;
        }
        if(this.state.bankname ==="" && this.state.upiid ==="" && this.state.upiid1 ===""){
            toast.error("Please enter UPI/Bank",toastStyles);
            this.setState({errors:{bankname:"Please enter UPI/Bank"}});
            return false;
        }
        if(this.state.trasanctionid ===""){
            this.setState({errors:{bankname:"Please enter UTR number"}});
            return false;
        }
        if(this.state.amount ===""){
            this.setState({errors:{amount:"Please enter amount"}});
            return false;
        }
            const newdata = {
                username: this.state.username,
                paymentmode: this.state.paymentmode,
                bankname: this.state.bankname,
                upiid: this.state.upiid,
                customUPIId: this.state.upiid1,
                trasanctionid: this.state.trasanctionid,
                amount:this.state.amount,
                depositType:this.state.depositType         
           };
           console.log("pp=========",newdata);
        this.props.addDepositData(newdata);
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }
    render() {
        const { errors } = this.state;

        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Add Deposit</h1>
                            <div className="in_page row">
                                <div className="col-md-12" >
                                    <div className=" padding_d man_white_box" >
                                        <ul className="nav nav_left_tab">
                                            <li className="nav-item">
                                                <a className="active show" data-toggle="tab" href="#details">
                                                    <i className="la la-mobile-phone l_i"></i>
                                                    Add Deposit
                                                </a>
                                            </li>
                                        </ul>
                                        <hr />
                                        <form noValidate onSubmit={this.addDeposit} id="add-user">
                                            <div className="white_box">
                                                <div className="tab-content ">
                                                    <div className="tab-pane active show" id="details">
                                                    <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="username">User Name</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.username}
                                                                    error={errors.username}
                                                                    name="username"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.username
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.username}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="depositType">Deposit Type</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <select class="browser-default custom-select" name="depositType"  value={this.state.depositType} onChange={this.handleChange1} >
                                                                <option value="">Please Select</option>
                                                                   <option value="bonus">Bonus</option>
                                                                    <option value="cash">Cash</option>
                                                                </select>
                                                                 <span className="text-danger">{errors.depositType}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="contestStatus">Payment Type</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <select class="browser-default custom-select" name="paymentmode"  value={this.state.paymentmode} onChange={this.handleChange} >
                                                                <option value="">Please Select</option>
                                                                   <option value="UPI">UPI</option>
                                                                    <option value="NEFT/IMPS">NEFT/IMPS</option>
                                                                    <option value="customUpi">Custom UPI</option>
                                                                </select>
                                                                 <span className="text-danger">{errors.paymentmode}</span>
                                                            </div>
                                                        </div>
                                                        {this.state.bankDetail &&
                                                            <div className="row mt-2">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="contestStatus">Bank Detail</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <select class="browser-default custom-select" name="bankname" value={this.state.bankname} onChange={this.onChange}>
                                                                        <option value="">Please select</option>
                                                                    {this.state.bankList.map((e, key) => {  
                                                                        return <option key={key} value={e._id}>{e.bankName}</option>;  
                                                                    })} 
                                                                    </select>
                                                                    <span className="text-danger">{errors.bankname}</span>
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                        {this.state.upiDetail && 
                                                        
                                                            <div className="row mt-2">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="contestStatus">Select UPI</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <select class="browser-default custom-select" name="upiid" value={this.state.upiid} onChange={this.onChange}>
                                                                    <option value="">Please select</option>
                                                                    {this.state.upiList.map((e, key) => {  
                                                                        return <option key={key} value={e._id}>{e.upiId}</option>;  
                                                                    })} 
                                                                    </select>
                                                                    <span className="text-danger">{errors.bankname}</span>
                                                                </div>
                                                            </div>
                                                        
                                                        
                                                        }
                                                        {this.state.customUpiDetail && 
                                                            <div className="row mt-2">
                                                                <div className="col-md-3">
                                                                    <label htmlFor="upiId1">Custome UPI Id</label>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        onChange={this.onChange}
                                                                        value={this.state.upiid1}
                                                                        error={errors.upiid1}
                                                                        name="upiid1"
                                                                        type="text"
                                                                        className={classnames("form-control", {
                                                                            invalid: errors.upiid1
                                                                        })}
                                                                        onKeyPress={(event) => {
                                                                    if (!/[0-9a-zA-Z@]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                    }}
                                                                    />
                                                                    <span className="text-danger">{errors.upiid1}</span>
                                                                </div>
                                                            </div>    
                                                        }                                                    
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="trasanctionid">UTR Number/Trasaction ID </label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.trasanctionid}
                                                                    error={errors.trasanctionid}
                                                                    name="trasanctionid"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.trasanctionid
                                                                    })}
                                                                    onKeyPress={(event) => {
                                                                    if (!/[0-9a-zA-Z]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                    }}
                                                                />
                                                                <span className="text-danger">{errors.trasanctionid}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="amount">Amount</label>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.amount}
                                                                    error={errors.amount}
                                                                    name="amount"
                                                                    type="number"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.amount
                                                                    })}
                                                                    onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                    }}
                                                                />
                                                                <span className="text-danger">{errors.amount}</span>
                                                            </div>
                                                        </div>
                                                        
                                                        <hr />
                                                        <button type="submit" className="btn btn-primary">Save</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            {/* datatable */}
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                          <div className="man_white_box mt-4">
                            <h4 className="mt-2 man_hadding">Deposit INR List</h4>
						
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                       
                          </div>
                            {/* datatable */}
                        </div>
                    </div>
                    <Toaster />
                </div>
            </div>
                );
  }
}

DepositFund.propTypes = {
    addDepositData: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
                    auth: state.auth,
                    transactiondata: state.transactiondata,
                    errors: state.errors
});
export default connect(
                mapStateToProps,
                {addDepositData}
                )(withRouter(DepositFund));
