import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import { forgetPassword } from "../../actions/authActions";
import { toast, ToastContainer } from "react-toastify";
class ForgotPassword extends Component {

    constructor() {
        super();
        this.state = {
            email: "",
            password: "",
            errors: {}
        };
    }

    componentDidMount() {
        if (this.props.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
            this.setState({
                errors: {},
                email:"",
      
            }); 
        }
    };

    componentWillReceiveProps(nextProps) {
   
        if (nextProps.auth.isAuthenticated) {
            this.props.history.push("/dashboard");
        }


 if (nextProps.auth.reg !== undefined
            && nextProps.auth.reg.data !== undefined
            &&nextProps.auth.reg.data.message !== undefined
            && nextProps.auth.reg.data.success) {
                 toast( nextProps.auth.reg.data.message, {
                    position: toast.POSITION.TOP_CENTER
                  });
           
             this.setState({
               
                email:"",
                errors: {}
            });
        }
        // console.log("nextProps.errors==",nextProps.errors);
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors,
            });
        }

   
    }

    onChange = e => {
        this.setState({ errors:{
            
        } });
        this.setState({ [e.target.id]: e.target.value });
    };
      onSubmit = e => {
        e.preventDefault();
        if(this.state.email =="" || typeof this.state.email == 'undefined'){
            this.setState({errors:{email:'Email field is required'}});
            return false;
        }
        const newUser = {
           email: this.state.email           
         };         
       this.props.forgetPassword(newUser, this.props.history);
     };
  

    render() {
      
        const { errors } = this.state;
        return (
            <>
            <div className="login_bg">
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-5 mx-auto ">
                    <div className="text-center">
					 <img src="img/logo-login.png" className="img-fluid" />
					 </div>
                    <div className="mx-auto mt-3 card shadow-lg">
                        <div className="card-body p-3 p-md-4">
                            <h4 className="text-center  mt-2 mb-2 mb-md-4">Forgot Password</h4>
                            <form noValidate onSubmit={this.onSubmit} className="white">
                                <label htmlFor="email">Email</label>
                                <input
                                    onChange={this.onChange}
                                    value={this.state.email}
                                    error={errors.email}
                                    id="email"
                                    type="email"
                                    className={classnames("form-control", {
                                        invalid: errors.email
                                    })}
                                />
                                <span className="text-danger">{errors.email}</span>                         
                                <span className="text-danger">{errors.password}</span>
                                 <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn man_btn  w100 mt-2 px-5">
                                        Login
                                    </button>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            </div>
               <ToastContainer/>
            </>
        );
    }
}

ForgotPassword.propTypes = {
    forgetPassword: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});
export default connect(
    mapStateToProps,
    { forgetPassword }
)(ForgotPassword);
