import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { BrowserRouter as Router, Route, Switch,Redirect,withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { updateContest } from "../../actions/contestActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import {  } from "react-router-dom";
import {toast,Toaster } from 'react-hot-toast';
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from '../../BaseUrl';
import moment from "moment/moment";
const baseUrl = myConstList.baseUrl;
const toastStyles = {
  style: {
    borderRadius: '10px',
    background: '#fff',
    color: '#000',
  },
}
class EditContest extends Component {

  constructor(props) {
    super(props);
    this.state = {
      records: [],
      id: '',
      logo: '',
      hiddenLogo:'',
      contestName: '',
      startDate: '',
      startTime: '',
      endDate: '',
      endTime: '',
      entryFee: '',
      virtualFund: '',
      tstatus: '',
      winningAmount: '',
      description: '',
      invalidImage: '',
      contestStatus: '',
      // heading:"",
      // about:"",
      // notes:"",
      pair_id: [],
      message: '',
      tstatus:'',
      values: [],
      errors: {},
      max_particepate: '',
      tstatusdisable:false,
      tempPair:[],
    };

    this.getData = this.getData.bind(this);
  }
  componentDidMount() {
    if (this.props.auth.user.contest_management !==undefined && this.props.auth.user.contest_management !==true ) {
      this.props.history.push("/dashboard");
    }
    for (var i = 0; i < this.state.winningLevel; i++) {
      this.addClick();
    }
      this.getData();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.user.contest_management !==undefined && nextProps.auth.user.contest_management !==true ) {
      this.props.history.push("/dashboard");
  }
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors
        });
      }
      if (nextProps.contestdata !== undefined
        && nextProps.contestdata.contest !== undefined
        && nextProps.contestdata.contest.data !== undefined
        && nextProps.contestdata.contest.data.message !== undefined
        && nextProps.contestdata.contest.data.success) {
         
        toast.success(nextProps.contestdata.contest.data.message, toastStyles);
       
        this.props.history.push("/contest_management");
  
  
      }
      this.getData();
    }

  getData() {
    axios
    .post(baseUrl+"api/contestOne-data", { _id:this.props.match.params.id})
    .then(res => {
        var myArray = res.data.levels.split(',');
        if(res.data.winningAmount <=0){
          this.setState({tstatusdisable:true});
        }
        this.setState({
            id: res.data._id,
            logo: res.data.contestLogo,
            hiddenLogo: res.data.contestLogo,
            contestName:  res.data.contestName,
            contestStatus: res.data.contestStatus,
            description:  res.data.description,
            endDate:  res.data.endDate,
            endTime:  res.data.endTime,
            startTime:  res.data.startTime,
            entryFee:  res.data.entryFee,
            levels: res.data.levels,
            pair_id: res.data.pair_id,
            startDate: res.data.startDate,
            virtualFund: res.data.virtualFund,
            publish: res.data.publish,
            winningAmount: res.data.winningAmount,
            tstatus: res.data.winningLevel,
            values: myArray,
            // heading: res.data.heading,
            // about: res.data.about,
            // notes:res.data.notes,
            max_particepate:res.data.max_particepate,
        });
     })
    .catch()
    axios
      .post(baseUrl+"api/pair-data")
      .then(res => {
        this.setState({ records: res.data })
      })
      .catch()
  }
 
  onChange = e => {
    if (e.target.id === 'contestName') {
      this.setState({ contestName: e.target.value,
        errors: {
          contestName:''
        } });
      
    }
    if (e.target.id === 'entryFee') {
      this.setState({ entryFee: e.target.value,
        errors: {
          entryFee:''
        } });
    }
    if (e.target.id === 'contestStatus') {
      this.setState({ contestStatus: e.target.value,
        errors: {
          contestStatus:''
        } });
    }
    if (e.target.id === 'virtualFund') {
      this.setState({ virtualFund: e.target.value,
        errors: {
          virtualFund:''
        } });
    }
	if (e.target.id === 'publish') {
      this.setState({ publish: e.target.value,
        errors: {
          publish:''
        } });
    }
    if (e.target.id === 'startTime') {
      this.setState({ startTime: e.target.value,
        errors: {
          startTime:''
        } });
    }
    if (e.target.id === 'endTime') {
      this.setState({ endTime: e.target.value,
        errors: {
          endTime:''
        } });
    }
    if (e.target.id === 'winningAmount') {
      if(e.target.value<0){
        this.setState({ winningAmount: 0,tstatusdisable: true,
          errors: {
            winningAmount:"Please enter valid amount"
          } });
      }else if(e.target.value==0){
        this.setState({ tstatusdisable: true});
      }else{
        this.setState({ tstatusdisable: false,winningAmount: e.target.value,
        errors: {
          winningAmount:''
        } });
      } 
    }
    if (e.target.id === 'tstatus') {
      this.setState({ values: '' });
      for (var i = 0; i < e.target.value; i++) {
        this.addClick();
      }
      this.setState({ tstatus: e.target.value,
        errors: {
          tstatus:''
        } });
    }
    if (e.target.id === 'notes') {
      this.setState({ notes: e.target.value,
        errors: {
          notes:''
        } });
      
    }
    // if (e.target.id === 'about') {
    //   this.setState({ about: e.target.value,
    //     errors: {
    //       notes:''
    //     } });
      
    // }
    // if (e.target.id === 'heading') {
    //   this.setState({ heading: e.target.value,
    //     errors: {
    //       heading:''
    //     } });
      
    // }
    // if (e.target.id === 'max_particepate') {
    //   this.setState({ max_particepate: e.target.value,
    //     errors: {
    //       max_particepate:''
    //     } });
    // }
    if (e.target.name === 'contestLogo') {
      var contestLogo = e.target.files[0];
      var fileSize = e.target.files[0].size;
      if (fileSize > 1000000) {
        this.setState({ invalidImage: "Maximum 1 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
        return false;
      }
      if (!contestLogo.name.match(/\.(jpg|jpeg|png|gif)$/)) {
        this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
        return false;
      }
      this.setState({
        logo: contestLogo,
        errors: {
          logo:''
        }
      });
    }
    this.setState({ [e.target.id]: e.target.value });
  };
  createUI() {
    return this.state.values.map((el, i) =>
      <div key={i} className="row mt-2">
        <div className="col-md-3">
          <label htmlFor="entryFee">Level {i + 1} </label>
        </div>
        <div className="col-md-9">
          <input type="text" id={"l" + (i + 1)} value={el || ''} onChange={this.handleChange.bind(this, i)} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
        </div>

      </div>
    )
  }
  createPairUI() {
    var TempArr = this.state.pair_id;
    var pairID =[];
    var pairID = TempArr.toString().split(',');
    // checked={pairID[i] == el._id?true:""}
    return this.state.records.map((el, i) =>
      <div key={i} className="row mt-2">
        <div className="col-md-3">
          <label htmlFor="entryFee">{el.cryptoCurrency.name}{el.box2}</label>
        </div>
        <div className="col-md-9">
          <input type="checkbox" id={"p" + (i + 1)} value={el._id} onChange={this.handleChangePair.bind(this, i)} checked={pairID.indexOf(el._id) > -1?true:false}/>
        </div>
      </div>
    )
  }
  addClick() {
    this.setState(prevState => ({ values: [...prevState.values, ''] }))
  }

  handleChange(i, event) {
    let values = [...this.state.values];
    values[i] = event.target.value;
    let sum = 0;
    let levelParticipate = 0;
    for (let num of values) {
      if (num) {
        levelParticipate+=1;
        sum += parseFloat(num)
      }
    }
    if (sum > this.state.winningAmount) {
      alert('winning amount low');
    }else if(levelParticipate > this.state.max_particepate){
      alert("Winning level should be not greater then maximum paricipents");
    } else {
      this.setState({ values,
        errors: {
          levels:''
        } });
    }
  }
  handleChangePair(i, event) {
    var neArr = [];
    neArr = this.state.tempPair;
    var TempArr = this.state.pair_id;
    var TempNewArr = TempArr.toString().split(',');
    const index1 = TempNewArr.indexOf(event.target.value);
    if (index1 > -1) {
      TempNewArr.splice(index1, 1);
    }
    if(event.target.checked){
      neArr[i] = event.target.value;  
    }else{
      const index = neArr.indexOf(event.target.value);
      if (index > -1) {
        neArr.splice(index, 1);
      }      
    }   

    var nestr ='';
    var str = '';
    var twoArrMerge = [];
    if(neArr.length>0){
      twoArrMerge = neArr;
    }else if(TempNewArr.length>0){
      twoArrMerge = TempNewArr;
    }
    if(TempNewArr.length>0 && neArr.length>0){
      twoArrMerge = TempNewArr.concat(neArr);
    } 
    
    var newArray = twoArrMerge.filter(function(elem, pos) {
      return twoArrMerge.indexOf(elem) == pos;
    });
    str = newArray.toString();   
    str = str.replace(/^,|,$/g, ''); 
    nestr= str;
    this.setState({ pair_id: nestr});
  }
  handleCommentChange = (event, editor) => {

    var data = editor.getData();
    if (data) {
      this.setState({ description: data,
        errors: {
          description:''
        } });
    }

  };

  onContestAdd = e => {
    e.preventDefault();
    const config = {
      headers: {
        'content-type': 'multipart/form-data'
      }
    }
    var levelInString = this.state.values.toString();
    const formData = new FormData();
    let endDateTemp = this.state.endDate+" "+this.state.endTime;
    let startDateTemp = this.state.startDate+" "+this.state.startTime;
    let dateData = new Date();
    if(startDateTemp<=moment(dateData).format("Y-MM-DD H:mm")){
      toast.error("Please enter correct start date and time",toastStyles);
      return false;
    }
    const date1 = new Date(endDateTemp);
    const date2 = new Date(startDateTemp);
    if (date2 >= date1) {
      toast.error("Start datetime must be earlier than end datetime.",toastStyles);
      return false;
    }
    formData.append('_id', this.props.match.params.id);
    formData.append('logo', this.state.logo);
    formData.append('hiddenLogo', this.state.hiddenLogo);
    formData.append('contestName', this.state.contestName);
    formData.append('startTime', this.state.startTime);
    formData.append('startDate', this.state.startDate);
    formData.append('endDate', this.state.endDate);
    formData.append('endTime', this.state.endTime);
    formData.append('entryFee', this.state.entryFee);
    formData.append('virtualFund', this.state.virtualFund);
    formData.append('publish', this.state.publish);
    formData.append('winningLevel', this.state.tstatus);
    formData.append('winningAmount', this.state.winningAmount);
    formData.append('description', this.state.description);
    formData.append('pair_id', this.state.pair_id);
    formData.append('levels', levelInString);
    formData.append('max_particepate', this.state.max_particepate);
    // formData.append('heading', this.state.heading);
    // formData.append('about', this.state.about);
    // formData.append('notes', this.state.notes);
    formData.append('contestStatus', this.state.contestStatus);
    /* const newdata = {
    _id:this.props.match.params.id,
    logo:this.state.logo,
    hiddenLogo: this.state.hiddenLogo,
    contestName:this.state.contestName,
    startTime:this.state.startTime,
    startDate:this.state.startDate,
    endDate:this.state.endDate,
    endTime:this.state.endTime,
    entryFee:this.state.entryFee,
    virtualFund: this.state.virtualFund,
    publish: this.state.publish,
    winningLevel: this.state.tstatus,
    winningAmount: this.state.winningAmount,
    description: this.state.description,
    pair_id: this.state.pair_id,
    levels: levelInString,
    contestStatus: this.state.contestStatus,
    heading: this.state.heading,
    about: this.state.about,
    notes: this.state.notes,
    max_particepate:this.state.max_particepate,
    } */
    this.props.updateContest(formData,config);
  };

 
   nextButtonClick=()=>{
	  $('.nav-item > .active').parent().next('li').find('a').trigger('click');
  }
  
  
   prevButtonClick=()=>{
	 $('.nav-item > .active').parent().prev('li').find('a').trigger('click');
  }
 
  render() {
    const { errors } = this.state;
    // alert(this.state.pair_id);
    return (
      <div>
        <Navbar />
        <div className="d-flex" id="wrapper">
          <Sidebar />
          <div id="page-content-wrapper">
            <div className="container-fluid">
              <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
              <h1 className="mt-2 text-primary">Edit Contest</h1>
              <div className="in_page row" >
                <br />
                <div className="col-md-12" >
                  <div className=" padding_d man_white_box " >
                    <ul className="nav nav_left_tab">
                      <li className="nav-item">
                        <a className="active show" data-toggle="tab" href="#details">
                          <i className="la la-mobile-phone l_i"></i>
                          Details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#prizeMoney">
                          <i className="la la-user l_i"></i>
                          Prize Money
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#paid">
                          <i className="la la-map-marker l_i"></i>
                          Pair
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="" data-toggle="tab" href="#contestRule">
                          <i className="la la-map-marker l_i"></i>
                          Contest Rule
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <form noValidate onSubmit={this.onContestAdd} id="add-user">
                      <div className="white_box">
                        <div className="tab-content ">

                          <div className="tab-pane active show" id="details">

                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="contestName">Contest Name</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.contestName}
                                  error={errors.contestName}
                                  id="contestName"
                                  type="text"
                                  className={classnames("form-control", {
                                    invalid: errors.contestName
                                  })}
                                />
                                <span className="text-danger">{errors.contestName}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="contestLogo">Logo</label>
                              </div>
                              <div className="col-md-9">
                              <input
                                  value={this.state.hiddenLogo}
                                  id="hiddenLogo"
                                  name="hiddenLogo"
                                  type="hidden"

                                />
                                <input
                                  onChange={this.onChange}
                                  value={this.state.contestLogo}
                                  id="contestLogo"
                                  name="contestLogo"
                                  type="file"

                                />
                            <img style={{ width: '92px',height: '70px'}} src={baseUrl+"/logo/" + this.state.logo} />
                                <span className="text-danger">{this.state.invalidImage}</span>
                                <span className="text-danger">{errors.logo}</span>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="entryFee">Entry Fee</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.entryFee}
                                  id="entryFee"
                                  type="number"
                                  error={errors.entryFee}
                                  className={classnames("form-control", {
                                    invalid: errors.entryFee
                                  })} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                <span className="text-danger">{errors.entryFee}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="max_particepate">Max Particepate</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.max_particepate}
                                  id="max_particepate"
                                  type="number"
                                  error={errors.max_particepate}
                                  className={classnames("form-control", {
                                    invalid: errors.max_particepate
                                  })} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                <span className="text-danger">{errors.max_particepate}</span>
                              </div>
                            </div>
                            {/* <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="contestStatus">Contest Status</label>
                              </div>
                              <div className="col-md-9">
                                <select class="browser-default custom-select" id="contestStatus" value={this.state.contestStatus} onChange={this.onChange} >
                                  <option value="">Please Select</option>
                                  <option value="Upcoming">Upcoming</option>
                                  <option value="Live">Live</option>
                                  <option value="Past">Past</option>


                                </select>
                                <span className="text-danger">{errors.contestStatus}</span>
                              </div>
                            </div> */}
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="virtualFund">Virtual Fund</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.virtualFund}
                                  id="virtualFund"
                                  type="number"
                                  error={errors.virtualFund}
                                  className={classnames("form-control", {
                                    invalid: errors.virtualFund
                                  })} onKeyPress={(event) => {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}}/>
                                <span className="text-danger">{errors.virtualFund}</span>
                              </div>

                            </div>
							<div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="virtualFund">Publish</label>
                              </div>
                              <div className="col-md-9">
                                <select
                                  onChange={this.onChange}
                                  value={this.state.publish}
                                  id="publish"
                                  error={errors.publish}
                                  className={classnames("form-control", {
                                    invalid: errors.publish
                                  })} >
								  <option value="">Select</option>
								  <option value="no">No</option>
								  <option value="yes">Yes</option>
								  </select>
                                <span className="text-danger">{errors.virtualFund}</span>
                              </div>

                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="startDate">Start Time</label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.startDate}
                                  min={new Date().toISOString().split("T")[0]}
                                  error={errors.startDate}
                                  id="startDate"
                                  type="date"
                                  className={classnames("form-control", {
                                    invalid: errors.startDate
                                  })}
                                />
                                <span className="text-danger">{errors.startDate}</span>
                              </div>
                              <div className="col-md-2">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.startTime}
                                  error={errors.startTime}
                                  id="startTime"
                                  type="time"
                                  className={classnames("form-control", {
                                    invalid: errors.startTime
                                  })}
                                />
                                <span className="text-danger">{errors.startTime}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="endTime">End Time</label>
                              </div>
                              <div className="col-md-7">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.endDate}
                                  min={this.state.startDate || new Date().toISOString().split("T")[0]}
                                  id="endDate"
                                  type="date"
                                  className={classnames("form-control", {
                                    invalid: errors.endDate
                                  })}
                                />
                                <span className="text-danger">{errors.endDate}</span>
                              </div>
                              <div className="col-md-2">
                                <input
                                  autoComplete={''}
                                  onChange={this.onChange}
                                  value={this.state.endTime}                                  
                                  id="endTime"
                                  type="time"
                                  className={classnames("form-control", {
                                    invalid: errors.endTime
                                  })}
                                />
                                <span className="text-danger">{errors.endTime}</span>
                              </div>
                            </div>
							<br/>
							<div className="text-right">
							 <a onClick={this.nextButtonClick} className="btn btn-primary">Next</a>
                          </div>
                          </div>
                          <div className="tab-pane" id="prizeMoney">
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="winningAmount">Winning Amount</label>
                              </div>
                              <div className="col-md-9">
                                <input
                                  onChange={this.onChange}
                                  value={this.state.winningAmount}
                                  id="winningAmount"
                                  type="text"
                                  className="form-control"
                                />
                                <span className="text-danger">{errors.winningAmount}</span>
                              </div>
                            </div>
                            <div className="row mt-2">
                              <div className="col-md-3">
                                <label htmlFor="entryFee">Winning Level</label>
                              </div>
                              <div className="col-md-9">
                                <select class="browser-default custom-select" id="tstatus" onChange={this.onChange} value={this.state.tstatus} disabled={this.state.tstatusdisable}>

                                  <option value="1">Level 1</option>
                                  <option value="2">Level 2</option>
                                  <option value="3">Level 3</option>
                                  <option value="4">Level 4</option>
                                  <option value="5">Level 5</option>
                                  <option value="6">Level 6</option>
                                  <option value="7">Level 7</option>
                                  <option value="8">Level 8</option>
                                  <option value="9">Level 9</option>
                                  <option value="10">Level 10</option>
                                  <option value="11">Level 11</option>
                                  <option value="12">Level 12</option>
                                  <option value="13">Level 13</option>
                                  <option value="14">Level 14</option>
                                  <option value="15">Level 15</option>
                                  <option value="16">Level 16</option>
                                  <option value="17">Level 17</option>
                                  <option value="18">Level 18</option>
                                  <option value="19">Level 19</option>
                                  <option value="20">Level 20</option>

                                  <option value="21">Level 21</option>
                                  <option value="22">Level 22</option>
                                  <option value="23">Level 23</option>
                                  <option value="24">Level 24</option>
                                  <option value="25">Level 25</option>
                                  <option value="26">Level 26</option>
                                  <option value="27">Level 27</option>
                                  <option value="28">Level 28</option>
                                  <option value="29">Level 29</option>
                                  <option value="30">Level 30</option>

                                  <option value="31">Level 31</option>
                                  <option value="32">Level 32</option>
                                  <option value="33">Level 33</option>
                                  <option value="34">Level 34</option>
                                  <option value="35">Level 35</option>
                                  <option value="36">Level 36</option>
                                  <option value="37">Level 37</option>
                                  <option value="38">Level 38</option>
                                  <option value="39">Level 39</option>
                                  <option value="40">Level 40</option>

                                  <option value="41">Level 41</option>
                                  <option value="42">Level 42</option>
                                  <option value="43">Level 43</option>
                                  <option value="44">Level 44</option>
                                  <option value="45">Level 45</option>
                                  <option value="46">Level 46</option>
                                  <option value="47">Level 47</option>
                                  <option value="48">Level 48</option>
                                  <option value="49">Level 49</option>
                                  <option value="50">Level 50</option>

                                  <option value="51">Level 51</option>
                                  <option value="52">Level 52</option>
                                  <option value="53">Level 53</option>
                                  <option value="54">Level 54</option>
                                  <option value="55">Level 55</option>
                                  <option value="56">Level 56</option>
                                  <option value="57">Level 57</option>
                                  <option value="58">Level 58</option>
                                  <option value="59">Level 59</option>
                                  <option value="60">Level 60</option>
                                </select>
                                <span className="text-danger">{errors.levels}</span>
                              </div>
                            </div>
                            {this.createUI()}
							<div className="row mt-3">
							<div className="col-6">
							<a onClick={this.prevButtonClick} style={{float:'left'}} className="btn btn-primary">Previous</a>
							</div>
							<div className="col-6 text-right">
							 <a onClick={this.nextButtonClick} style={{float:'right'}} className="btn btn-primary">Next</a>
							</div>
							</div>
							
							
                          </div>
                          <div className="tab-pane" id="paid">
                            {this.createPairUI()}
							<div className="row mt-3">
							<div className="col-6">
							<a onClick={this.prevButtonClick}  className="btn btn-primary">Previous</a>
							</div>
							<div className="col-6 text-right">
							 <a onClick={this.nextButtonClick}  className="btn btn-primary">Next</a>
							</div>
							</div>
							
							
                          </div>
                          <div className="tab-pane " id="contestRule">
                            <div className="row mt-2">
                            {/* <div className="col-md-12">
                                <label htmlFor="contestName">Contest Heading:-</label>
                              </div>
                            <div className="col-md-12">
                              <input
                                  onChange={this.onChange}
                                  value={this.state.heading}
                                  id="heading"
                                  type="text"
                                  className="form-control"
                                />
                                <span className="text-danger">{errors.heading}</span>
                                </div> */}
                                {/* <div className="col-md-12">
                                <label htmlFor="contestName">About :-</label>
                              </div>
                           
                              <div className="col-md-12">
                                <textarea
                                  value={this.state.about}
                                  id="about"
                                  onChange={this.onChange}
                                  className="form-control"
                                  ></textarea>
                                <br />
                                <span className="text-danger">{errors.about}</span>
                              </div> */}
                              {/* <div className="col-md-12">
                                <label htmlFor="contestName">Important Notes :-</label>
                              </div>
                           
                              <div className="col-md-12">
                                <textarea
                                  value={this.state.notes}
                                  onChange={this.onChange}
                                   id="notes"
                                  className="form-control "
                                  data={this.state.notes} ></textarea>
                                <br />
                                <span className="text-danger">{errors.notes}</span>
                              </div> */}
                                <div className="col-md-12">
                                <label htmlFor="contestName">Write Contest Rule :-</label>
                              </div>
                           
                              <div className="col-md-12">
                                <CKEditor editor={ClassicEditor}
                                  value={this.state.description}
                                  onChange={this.handleCommentChange}
                                  placeholder="Enter Description"
                                  className="form-control form_control_textarea"
                                  data={this.state.description} />
                                <br />
                                <span className="text-danger">{errors.description}</span>
                              </div>
                             
                            </div>
							
							<div className="row mt-3">
							<div className="col-6">
							<a onClick={this.prevButtonClick} style={{float:'left'}} className="btn btn-primary">Previous</a>
							</div>
							<div className="col-6 text-right">
							<button type="submit" className="btn btn-dark">Submit</button>
							</div>
							</div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Toaster />
        </div>
      </div>
    );
  }

}

EditContest.propTypes = {
  updateContest: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  contestdata: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  contestdata: state.contestdata,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { updateContest }
)(withRouter(EditContest));
