import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { addNotification } from "../../actions/updateHelp";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import * as myConstList from '../../BaseUrl';
import {toast,Toaster } from 'react-hot-toast';
import { withRouter } from "react-router-dom";
import {Link} from "react-router-dom";
// import Tooltip from 'react-tooltip';
import $ from 'jquery';
import Moment from 'moment';
const baseUrl = myConstList.baseUrl;
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
  const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
class NotificationAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            records: [],
            bunnerRecord:{},
            bunnerId:'',
        };

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "Bunner",
                text: "Notification Image",
                className: "bunner",
                align: "left",
                sortable: true,
				cell: record => {
                    return (
                       <Fragment>
                           <a href={baseUrl+"static/notification/"+record.file}>
                            <img src={baseUrl+"static/notification/"+record.file} className="btn btn_man" width='100'  data-target="blank" onClick={() => this.showRecord(record.file)}/></a>                            
						</Fragment>
                    );
                }
            },
            {   
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                        <Link data-toggle="modal"
                                data-target="#update-user-modal-bankDetail"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.showBannerRecord(record)}
                                style={{marginRight: '5px'}} title="Edit"><i className="fa fa-edit"></i></Link>
                            
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)} title="Delete">
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
            
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Fund Request List",
            no_data_text: 'No user found!',
            button: {
               
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };
        this.getData = this.getData.bind(this);
    }


    
    getData() {
        const { user } = this.props.auth;
        axios
        .post(baseUrl+"api/getNotificationBunner",{},config)
        .then(res => {
            this.setState({ records: res.data.data})
        })
        .catch()
            .catch()
    }
    componentDidMount() {
        if (this.props.auth.user.bunnerManagement !==undefined && this.props.auth.user.bunnerManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData()
     
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.bunnerManagement !==undefined && nextProps.auth.user.bunnerManagement !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined) {
         
            toast.success(nextProps.helpdata.help.data.message,toastStyles);
            $('#update-user-modal-bankDetail').modal('hide');
        }
        
        this.getData();
        
    }
    onChange = e => {
        this.setState({
            message: "",
            invalidImage:"",
            errors: {}
        });
        if (e.target.id === 'tstatus') {
            this.setState({ tstatus: e.target.value });
        }
        if (e.target.name === 'bunner' || e.target.name ==='bunnerupdate') {
            var bunner = e.target.files[0];
            // console.log('bunner');
            // console.log(bunner);
            var fileSize = e.target.files[0].size;
            if (fileSize > 10000000) {
                this.setState({ invalidImage: "Maximum 10 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
           
            if (!bunner.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            this.setState({
                bunner: bunner,
            });
        }
    };
    onUserAdd = e => {
        e.preventDefault();
        if(this.state.bunner === undefined){
            this.setState({invalidImage:"Please attach the banner"});
            return false; 
         }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }        
        const { user } = this.props.auth;
        const formData = new FormData();  
        formData.append('bunner', this.state.bunner);     
        formData.append('bunnerId',this.state.bunnerId);
        this.props.addNotification(formData, config);
    
    };
    deleteRecord(record) {
        axios
            .post(baseUrl+"api/notificationbunner-delete", {_id: record._id},config)
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles);
                   this.getData();
                }
            })
            .catch();
            
    }
    onUserUpdate = e => {
        e.preventDefault();
        if(this.state.bunner === undefined){
            this.setState({invalidImage:"Please attach the banner"});
            return false; 
         }
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        const { user } = this.props.auth;
        const formData = new FormData();    
        formData.append('bunnerId', this.bunnerRecord);
        this.props.addNotification(formData, config);
    
    };
    showBannerRecord(record) {
        this.setState({ bunnerRecord: record,bunnerId:record._id});
    }
    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <>
               <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h1 className="mt-2 text-primary mb-1">Notification Add Image</h1>
                                <div className="w_chating_box">
                                    <h5 className="mb-3">Select Image</h5>
                                        <form noValidate onSubmit={this.onUserAdd} id="update-user">                     
                                            <div className="row">
                                                <div className="form-group col-md-6">
                                                    <div className="add_fileinput form-control mt-0">Add File <input className="profile_input" type="file" name="bunner" onChange={this.onChange} /></div><span className="text-danger">{this.state.invalidImage}</span>
                                                </div>
                                                
                                                        
                                                <div className="col-md-6">
                                                <button className="btn btn-primary" type="submit">Upload</button>
                                            </div>
											 </div>
                                        </form>
                                </div>
                         
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <h1 className="mt-3 text-primary">Notification List</h1>
                                <div className="man_white_box ">
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.records}
                                    columns={this.columns}
                                    onPageChange={this.pageChange.bind(this)}
                                />
                                </div>
                        </div>
                    </div>
                 <Toaster/>
                </div>
                <div className="modal fade" id="update-user-modal-bankDetail">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Notification Update</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <form noValidate onSubmit={this.onUserAdd} >
                            <div className="modal-body">                            
                                {/* <p>Attachment: {this.state.bunnerRecord.file}</p> */}
                                <div className="form-group col-md-6">
                                    <div className="add_fileinput form-control mt-0">Add File <input className="profile_input" type="file" name="bunner" onChange={this.onChange} /></div><span className="text-danger">{this.state.invalidImage}</span>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button><button className="btn btn-primary" type="submit">Upload</button>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Tooltip /> */}
            </>
        );
    }
}

NotificationAdd.propTypes = {
    addNotification: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    records: state.records
});

export default connect(
    mapStateToProps,
    { addNotification }
    )(withRouter(NotificationAdd));
