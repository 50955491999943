import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList } from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import { updateRoleData } from "../../actions/roleActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import $ from 'jquery';
import classnames from "classnames";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class EditRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            records: [],
            id: '',
            name: '',
            email: '',
            mobile: '',
            password:'',
            role: '',
            locationManagment:0,
            cryptoManagement:0,
            userManagement:0,
            kycManagement:0,
            contestManagement:0,
            depositManagement:0,
            gstManagement:0,
            rewardManagement:0,
            bankManagement:0,
            withdrowalInr:0,
            roleManager:0,
            suportManagement:0,
            bunnerManagement:0,
            appSetting:0,
            pair_id: [],
            message: '',
            values: [],
           
            errors: {}
        };


    }

    getData() {
        axios
            .post(baseUrl+"api/profile-data-admin",{_id:this.props.match.params.id})
            .then(res => {
                this.setState({ 
                    records: res.data.data,
                   
                    name: res.data.data.name,
                    email: res.data.data.email,
                    mobile: res.data.data.mobile,
                    role: res.data.data.role,
                    
                    locationManagment: res.data.data.locationManagment,
                    cryptoManagement: res.data.data.cryptoManagement,
                    userManagement: res.data.data.userManagement,
                    kycManagement: res.data.data.kycManagement,
                    contestManagement: res.data.data.contestManagement,
                    depositManagement: res.data.data.depositManagement,
                    gstManagement: res.data.data.gstManagement,
                    rewardManagement: res.data.data.rewardManagement,
                    bankManagement: res.data.data.bankManagement,
                    withdrowalInr: res.data.data.withdrowalInr,
                    roleManager: res.data.data.roleManager,
                    suportManagement: res.data.data.suportManagement,
                    bunnerManagement: res.data.data.bunnerManagement,
                    appSetting: res.data.data.appSetting,
                })
            })
            .catch()
    }
    componentDidMount() {
        if (this.props.auth.user.roleManager !==undefined && this.props.auth.user.roleManager !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.roleManager !==undefined && nextProps.auth.user.roleManager !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.roledata !== undefined
            && nextProps.roledata.role !== undefined
            && nextProps.roledata.role.data !== undefined
            && nextProps.roledata.role.data.message !== undefined
            && nextProps.roledata.role.data.status) {
           this.props.history.push("/role-manager");
        }

        this.getData()
    }
    

  
    onChange = e => {

        this.setState({errors: {}});
        if (e.target.name === 'name') {this.setState({name: e.target.value});}
        if (e.target.name === 'email') {this.setState({email: e.target.value});}
        if (e.target.name === 'mobile') {this.setState({mobile: e.target.value});}
        if (e.target.name === 'role') {this.setState({role: e.target.value});}
        if (e.target.name === 'password') {this.setState({password: e.target.value});}
        if (e.target.name === 'locationManagment') {this.setState({locationManagment: e.target.value});}
        if (e.target.name === 'cryptoManagement') {this.setState({cryptoManagement: e.target.value});}
        if (e.target.name === 'userManagement') {this.setState({userManagement: e.target.value});}
        if (e.target.name === 'kycManagement') {this.setState({kycManagement: e.target.value});}
        if (e.target.name === 'contestManagement') {this.setState({contestManagement: e.target.value});}
        if (e.target.name === 'depositManagement') {this.setState({depositManagement: e.target.value});}
        if (e.target.name === 'gstManagement') {this.setState({gstManagement: e.target.value});}
        if (e.target.name === 'rewardManagement') {this.setState({rewardManagement: e.target.value});}
        if (e.target.name === 'bankManagement') {this.setState({bankManagement: e.target.value});}
        if (e.target.name === 'withdrowalInr') {this.setState({withdrowalInr: e.target.value});}
        if (e.target.name === 'roleManager') {this.setState({roleManager: e.target.value});}
        if (e.target.name === 'suportManagement') {this.setState({suportManagement: e.target.value});}
        if (e.target.name === 'bunnerManagement') {this.setState({bunnerManagement: e.target.value});}
        if (e.target.name === 'appSetting') {this.setState({appSetting: e.target.value});}
        this.setState({ [e.target.id]: e.target.value });
    };

    onRoleUpdate = e => {
        e.preventDefault();
            const newdata = {
                id:this.props.match.params.id,
                name: this.state.name,
                email: this.state.email,
                mobile: this.state.mobile,
                role: this.state.role,

                locationManagment: this.state.locationManagment,
                cryptoManagement: this.state.cryptoManagement,
                userManagement: this.state.userManagement,
                kycManagement: this.state.kycManagement,
                contestManagement: this.state.contestManagement,
                depositManagement: this.state.depositManagement,
                gstManagement: this.state.gstManagement,
                rewardManagement: this.state.rewardManagement,
                bankManagement: this.state.bankManagement,
                withdrowalInr: this.state.withdrowalInr,
                roleManager: this.state.roleManager,
                suportManagement: this.state.suportManagement,
                bunnerManagement: this.state.bunnerManagement,
                appSetting: this.state.appSetting,
                password: this.state.password
           };
        this.props.updateRoleData(newdata);
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <Navbar />
                <div className="d-flex" id="wrapper">
                    <Sidebar />
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList} /></button>
                            <h1 className="mt-2 text-primary">Update Role</h1>
                            <div className="in_page " >
                               
                                    <div className=" padding_d man_white_box " >
                                        <ul className="nav nav_left_tab">
                                            <li className="nav-item">
                                                <a className="active show" data-toggle="tab" href="#details">
                                                    <i className="la la-mobile-phone l_i"></i>
                                                    Update Role 
                                                </a>
                                            </li>
                                        </ul>
                                        <hr />
                                        <form noValidate onSubmit={this.onRoleUpdate} id="add-user">
                                            <div className="white_box">
                                                <div className="tab-content ">
                                                    <div className="tab-pane active show" id="details">
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="contestStatus">Role Type</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <select class="browser-default custom-select" name="role" value={this.state.role} onChange={this.onChange} >
                                                                
                                                                   <option value="Manager">Manager</option>
                                                                    <option value="Employee">Employee</option>
                                                                </select>
                                                                <span className="text-danger">{errors.role}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="name">Full Name</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.name}
                                                                 
                                                                    error={errors.name}
                                                                    name="name"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.name
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.name}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="email">Email</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.email}
                                                                    error={errors.email}
                                                                    name="email"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.email
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.email}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="mobile">Mobile Number</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                    value={this.state.mobile}
                                                                    error={errors.mobile}
                                                                    name="mobile"
                                                                    type="text"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.mobile
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.mobile}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="password">Password</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <input
                                                                    onChange={this.onChange}
                                                                   
                                                                    error={errors.password}
                                                                    name="password"
                                                                    type="password"
                                                                    className={classnames("form-control", {
                                                                        invalid: errors.password
                                                                    })}
                                                                />
                                                                <span className="text-danger">{errors.password}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="countryManagment">Location Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.locationManagment == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="locationManagment"
                                                                            type="radio" className="form-check-input" />Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.locationManagment == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="locationManagment"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="stateManagment">Crypto Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.cryptoManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="cryptoManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.cryptoManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="cryptoManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="contestManagement">User Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.userManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="userManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.userManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="userManagement"
                                                                            type="radio" className="form-check-input"
/>
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="cryptoCurrency">KYC Managemnt</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.kycManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="kycManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.kycManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="kycManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                                <span className="text-danger">{errors.contestStatus}</span>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="pairManagement">Contest Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.contestManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="contestManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.contestManagement ==0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="contestManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="manageUsers">Deposit Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.depositManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="depositManagement"
                                                                            type="radio" className="form-check-input"
                                                                            placeholder="Enter Document No"

                                                                        />
                                                                        <span className="text-danger">{errors.idCard}</span>Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.depositManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="depositManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="verifyComplete">GST Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.gstManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="gstManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.gstManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="gstManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="verifyPending">Reward Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.rewardManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="rewardManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.rewardManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="rewardManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="fundRequest">Bank Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.bankManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="bankManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.bankManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="bankManagement"
                                                                            type="radio" className="form-check-input"


                                                                        />
                                                                        No
                                                                    </label>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="suportManagement">Withdrowal Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.withdrowalInr == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="withdrowalInr"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.withdrowalInr == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="withdrowalInr"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="contestDetail">Role Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.roleManager == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="roleManager"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.roleManager == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="roleManager"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="depositAmount">Support Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.suportManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="suportManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.suportManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="suportManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="depositAmount">Bunner Management</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.bunnerManagement == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="bunnerManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.bunnerManagement == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="bunnerManagement"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row mt-2">
                                                            <div className="col-md-3">
                                                                <label htmlFor="withdrowalInr">App Update Setting</label>
                                                            </div>
                                                            <div className="col-md-9">
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">

                                                                        <input
                                                                            checked={this.state.appSetting == 1}
                                                                            onChange={this.onChange}
                                                                            value="1"
                                                                            name="appSetting"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        Yes
                                                                    </label>
                                                                </div>
                                                                <div className="form-check-inline">
                                                                    <label className="form-check-label">
                                                                        <input
                                                                            checked={this.state.appSetting == 0}
                                                                            onChange={this.onChange}
                                                                            value="0"
                                                                            name="appSetting"
                                                                            type="radio" className="form-check-input"
                                                                        />
                                                                        No
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr />
                                                        <button className="btn btn-primary" type="submit">Save</button>
                                                        <hr />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                </div>
            </div>
                );
  }

}

EditRole.propTypes = {
    updateRoleData: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    roledata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
                auth: state.auth,
                roledata: state.roledata,
                errors: state.errors
});

                export default connect(
                mapStateToProps,
                {updateRoleData}
                )(withRouter(EditRole));
