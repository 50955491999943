import axios from "axios";
import {
    GET_ERRORS,
   
} from "./types";

import * as myConstList from '../BaseUrl';
const config = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
const baseUrl = myConstList.baseUrl;
export const fundverifyStatus = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/fundverifyStatus-update", userData,config)
        .then(res =>
            dispatch({
                type: 'TRANSACTION_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const rejectPaymentButton = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/rejectPayment-update", userData,config)
        .then(res =>
            dispatch({
                type: 'TRANSACTION_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const InrStatus = (userData) => dispatch => {
    axios
        .post(baseUrl+"api/InrStatus-update", userData,config)
        .then(res =>
            dispatch({
                type: 'TRANSACTION_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};
export const addDepositData = (userData) => dispatch => {
    console.log("userdata==",userData);
    axios
        .post(baseUrl+"api/deposit-add", userData,config)
        .then(res =>
            dispatch({
                type: 'TRANSACTION_UPDATE',
                payload: res,
            })
        ).catch(err =>
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    );
};