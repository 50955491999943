import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class SuportUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            name: this.props.record.name,
            email: this.props.record.email,
            password: '',
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                name: nextProps.record.name,
                email: nextProps.record.email,
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.auth !== undefined
            && nextProps.auth.user !== undefined
            && nextProps.auth.user.data !== undefined
            && nextProps.auth.user.data.message !== undefined
            && nextProps.auth.user.data.success) {
            $('#update-user-modal').modal('hide');
            toast(nextProps.auth.user.data.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    onChange = e => {
        if (e.target.id === 'user-update-name') {
            this.setState({ name: e.target.value });
        }
        if (e.target.id === 'user-update-email') {
            this.setState({ email: e.target.value });
        }
        if (e.target.id === 'user-update-password') {
            this.setState({ password: e.target.value });
        }
    };

    onUserUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password
        };
        this.props.updateUser(newUser);
    };

    render() {
        const { errors } = this.state;
        return (
            <div className="container">
            <div className="chat_box">
                <div className="head">
                    <div className="user">
                        <div className="avatar">
                            <img src="https://picsum.photos/g/40/40" />
                        </div>
                        <div className="name">Kai Cheng</div>
                    </div>
                    <ul className="bar_tool">
                        <li><span className="alink"><i className="fas fa-phone"></i></span></li>
                        <li><span className="alink"><i className="fas fa-video"></i></span></li>
                        <li><span className="alink"><i className="fas fa-ellipsis-v"></i></span></li>
                    </ul>
                </div>
                <div className="body">
                    <div className="incoming">
                        <div className="bubble">
                            <p>Hey, Father's Day is coming up..</p>
                        </div>
                        <div className="bubble">
                            <p>What are you getting.. Oh, oops sorry dude.</p>
                        </div>
                    </div>
                    <div className="outgoing">
                        <div className="bubble lower">
                            <p>Nah, it's cool.</p>
                        </div>
                        <div className="bubble">
                            <p>Well you should get your Dad a cologne. Here smell it. Oh wait! ...</p>
                        </div>
                    </div>
                    <div className="typing">
                        <div className="bubble">
                            <div className="ellipsis dot_1"></div>
                            <div className="ellipsis dot_2"></div>
                            <div className="ellipsis dot_3"></div>
                        </div>
                    </div>
                </div>
                <div className="foot">
                    <input type="text" className="msg" placeholder="Type a message..." />
                    <button type="submit"><i className="fas fa-paper-plane"></i></button>
                </div>
            </div>
        </div>
        )
    }
}

SuportUpdateModal.propTypes = {
    updateUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateUser }
)(withRouter(SuportUpdateModal));
