import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { replyForAdmin } from "../../actions/updateHelp";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import Moment from 'moment';
import { Link } from "react-router-dom";
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class HelpQueryModal extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            id: this.props.id,
            description: '',
            message: '',
            tstatus: '',
            issueImage:'',
            username: user.name,
            replyBy: this.props.userrecords.replyTo,
            showResults: false,
            showTeaxtArea: true,
            userUploadImageFront:'',
            descriptionerror:'',
            errors: {},
        };
    
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userrecords.status,
            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.helpdata !== undefined
            && nextProps.helpdata.help !== undefined
            && nextProps.helpdata.help.data !== undefined
            && nextProps.helpdata.help.data.message !== undefined) {
            this.setState({ 
                description:"",
                issueImage:'',
                userUploadImageFront:"",
                errors:{ }
            });
        }
       
    }

    getNodesToRemoveFromElement = (stringContent) => {
        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };
    showChatHtml = () => {
        const html = []
        const classThis = this;
        this.props.record.map(function (value, i) {

            if (value.replyBy == "USER") {
                html.push(
                    <>
                        <div className="incoming">
                            <div className="bubble">
                            
                                <p>{classThis.getNodesToRemoveFromElement(value.description)}</p>
                                {value.issueImage !== undefined && value.issueImage !== '' &&
                                <Link to={baseUrl+"helpIssue/" + value.issueImage} target="_blank"><img src={baseUrl+"helpIssue/"+value.issueImage} /></Link>
}<br/>
                                <span className="time-right">{Moment(value.date).format('lll')}</span>
                            </div>
                            <br />

                        </div>
                    </>
                );
            }
            if (value.replyBy == "ADMIN") {
                html.push(
                    <>
                        <div className="outgoing" style={{ textAlign: 'right' }}>
                            <div className="bubble lower">
                                <p>{classThis.getNodesToRemoveFromElement(value.description)}</p>
                                {value.issueImage !== undefined && value.issueImage !== '' &&
                                <a href={baseUrl+"helpIssue/" + value.issueImage} target="_blank"><img src={baseUrl+"helpIssue/"+value.issueImage} /></a>
}<br/>
                                <span className="time-right">{Moment(value.date).format('lll')}</span>
                            </div>
                            <br />

                        </div>
                    </>
                );
            }
        })
        return html;
    }
    onChange = e => {
        this.setState({
            message: "",
            errors: {}
        });
        if (e.target.id === 'tstatus') {
            this.setState({ tstatus: e.target.value });
        }
        if (e.target.name === 'issueImage') {
            this.setState({userUploadImageFront:URL.createObjectURL(e.target.files[0])});
            var issueImage = e.target.files[0];
            console.log('issueImage');
            console.log(issueImage);
            var fileSize = e.target.files[0].size;
            if (fileSize > 1000000) {
                this.setState({ invalidImage: "Maximum 1 MB Image allowed.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }
           
            if (!issueImage.name.match(/\.(jpg|jpeg|png|gif)$/)) {
                this.setState({ invalidImage: "Please select valid Front image jpeg,png,gif.", firstSubmitIsDisabled: true, userUploadImage: false });
                return false;
            }

            this.setState({
                issueImage: issueImage,
            });
        }
    };
    handleCommentChange = (event, editor) => {
        var data = editor.getData();
        if (data) {
            this.setState({'descriptionerror':""});
            this.setState({ description: data });
        }
        this.setState({
            showResults: this.state.name === null ? false : true
        })
    };
    onUserUpdate = e => {        
        e.preventDefault();
        const { user } = this.props.auth;
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
        axios.post(baseUrl+"api/chat-room-tichekdata", { _id:this.props.match.params.id,user_id:user.id })
        .then(res => {
            if(res.data.status === "Resolved"){
                toast.error("Your Ticket Resolved Please check");
                return false;
            }else{      
                if(this.state.description ===""){
                    this.setState({'descriptionerror':"This filed is require"}); 
                    return false;
                }           
                const formData = new FormData();
                formData.append('_id', this.props.match.params.id);
            
                formData.append('user_id', user.id);
                formData.append('description', this.state.description);
                formData.append('issueImage', this.state.issueImage);
                formData.append('tstatus', this.state.tstatus);
                this.props.replyForAdmin(formData, config);
            }
        })
    
    };
    onStatusUpdate = e => {
        axios
            .post(baseUrl+"api/suportStatus-update", { _id: this.props.match.params.id, tstatus: this.state.tstatus },configToken)
            .then(res => {
                if (res.status === 200) {
                 this.setState({ message: res.data.message });
                }
            })
    };

    render() {

        const { errors } = this.state;
        const { user } = this.props.auth;
        return (
            <div className="">
                <div className="chat_box">
                    <div>
					<form noValidate onSubmit={this.onStatusUpdate} id="update-user">
					 <div className="row">
					   <div className="col-md-6 pt-2">
					    <input type="reset" defaultValue="Reset" className="d-none"/>  
                       <b>Ticket Id:</b> {this.props.userrecords.id}
					   </div> 
					    
					   <div className="col-md-6">
					  
					   <div className="status_box">
					    <div className="pt-2 "><b>Status:</b></div>
					  <div className="ml-md-3 mr-md-3">
					   <select className="browser-default custom-select" id="tstatus" onChange={this.onChange} value={this.state.tstatus}>
                            <option value="pending">Pending</option>
                            <option value="Resolved">Resolve</option>
                        </select>
                        </div>
					   
					 
					   <button className="btn btn-primary" type="submit">Send</button>
					   </div>
					   <span className="text-success">{this.state.message}</span>
					   </div>
					 </div>                      
                    </form>
                        <hr />
                        <h5 className="op05 mt-2">Subject: {this.props.userrecords.subject}</h5>                    
                    </div>
                    <div className="row">
					<div className="col-md-6">
					<div className="w_chating_box">
					<div className="scrool_chating">
                        <div className="incoming">
                            <div className="bubble">
                                <p>{this.getNodesToRemoveFromElement(this.props.userrecords.description)}
                                {console.log("issueImage",this.props.userrecords?.issueImage)}
                                    {this.props.userrecords?.issueImage=="" || this.props.userrecords?.issueImage ==undefined
                                    ?
                                    <></>
                                    :
                                    <>
                                        <br/>
                                        <a href={baseUrl+"helpIssue/"+this.props.userrecords?.issueImage} target="_blank"><img src={baseUrl+"helpIssue/"+this.props.userrecords?.issueImage} /></a>
                                    </>
                                    }
                                </p>
                                <span className="time-right">{Moment(this.props.userrecords.date).format('lll')}</span>
                            </div>
                            <br />  
                        </div>
                        {this.showChatHtml()}
                    </div>
                    </div>
                    </div>
                   
					<div className="col-md-6">
					<div className="w_chating_box">
					<h5 className="mb-3">Send Your Message Form Here:</h5>
                    <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                        <div className="foot">
                            <CKEditor editor={ClassicEditor}
                                value={this.state.description}
                                onChange={this.handleCommentChange}
                                placeholder="Enter Description"
                                className="form-control form_control_textarea"
                                data={this.state.description} />
                            </div>
                            <span className="text-danger">{this.state.descriptionerror}</span>
                            <div className="form-group">
                                            <div className="add_fileinput form-control">Add File<br/><input className="profile_input" type="file" name="issueImage" onChange={this.onChange} /></div><span className="text-danger">{this.state.invalidImage}</span>
                                            {this.state.userUploadImageFront !== undefined && this.state.userUploadImageFront !== '' &&
                                            <span>
                                                <br/>
                                            <img src={this.state.userUploadImageFront!==''?this.state.userUploadImageFront:""}  alt="Cinque Terre"></img>
                                            </span>
                                            }
                                        </div>
										
                            <div className="text-right">
                            <button className="btn btn-primary" type="submit">Send</button>
                     </div>
                     </form>
                </div>
                </div> </div>
                </div>
            </div>
        )
    }
}

HelpQueryModal.propTypes = {
    replyForAdmin: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    helpdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    helpdata: state.helpdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { replyForAdmin }
)(withRouter(HelpQueryModal));
