import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addCrypto } from "../../actions/cryptoActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class CryptoAddModal extends React.Component {
    constructor() {
        super();
        this.state = {
            name: "",
            c_id:[],
            countryList:[],
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps.countryList) {
            this.setState({
                countryList: nextProps.countryList
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.cryptodata !== undefined
            && nextProps.cryptodata.crypto !== undefined
            && nextProps.cryptodata.crypto.data !== undefined
            && nextProps.cryptodata.crypto.data.message !== undefined) {
            this.setState({ errors: { },countryList:[],name:'' });
        }      
    }

  
    onChange = e => {
        this.setState({ errors: { } });
        if (e.target.id === 'c_id') {
            let value = Array.from(e.target.selectedOptions, option => option.value);            
            this.setState({ c_id:value });
        }
        if (e.target.id === 'name') {
            this.setState({ name: e.target.value });
        }
    };


    onCryptoAdd = e => {
        e.preventDefault();
        const newCrypto = {
            name: this.state.name,
            c_id: this.state.c_id
        };
        this.props.addCrypto(newCrypto, this.props.history);
    };
    showHtml = () => {
        const html = [];
        if(this.state.countryList.length>0){
            this.state.countryList.map(function (value, i) {
                html.push(
                    <>  
                        <option value={value._id}>{value.name}</option>
                    </>
                );
            })
        }        
     return html;
     }
    render() {
        
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="add-user-modal" data-reset="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Add Crypto Currency</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCryptoAdd} id="add-user">
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Country Name</label>
                                        </div>
                                          <div className="col-md-9">
                                            <select multiple className="form-control" id="c_id"  error={errors.c_id} onChange={this.onChange}>
                                            {this.showHtml()}
                                            </select>
                                            <span className="text-danger">{errors.c_id}</span>
                                        </div>
                                         <div className="col-md-3">
                                            <label htmlFor="name">Crypto Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                               </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="add-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CryptoAddModal.propTypes = {
    addCrypto: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    cryptodata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    cryptodata: state.cryptodata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { addCrypto }
)(withRouter(CryptoAddModal));
