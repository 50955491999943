const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    country: {},
    loading: false
};
export default function(state = initialState, action) {
    switch (action.type) {
       
        case 'COUNTRY_ADD':
        return {
                isAuthenticated: !isEmpty(action.payload),
                country: action.payload
        };
      
        case 'COUNTRY_UPDATE':
            return {
                isAuthenticated: !isEmpty(action.payload),
                country: action.payload,
            };
        case 'SET_CURRENT_COUNTRY':
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                country: action.payload
            };  
            
        case 'COUNTRY_LOADING':
            return {
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
