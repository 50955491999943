import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import CountryAddModal from "../partials/CountryAddModal";
import CountryUpdateModal from "../partials/CountryUpdateModal";
import {toast,Toaster } from 'react-hot-toast';

import Moment from 'moment';
import $ from 'jquery';
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class Country extends Component {

    constructor(props) {
        super(props);

        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "name",
                text: "Country Name",
                className: "name",
                align: "left",
                sortable: true,
            },
          
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
                
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment>
                            <button
                                data-toggle="modal"
                                data-target="#update-user-modal"
                                className="btn btn-primary btn-sm"
                                onClick={() => this.editRecord(record)}
                                style={{marginRight: '5px'}} title="Edit">
                                <i className="fa fa-edit" ></i>
                            </button>
                            <button
                                className="btn btn-danger btn-sm"
                                onClick={() => this.deleteRecord(record)} title="Delete">
                                <i className="fa fa-trash"></i>
                            </button>
                        </Fragment>
                    );
                }
            }
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Country List",
            no_data_text: 'No user found!',
            button: {
          
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: []
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
               
            }
        };

        this.getData = this.getData.bind(this);
    }

    componentDidMount() {
        if (this.props.auth.user.countryManagment !==undefined && this.props.auth.user.countryManagment !==true ) {
            this.props.history.push("/dashboard");
        }
        this.getData();
    };
    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user.countryManagment !==undefined && nextProps.auth.user.countryManagment !==true ) {
            this.props.history.push("/dashboard");
        }
        if (nextProps.countrydata !== undefined
            && nextProps.countrydata.country !== undefined
            && nextProps.countrydata.country.data !== undefined && nextProps.countrydata.country.data.status && nextProps.countrydata.country.data.message !== undefined) {
            $('#add-user-modal').modal('hide');
            $('#update-user-modal').modal('hide');
            toast.success(nextProps.countrydata.country.data.message, toastStyles);
            this.setState({ errors: { } });
        }else if(!nextProps.countrydata.country.data.status){
            toast.error(nextProps.countrydata.country.data.message, toastStyles);
        }
        this.getData();
    } 

   

    getData() {
        axios
            .post(baseUrl+"api/country-data",{},configToken)
            .then(res => {
                this.setState({ records: res.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/country-delete", {_id: record._id},configToken)
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.messageD, toastStyles)
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <CountryAddModal/>
                    <CountryUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
                            <button className="btn btn-outline-primary float-right" data-toggle="modal" data-target="#add-user-modal"><FontAwesomeIcon icon={faPlus}/> Add Country</button>
                            <h1 className="mt-2 text-primary">Country List</h1>
                            <div className="man_white_box ">
                            <ReactDatatable
                                config={this.config}
                                records={this.state.records}
                                columns={this.columns}
                                onPageChange={this.pageChange.bind(this)}
                            />
                        </div>
                        </div>
                    </div>
                    <Toaster/>
                </div>
            </div>
        );
    }

}

Country.propTypes = {
    auth: PropTypes.object.isRequired,
    countrydata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    countrydata: state.countrydata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Country);
