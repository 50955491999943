import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { rejectStatus } from "../../../actions/userActions";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
class RejectButtonTax extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            id: "",
            fImage: this.props.record.fimage,
            docStatus1: this.props.record.taxStatus,
            reject: '',
            errors: {},
        };
    }
    
    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                fImage: nextProps.record.fImage,
                docStatus1: nextProps.record.taxStatus,
                reject: nextProps.reject,
            });
           
        }

        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.userrecords) {
            this.setState({
                tstatus: nextProps.userdata.newuser.data.status
            });
        }
        if (nextProps.userdata !== undefined
            && nextProps.userdata.newuser !== undefined
            && nextProps.userdata.newuser.data !== undefined
            && nextProps.userdata.newuser.data.message !== undefined
            && nextProps.userdata.newuser.data.status && nextProps.userdata.newuser.data.taxreject == 1) {                
                $('#exampleModalTax').modal('hide');
                this.setState({
                    docStatus1: 'verify'
                });
        }
       
    }

    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };

    onRejectUpdate = e => {
        e.preventDefault();
        const newUser = {
            _id: this.props.match.params.id,
            stype: "tax",
            reject: this.state.reject
        };
        this.props.rejectStatus(newUser);
    };
    render() {

        const { errors } = this.state;
        const { user } = this.state;

        return (
            <>                  
                    <button style={{ display: (this.state.docStatus1 != "verify" && this.state.docStatus1 != "reject" ? 'inline-block' : 'none') }}type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalTax">Reject</button>
                <form noValidate id="update-user">
                    <div className="modal fade" id="exampleModalTax" data-reset="true" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Write Reason</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="form-group">
                                        <input onChange={this.onChange}
                                            value={this.state.reject}
                                            id="reject"
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary m-0 mr-2" data-dismiss="modal">Close</button>
                                    {this.state.reject != '' && this.state.docStatus1 != "reject" &&
                                        <button type="button" onClick={this.onRejectUpdate} className="btn btn-primary m-0">Save</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </>
        )
    }
}

RejectButtonTax.propTypes = {
    rejectStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    userdata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    userdata: state.userdata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { rejectStatus }
)(withRouter(RejectButtonTax));
