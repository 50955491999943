import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fundverifyStatus } from "../../../actions/transactionAction";
import RejectPaymentButton from "./RejectPaymentButton";
import { withRouter } from "react-router-dom";
import axios from "axios";
import $ from 'jquery';
import {Link} from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import * as myConstList from '../../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class FundRequestVerifyC extends React.Component {

    constructor(props) {
        super(props);
        const { user } = this.props.auth;
        this.state = {
            records: [],
            id: "",
            paymentProof: '',
            status: '',
            reject: '',
            balance:0,
            amount:0,
            user_id:'',
            errors: {}
        };
       
    }

    componentWillReceiveProps(nextProps) {
       
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                paymentProof: nextProps.record.paymentProof,
                status: nextProps.record.status,
                reject: nextProps.record.rejectResion,
                amount: nextProps.record.amount,
                user_id: nextProps.record.user_id,
                balance: nextProps.balance,
                records: nextProps.record,

            });
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
      

        
    }
  
    

 
    getNodesToRemoveFromElement = (stringContent) => {

        var div = document.createElement("div");
        div.innerHTML = stringContent;
        var text = div.textContent || div.innerText || "";
        return text;
    };



    

    onUpdate = e => {

        e.preventDefault();
        
        const newUser = {
            _id: this.props.match.params.id,
             user_id: this.state.user_id,
             balance:this.state.balance + this.state.amount,

        };
        this.props.fundverifyStatus(newUser);
    };
    onChange = e => {
        if (e.target.id === 'reject') {
            this.setState({ reject: e.target.value });
        }
    };
    render() {

        const { errors } = this.state;
     
        return (
            <>
                <form noValidate onSubmit={this.onUpdate} id="update-user">
                    <p><b>Payment Proof:</b> {this.state.status}</p>
                    <Link to={baseUrl+"/profile/" + this.state.paymentProof} target="_blank"><img src={baseUrl+"/profile/" + this.state.paymentProof} className="img-thumbnail" alt="Cinque Terre"></img></Link>
                    
                    <div style={{ display: (this.state.status !== "verify" && this.state.status !== "reject" ? 'block' : 'none') }}>
                        <button type="submit">Verify</button>
                    </div>
                </form>
                <RejectPaymentButton record={this.state.records} docStatus={this.state.status} rejectStatus={this.state.reject} />
                <hr />

            </>




        )
    }
}

FundRequestVerifyC.propTypes = {
    fundverifyStatus: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { fundverifyStatus }
)(withRouter(FundRequestVerifyC));
