import React, { Component, Fragment } from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import { toast, ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import Moment from 'moment';
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class Participate extends Component {

    constructor(props) {
        super(props);
        this.setState({record:[]});        
        this.state = {
            records: []
        };
        // this.getData = this.getData.bind(this);
    }
  

  

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {        
            this.setState({
                record: nextProps.record,
                bonus:nextProps.bonus
            });       
        }
    }
    showChatHtml = () => {
       
        console.log("this.state.participant",this.state.record);
        const html = [];
		
        if(this.state.record != undefined && this.state.record.length>0){
            this.state.record.map(function (value, i) {            
                html.push(
                    <>
                        <tr>
                            <td className="align-middle col-3">
                            {i+1}</td>
                            <td className="col-3">{value.name}</td>
                            <td className="col-3">{value.portfoliovalue}</td>
                            <td className="col-3">{(Number(value.per).toFixed(3))+"%"}</td>
                            <td className="col-3">{value.bid_count}</td>
                            {/* <td className="tr"><b className="crypt-down">-5.4%</b> </td> */}
                        </tr>
                    </>
                );
            })
        }
		
        return html;
    }
    componentDidMount() {
        // this.getData()
    };
    editRecord(record) {
        this.setState({ currentRecord: record});
    }

   

    pageChange(pageData) {
        console.log("OnPageChange", pageData);
    }

    render() {
    
        return (<div className="table-responsive">
		<div className="table-responsive winners_table">
            <table className="table  mb-0">            
                <tr>
                    <th className="th">S.No.</th>
                    <th className="th">Username</th>
                    <th className="th">Portfolio Value</th>
                    <th className="th">Gain/Loss %</th>
                    <th className="th">Bids</th>
                </tr>
                <tbody>
                    {this.showChatHtml()}
                </tbody>
        </table>
		</div>
		</div>
        );
    }

}

Participate.propTypes = {
    auth: PropTypes.object.isRequired,
    transactiondata: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth,
    transactiondata:state.transactiondata,
    records: state.records
});

export default connect(
    mapStateToProps
)(Participate);
