import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateDummyUser } from "../../actions/userActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class UserUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record._id,
            name: this.props.record.name,
            vartualFund: this.props.record.vartualFund,
            bids: this.props.record.bids,
            gainlossPercentage: this.props.record.gainlossPercentage,
            userWin: this.props.record.userWin,
            // password: '',
            constestRecords1:[],
            contestIdArr:{},
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
        // console.log("nextProps.constestRecords==",nextProps.constestRecords);
        if (nextProps.record) {
            this.setState({
                id: nextProps.record._id,
                name: nextProps.record.name,
                vartualFund: nextProps.record.contest_virtualFund,
                bids: nextProps.record.order_count,
                gainlossPercentage: nextProps.record.per,
                userWin: nextProps.record.userWin,
                constestRecords:nextProps.constestRecords
            })
        }
        if (nextProps.constestRecords) {
            this.setState({                
                constestRecords1:nextProps.constestRecords
            })
            console.log("constestRecords1====",this.state.constestRecords1);
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        // if (nextProps.auth !== undefined
        //     && nextProps.auth.user !== undefined
        //     && nextProps.auth.user.data !== undefined
        //     && nextProps.auth.user.data.message !== undefined
        //     && nextProps.auth.user.data.success) {
        //     $('#update-user-modal').modal('hide');
        //     toast(nextProps.auth.user.data.message, {
        //         position: toast.POSITION.TOP_CENTER
        //     });
        // }
    }

    onChange = e => {
        // if (e.target.id === 'user-update-name') {
            // this.setState({ [e.target.id]: e.target.value });
        // }
        if (e.target.id === 'contestId') {            
            // let value = Array.from(e.target.selectedOptions, option => option.value);  
            // let value2=  Object.assign({}, value);
            // console.log("value2",e.target.value);       
            this.setState({ contestId:e.target.value });
        }
        if(e.target.id ==='bids'){
            if(e.target.value <=0){
                this.setState({errors:{bids:"Please enter valid bid"}});
            }else if(e.target.value<5){
                this.setState({userWin:'no',errors:{bids:""}});
            }else{
                this.setState({[e.target.id]: e.target.value,errors:{bids:""}});
            }            
        }
        // if(e.target.id ==='vartualFund'){
        //     if(e.target.value <0){
        //         this.setState({errors:{vartualFund:"Please enter valid vartual fund"}});
        //     }else{
        //         this.setState({errors:{vartualFund:""}});
        //     }     
        // }
        if(e.target.id ==='gainlossPercentage'){
            if(e.target.value <0){
                this.setState({errors:{gainlossPercentage:"Please enter valid percentage"}});
            }else{
                this.setState({errors:{gainlossPercentage:""}});
            }     
        }
        if(e.target.id ==='name' && e.target.value.length<11){
            this.setState({[e.target.id]: e.target.value });
        }
        // if (e.target.id === 'user-update-email') {
        //     this.setState({ email: e.target.value });
        // }
        // if (e.target.id === 'user-update-password') {
        //     this.setState({ password: e.target.value });
        // }
        this.setState({ [e.target.id]: e.target.value });
    };

    showHtml = () => {
        const html = [];
        let collectThis = this;
        // if(collectThis.state.constestRecords1.length>0){
            // console.log("this.state.constestRecordsthis.state.constestRecords",collectThis.state.constestRecords1);
            var tempArr = collectThis.state.constestRecords1;
            console.log("tempAr===",tempArr);
            tempArr.map(function (value, i) {
                html.push(
                <>  
                    <option value={value._id}>{value.contestName}</option>
                </>
                );               
            })
        // }
        return html;
    }
    onUserUpdate = e => {
        e.preventDefault();
        const strongRegexNameCk = new RegExp(/^[a-zA-Z0-9]*$/);
        const onlynumeric = new RegExp(/^[0-9\b]+$/);
        
		var testNameCk = strongRegexNameCk.test(this.state.name);
        var testOnlyNumric = onlynumeric.test(this.state.name);

        if (!testNameCk || testOnlyNumric){            
			this.setState({ errors: {name: "Only use alphanumeric characters without space"},submitBtnDisable:false});  
			return false;
		}
        // console.log("contestIdArr====",contestIdArr);
        if(typeof this.state.name =='undefined' || this.state.name ==""){
            this.setState({errors:{name:"Please enter name"}});
            return false;
        }
        // if(typeof this.state.vartualFund =='undefined' || this.state.vartualFund ==""){
        //     this.setState({errors:{vartualFund:"Please enter virtual fund"}});
        //     return false;
        // }
        if(typeof this.state.bids =='undefined' || this.state.bids ==""){
            this.setState({errors:{bids:"Please enter bids"}});
            return false;
        }

        if(this.state.bids<=0){
            this.setState({errors:{bids:"Please enter greater then 0"}});
            return false;
        }

        if(typeof this.state.gainlossPercentage =='undefined' || this.state.gainlossPercentage ==""){
            this.setState({errors:{gainlossPercentage:"Please enter gain loss percentage"}});
            return false;
        }
        if(typeof this.state.userWin =='undefined' || this.state.userWin ==""){
            this.setState({errors:{userWin:"Please enter user win"}});
            return false;
        }
        const newUser = {
            _id: this.state.id,
            name: this.state.name,
            // vartualFund: this.state.vartualFund,
            bids: this.state.bids,
            gainlossPercentage: this.state.gainlossPercentage,
            userWin: this.state.userWin,
            contestId:this.state.contestId,
            // email: this.state.email,
            // password: this.state.password
        };
        this.props.updateDummyUser(newUser);
    };

    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update User</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onUserUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                        <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Select Contest</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select
                                            onChange={this.onChange}
                                            value={this.state.contestId}
                                            id="contestId"
                                            error={errors.contestId}
                                            className={classnames("form-control", {
                                                invalid: errors.contestId,
                                            })}
                                            // disabled={this.state.contestId!=""?true:false}
                                            >
                                            <option value="">Select</option>
                                            {/* {this.state.constestRecords && this.state.constestRecords.map(function (value, i) {
                                                console.log("value====",value);
                                                <option value={value._id}>{value.contestName}</option>
                                            })
                                            } */}
                                            {this.showHtml()}
                                        </select>
                                           
                                            <span className="text-danger">{errors.contestId}</span>
                                        </div>
                                    </div> 
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                    {/* <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name" >Vartual Fund</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.vartualFund}
                                                id="vartualFund"
                                                type="number"
                                                error={errors.vartualFund}
                                                className={classnames("form-control", {
                                                    invalid: errors.vartualFund
                                                })}
                                                />
                                            <span className="text-danger">{errors.vartualFund}</span>
                                        </div>
                                    </div> */}
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Bids</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.bids}
                                                id="bids"
                                                type="number"
                                                error={errors.bids}
                                                className={classnames("form-control", {
                                                    invalid: errors.bids
                                                })}/>
                                            <span className="text-danger">{errors.bids}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Gain Loss Percentage</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.gainlossPercentage}
                                                id="gainlossPercentage"
                                                type="number"
                                                error={errors.gainlossPercentage}
                                                className={classnames("form-control", {
                                                    invalid: errors.gainlossPercentage
                                                })}/>
                                            <span className="text-danger">{errors.gainlossPercentage}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">User Win</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select
                                            onChange={this.onChange}
                                            value={this.state.userWin}
                                            id="userWin"
                                            error={errors.userWin}
                                            className={classnames("form-control", {
                                                invalid: errors.userWin,
                                            })} disabled={this.state.bids<5?true:""}
                                            >
                                            <option value="">Select</option>
                                            <option value="no">No</option>
                                            <option value="yes">Yes</option>
                                        </select>                                           
                                            <span className="text-danger">{errors.userWin}</span>
                                        </div>
                                    </div>                                    
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update User
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

UserUpdateModal.propTypes = {
    updateDummyUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateDummyUser }
)(withRouter(UserUpdateModal));
