import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCrypto } from "../../actions/cryptoActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class CryptoUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            name: this.props.record.name,
            countryList:[],
            errors: {},
        };
    }

    componentWillReceiveProps(nextProps) {
      
        if (nextProps.countryList) {
            this.setState({
                countryList: nextProps.countryList
            })
        }
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                name: nextProps.record.name,
                
            })
        }
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.cryptodata !== undefined
            && nextProps.cryptodata.crypto !== undefined
            && nextProps.cryptodata.crypto.data !== undefined
            && nextProps.cryptodata.crypto.data.message !== undefined) {
            this.setState({ errors: { } });
        }
    }

    onChange = e => {
        this.setState({ errors: { } });
        if (e.target.id === 'user-update-name') {
            this.setState({ name: e.target.value });
        }
       
    };

    onCryptoUpdate = e => {
        e.preventDefault();
        const newCrypto = {
            _id: this.state.id,
            name: this.state.name,
   
        };
        this.props.updateCrypto(newCrypto);
    };
    showHtml = () => {
        const html = []
         this.state.countryList.map(function (value, i) {
              html.push(
                     <>  
                         <option value={value._id}>{value.name}</option>
                     </>
                 );
             
     })
     return html;
     }
    ren
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update Country</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCryptoUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Crypto Currency Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="user-update-name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

CryptoUpdateModal.propTypes = {
    updateCrypto: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    cryptodata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    cryptodata: state.cryptodata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateCrypto }
)(withRouter(CryptoUpdateModal));
