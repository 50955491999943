const isEmpty = require("is-empty");
const initialState = {
    isAuthenticated: false,
    app: {},
};
export default function(state = initialState, action) {
    switch (action.type) {
       
        case 'APP_UPDATE':
        return {
                isAuthenticated: !isEmpty(action.payload),
                app: action.payload
        };
        default:
            return state;
    }
}
