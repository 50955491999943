import React, { Component } from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import $ from 'jquery';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { toast, ToastContainer} from "react-toastify";
import * as myConstList from '../../BaseUrl';
const baseUrl = myConstList.baseUrl;
class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId:this.props.match.params.userId,
            token:this.props.match.params.token,
            password2: "",
            password: "",
            message:'',
            messageerror:'',
            err:'',
            errors: {
          
        },
         
        };
    }


    onChange = e => {
        this.setState({ errors: ''});
        this.setState({ [e.target.id]: e.target.value });

    };

     
   
    onSubmit = e => {
        e.preventDefault();
        axios
            .post(baseUrl+"api/reset-password", {userId: this.state.userId,token: this.state.token,password: this.state.password,password2: this.state.password2})
            .then(res => {
               
                  if (res.status === 200) {
                     toast( res.data.message, {
                    position: toast.POSITION.TOP_CENTER
                  });
                  
                }
                if (res.data.err) {
                       toast( res.data.err, {
                    position: toast.POSITION.TOP_CENTER
                  });
                    
                }
                if (res.data.password || res.data.password2 ) {
                       toast( res.data, {
                    position: toast.POSITION.TOP_CENTER
                  });
            }
              
            })
            .catch(error=>{
                // alert(error);
            }

            );
    };

    render() {
        const { errors } = this.state;
        return (
              <>
            <div className="container">
                <div className="row mt-5">
                    <div className="col-md-4 mx-auto mt-5 card shadow-lg">
                        <div className="card-body p-1">
                       
                        
                        
                   
                      
                            <h2 className="text-center text-primary mt-3">Forgot Password</h2>
                            <form noValidate onSubmit={this.onSubmit} className="white">
                                <label htmlFor="password">Password</label>
                                <input onChange={this.onChange}    id="password"
    type="password" className={classnames("form-control", { invalid: errors.email1 })} />
   <span className="text-danger">{errors.password}</span>
                                <span className="text-danger">{errors.email}</span>
                         
                                <span className="text-danger">{errors.password}</span>
                           
                           <label htmlFor="password">Confirm Password</label>

                                   <input onChange={this.onChange} value={this.state.password2}
                                    error={errors.password2}
                                    id="password2"
                                    type="password"
                                   
                                   
                                    className={classnames("form-control", {
                                        invalid: errors.password2
                                    })}
                                />
                                   <span className="text-danger">{errors.password2}</span>
                                 <p className="text-center pb-0 mt-2">
                                    <button
                                        type="submit"
                                        className="btn btn-large btn-primary mt-2 px-5">
                                         Reset Password
                                    </button>
                                </p>
                                <Link to={"/"} ><h5 className="text-center">Go to Home</h5></Link>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
               <ToastContainer/>
            </>

        );
    }
}


export default ResetPassword;
