import React, { Component, Fragment } from "react";
import Navbar from "../partials/Navbar";
import Sidebar from "../partials/Sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import ReactDatatable from '@ashvin27/react-datatable';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import axios from "axios";
import {Link} from "react-router-dom";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import UserAddModal from "../partials/UserAddModal";
import UserUpdateModal from "../partials/UserUpdateModal";
import {toast,Toaster } from 'react-hot-toast';
import Moment from 'moment';
import * as myConstList from '../../BaseUrl';
import $ from 'jquery';
const baseUrl = myConstList.baseUrl;
const toastStyles = {
    style: {
      borderRadius: '10px',
      background: '#fff',
      color: '#000',
    },
  }
const configToken = {
    headers: {
      Authorization: `${localStorage.getItem("jwtToken")}`,
    },
  };
class BankDetailPending extends Component {

    constructor(props) {
        super(props);
        
        this.columnsPending = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "userName",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "accountHolderName",
                text: "Account Holder Name",
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "accountNo",
                text: "Account No",
                className: "accountNo",
                align: "left",
                sortable: true
            },
           
            {
                key: "bankName",
                text: "Bank Name",
                className: "bankName",
                align: "left",
                sortable: true
            },
			{
                key: "branchName",
                text: "Branch Name",
                className: "branchName",
                align: "left",
                sortable: true
            },
            {
                key: "cancelledChequeImage",
                text: "Cancelled Cheque Image",
                className: "cancelledChequeImage",
                align: "left",
                sortable: true,
				cell: record => {
                    return (
                       <Fragment>
                        <img src={record.cancelledChequeImage} className="btn btn_man" width='100' data-toggle="modal" data-target="#myModal" onClick={() => this.showRecord(record.cancelledChequeImage)}/>
                        {/* <a href={record.cancelledChequeImage} target="_blank"><img src={record.cancelledChequeImage} className="" width='30'/></a> */}

                            
						</Fragment>
                    );
                }
            },
            {
                key: "ifscCode",
                text: "IFSC Code",
                className: "ifscCode",
                align: "left",
                sortable: true
            },
			{
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: record => {
					if(record.status==="pending") {
						return (
							<Fragment>
							<span className="accepted">Pending</span>
							</Fragment>
						);
					}
                    else if(record.status==="accepted"){
                        return (
                        <Fragment>
                        <span className="accepted">Accepted</span>
                        </Fragment>);
                    }
                    else if(record.status==="rejected"){
                        return (<Fragment>
                        <span className="rejected">Rejected</span>
                        </Fragment>);
                    }
                }
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
            {
                key: "action",
                text: "Action",
                className: "action",
                width: 100,
                align: "left",
                sortable: false,
                cell: record => {
					if(record.status==="pending") {
						return (
							<Fragment>
							<a onClick={()=>this.verifyBankDetailFunc(record._id,"accepted")} className="btn btn_man acceptedPointer mr-2">Accept</a>							
							<a  data-toggle="modal"
                                data-target="#bank-modal-reject"
                                className="btn btn-primary btn-sm rejectedwithdrow"
                                className="btn btn_man rejectedwithdrow" onClick={()=>this.setvalueRejectRecord(record._id)}>Reject</a>
							</Fragment>
						);
					}
                    else if(record.status==="accepted"){
                        return (
                        <Fragment>
                        <a className="btn btn_man accepted">Accepted</a>
                        </Fragment>);
                    }
                    else if(record.status==="rejected"){
                        return (<Fragment>
                        <a className="btn btn_man rejected">Rejected</a>
                        </Fragment>);
                    }
                }
            }
        ];
        this.columns = [
            {
                key: "_id",
                text: "#",
                className: "id",
                align: "left",
                sortable: true,
                cell:(row, index)=>index+1
            },
            {
                key: "userName",
                text: "User Name",
                className: "name",
                align: "left",
                sortable: true,
            },
            {
                key: "accountHolderName",
                text: "Account Holder Name",
                className: "email",
                align: "left",
                sortable: true
            },
            {
                key: "accountNo",
                text: "Account No",
                className: "accountNo",
                align: "left",
                sortable: true
            },
           
            {
                key: "bankName",
                text: "Bank Name",
                className: "bankName",
                align: "left",
                sortable: true
            },
			{
                key: "branchName",
                text: "Branch Name",
                className: "branchName",
                align: "left",
                sortable: true
            },
            {
                key: "cancelledChequeImage",
                text: "Cancelled Cheque Image",
                className: "cancelledChequeImage",
                align: "left",
                sortable: true,
				cell: record => {
                    return (
                       <Fragment>
                        <img src={record.cancelledChequeImage} className="btn btn_man" width='100' data-toggle="modal" data-target="#myModal" onClick={() => this.showRecord(record.cancelledChequeImage)} />
                          
						</Fragment>
                    );
                }
            },
            {
                key: "ifscCode",
                text: "IFSC Code",
                className: "ifscCode",
                align: "left",
                sortable: true
            },
            {
                key: "verifiedByUser",
                text: "verified By",
                className: "verifiedBy",
                width: 100,
                align: "left",
                sortable: true,
                cell: record => {
					if(record.status==="accepted" || record.status==="rejected"){
                        return (
                        <Fragment>
                        <a>{record.verifiedByUser}</a>
                        </Fragment>);
                    }
                }
            },
			{
                key: "status",
                text: "Status",
                className: "status",
                align: "left",
                sortable: true,
                cell: record => {
					if(record.status==="pending") {
						return (
							<Fragment>
							<span className="accepted">Pending</span>
							</Fragment>
						);
					}
                    else if(record.status==="accepted"){
                        return (
                                <Fragment>						
                                <a  data-toggle="modal"
                                    data-target="#bank-modal-reject"
                                    className="btn btn-primary btn-sm rejectedwithdrow"
                                    className="btn btn_man rejectedwithdrow" onClick={()=>this.setvalueRejectRecord(record._id)}>Reject</a>
                                </Fragment>
                            );
                    }
                    else if(record.status==="rejected"){
                        var rejectReason = "";
                        if(record.rejectReason != undefined){
                            rejectReason = record.rejectReason;
                        }
                        return (<Fragment>
                            <span className="rejected" data-toggle="modal"
                                    data-target="#reject-reason-modal"
                                    className="btn btn-primary btn-sm rejectedwithdrow"
                                    className="btn btn_man rejectedwithdrow" onClick={()=>this.setvalueReject(rejectReason)}>Rejected
                            </span>
                        </Fragment>);
                    }
                }
            },
            {
                key: "date",
                text: "Date",
                className: "date",
                align: "left",
                sortable: true,
                cell: record => {
                    return (
                        <Fragment>
                          {Moment(record.date).format('lll')}
                        </Fragment>
                    );
                }
            },
        ];

        this.config = {
            page_size: 10,
            length_menu: [ 10, 20, 50 ],
            filename: "Users Pending Verifications",
            no_data_text: 'No user found!',
            button: {
               
                print: true,
                csv: true
            },
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Filter in records...",
                info: "Showing _START_ to _END_ of _TOTAL_ records",
                pagination: {
                    first: "First",
                    previous: "Previous",
                    next: "Next",
                    last: "Last"
                }
            },
            show_length_menu: true,
            show_filter: true,
            show_pagination: true,
            show_info: true,
        };

        this.state = {
            records: [],
            chequeImageRecord:"",
            rotation: 0,
            errors: {},
        };

        this.state = {
            currentRecord: {
                id: '',
                name: '',
                email: '',
                password: '',
                password2: '',
                recordId:"",
                reject:"",
                currentReject:""
            },
            rotation: 0,
            errors: {},
        };

        this.getPendingData = this.getPendingData.bind(this);
        this.rotate = this.rotate.bind(this);
        this.rotateleft = this.rotateleft.bind(this);
        this.handleZoomIn = this.handleZoomIn.bind(this)
	    this.handleZoomOut = this.handleZoomOut.bind(this)
        this.imgRef = React.createRef()
    }

    componentDidMount() {
        if (this.props.auth.user.bankDetail !==undefined && this.props.auth.user.bankDetail !==true) {
            this.props.history.push("/dashboard");
        }
        this.getPendingData();
        this.getAcceptedData();
        this.getRejectedData();
    };

    componentWillReceiveProps(nextProps) {
        if (this.props.auth.user.bankDetail !==undefined && this.props.auth.user.bankDetail !==true) {
            this.props.history.push("/dashboard");
        }
      
        this.getPendingData();
    }
    
    showRecord(record) {
        this.setState({ chequeImageRecord: record});
    }

    rotate(){
        let newRotation = this.state.rotation + 90;
        if(newRotation >= 360){
            newRotation =- 360;
        }
        this.setState({
            rotation: newRotation,
        })
    }
    rotateleft(){
       
        let newRotation = this.state.rotation - 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        this.setState({
          rotation: newRotation,
        })
    }
    handleZoomIn(){
        // Fetching current height and width
        const height = this.imgRef.current.clientHeight
        const width = this.imgRef.current.clientWidth
        // Increase dimension(Zooming)
        this.setState({
        height : height + 10,
        width : width + 10,
        })
    }
    handleZoomOut(){
        // Assigning original height and width
        this.setState({
        height : 300,
        width : 500,
        })
    }

    getPendingData() {
        axios
            .post(baseUrl+"api/bank-detail-pending",{},configToken)
            .then(res => {
               this.setState({ pendingRecords: res.data.data})
            })
            .catch()
    }
	
	getAcceptedData=()=> {
        axios
            .post(baseUrl+"api/bank-detail-accepted",{},configToken)
            .then(res => {
               this.setState({ acceptedRecords: res.data.data})
            })
            .catch()
    }
	
	getRejectedData=()=> {
        axios
            .post(baseUrl+"api/bank-detail-rejected",{},configToken)
            .then(res => {
               this.setState({ rejectedRecords: res.data.data})
            })
            .catch()
    }

    editRecord(record) {
        this.setState({ currentRecord: record});
    }

    deleteRecord(record) {
        axios
            .post(baseUrl+"api/user-delete", {_id: record._id},configToken)
            .then(res => {
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles)
                }
            })
            .catch();
        this.getData();
    }

    pageChange(pageData) {
        // console.log("OnPageChange", pageData);
    }

    onChange = e => {
        if (e.target.id === 'user-update-reject') {
            this.setState({ reject: e.target.value });
        }
       
    };
    setvalueRejectRecord=(getRecordId)=>{
        this.setState({ recordId: getRecordId});
    }
    setvalueReject=(rejectReason)=>{
        this.setState({ currentReject: rejectReason});
    }
    
	verifyBankDetailFunc =(getRecordId,getNewStatus)=>{
        // alert(this.state.reject);
		axios
            .post(baseUrl+"api/verify_bank_detail", {record_id: getRecordId, newstatus:getNewStatus,reject:this.state.reject},configToken)
            .then(res => {
                $('#bank-modal-reject').modal('hide');
                if (res.status === 200) {
                   toast.success(res.data.message, toastStyles)
				   this.getPendingData();
				   this.getAcceptedData();
				   this.getRejectedData();
                }
            })
            .catch();
		
	}
	
	
    render() {
        const { errors } = this.state;
        return (
            <div>
                <Navbar/>
                <div className="d-flex" id="wrapper">
                    <Sidebar/>
                    <UserAddModal/>
                    <UserUpdateModal record={this.state.currentRecord}/>
                    <div id="page-content-wrapper">
                        <div className="container-fluid">
                            <button className="btn btn-link mt-3" id="menu-toggle"><FontAwesomeIcon icon={faList}/></button>
							
							<h1 className="mt-2 text-primary">Bank Detail</h1>

                            <div className="man_white_box padding_d">
                                <ul className="nav nav_left_tab">
                                <li className="nav-item">
                                    <a className="active show" data-toggle="tab" href="#pending">
                                    <i className="la la-mobile-phone l_i"></i>
                                        Pending
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="" data-toggle="tab" href="#accepted">
                                    <i className="la la-user l_i"></i>
                                    Accepted
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="" data-toggle="tab" href="#rejected">
                                    <i className="la la-map-marker l_i"></i>
                                    Rejected
                                    </a>
                                </li>
                                </ul>
                                <hr />
                                <div className="white_box">
                                    <div className="tab-content">
                                        <div id="pending" className="tab-pane active show bank-detail-datatable">
                                                <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.pendingRecords}
                                                    columns={this.columnsPending}
                                                    onPageChange={this.pageChange.bind(this)}
                                                />
                                        </div>
                                        <div id="accepted" className="tab-pane bank-detail-datatable">
                                            <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.acceptedRecords}
                                                    columns={this.columns}
                                                    onPageChange={this.pageChange.bind(this)}
                                                />
                                        </div>
                                        <div id="rejected" className="tab-pane bank-detail-datatable">
                                            <ReactDatatable
                                                    config={this.config}
                                                    records={this.state.rejectedRecords}
                                                    columns={this.columns}
                                                    onPageChange={this.pageChange.bind(this)}
                                                    class ="bank-detail-datatable"
                                                />
                                        </div>
                                    </div>
                                </div>
							</div>                            
                      
                    </div>
                    <Toaster/>
                </div>
                <div id="myModal" className="modal fade" role="dialog">
                    <div className="modal-dialog modalSize">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">cancelledChequeImage</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                                
                            </div>
                            <div className="modal-body login_modal">
                                <button type="button" className="close" data-dismiss="modal">
                                <i className="las la-times"></i></button>
                                    <div className="rotate_img_box text-center">
                                            <div className="rotate_img ">
                                                <img ref={this.imgRef} style={{transform: `rotate(${this.state.rotation}deg)`,maxHeight:"600px",maxWidth:'650px',height : this.state.height, width: this.state.width}} src={this.state.chequeImageRecord}/>
                                            </div>
                                            <button className="btn btn-primary" onClick={this.rotateleft} type="button"> <i className="fa fa-undo" ></i></button>
                                            <button className="btn btn-primary" onClick={this.rotate} type="button"> <i className="fa fa-repeat" ></i></button>
                                            <button className="btn btn-primary" onClick={this.handleZoomIn} type="button"> <i className="fa fa-plus" ></i></button>
                                            <button className="btn btn-primary" onClick={this.handleZoomOut} type="button"> <i className="fa fa-refresh" ></i></button>
                                        </div>
                                {/* <img src={record.cancelledChequeImage} className="btn btn_man"  /> */}
                            </div>
                        </div>
                    </div>
                </div>
                {/* reject reason submit modal */}
                <div className="modal fade" id="bank-modal-reject">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Reject Bank Detail</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onCountryUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">Reject Reason</label>
                                        </div>
                                        <div className="col-md-9">
                                            <textarea
                                                onChange={this.onChange}
                                                value={this.state.reject}
                                                id="user-update-reject"
                                                type="text"
                                                error={errors.reject}
                                                className="form-control"/>
                                            <span className="text-danger">{errors.reject}</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="button"
                                    className="btn btn-primary" onClick={()=>this.verifyBankDetailFunc(this.state.recordId,"rejected")}>
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                {/* show reject reason */}
                <div className="modal fade" id="reject-reason-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Bank Detail Reject Reason</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <span>{this.state.currentReject}</span>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>                                
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*  */}
            </div>
            
        );
    }

}

BankDetailPending.propTypes = {
    auth: PropTypes.object.isRequired,
   
};

const mapStateToProps = state => ({
    auth: state.auth,
    
    records: state.records
});

export default connect(
    mapStateToProps
)(BankDetailPending);
