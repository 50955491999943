import React from 'react'
import classnames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateState } from "../../actions/stateActions";
import { withRouter } from "react-router-dom";
import { toast } from 'react-toastify';
import $ from 'jquery';

import 'react-toastify/dist/ReactToastify.css';

class StateUpdateModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: this.props.record.id,
            name: this.props.record.name,
            c_id: this.props.record.c_id,
            countryList:[],
            errors: {},
        };
        console.log("fffffffffffff");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.record) {
            this.setState({
                id: nextProps.record.id,
                name: nextProps.record.name,
                c_id: nextProps.record.c_id,               
            })
        }
        if (nextProps.countryList) {
            this.setState({
                countryList: nextProps.countryList
            })
        }
        if (nextProps.statedata.isAuthenticated && nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
        if (nextProps.statedata !== undefined
            && nextProps.statedata.state !== undefined
            && nextProps.statedata.state.data !== undefined
            && nextProps.statedata.state.data.message !== undefined) {          
                this.setState({errors:{}});
        }      
    }

    componentDidMount(){
        this.setState({errors:{}});
        console.log("this.propsthis.props=",this.props);
    }

    onChange = e => {
        this.setState({errors:{}});
        if (e.target.id === 'c_id') {
            this.setState({ c_id: e.target.value });
        }
        if (e.target.id === 'user-update-name') {
            this.setState({ name: e.target.value });
        }       
    };

    onStateUpdate = e => {
        e.preventDefault();
        const newCountry = {
            _id: this.state.id,
            name: this.state.name,
            c_id: this.state.c_id
   
        };
        this.props.updateState(newCountry);
    };
    showHtml = () => {
       const html = []
        this.state.countryList.map(function (value, i) {
             html.push(
                    <>  
                        <option value={value._id}>{value.name}</option>
                    </>
                );
            
    })
    return html;
    }
    render() {
        const { errors } = this.state;
        return (
            <div>
                <div className="modal fade" id="update-user-modal">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h4 className="modal-title">Update State</h4>
                                <button type="button" className="close" data-dismiss="modal">&times;</button>
                            </div>
                            <div className="modal-body">
                                <form noValidate onSubmit={this.onStateUpdate} id="update-user">
                                    <input
                                        onChange={this.onChange}
                                        value={this.state.id}
                                        id="user-update-id"
                                        type="text"
                                        className="d-none"/>
                                               <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="c_id">Country Name</label>
                                        </div>
                                        <div className="col-md-9">
                                        <select className={classnames("form-control", {
                                                invalid: errors.c_id
                                            })} id="c_id" value={this.state.c_id} error={errors.c_id} onChange={this.onChange} aria-label="Default select example">
                                                <option selected>Open this select menu</option>
                                                 {this.showHtml()}
                                            </select>
                                            <span className="text-danger">{errors.c_id}</span>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-md-3">
                                            <label htmlFor="name">State Name</label>
                                        </div>
                                        <div className="col-md-9">
                                            <input
                                                onChange={this.onChange}
                                                value={this.state.name}
                                                id="user-update-name"
                                                type="text"
                                                error={errors.name}
                                                className={classnames("form-control", {
                                                    invalid: errors.name
                                                })}/>
                                            <span className="text-danger">{errors.name}</span>
                                        </div>
                                    </div>
                                
                               
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                                <button
                                    form="update-user"
                                    type="submit"
                                    className="btn btn-primary">
                                    Update
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

StateUpdateModal.propTypes = {
    updateState: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    statedata: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    statedata: state.statedata,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { updateState }
)(withRouter(StateUpdateModal));
